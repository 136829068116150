import type { Store } from 'effector';
import { createEvent, createStore, is, sample } from 'effector';

import { atom } from '@kuna-pay/utils/misc';

import type { OptionsLoader } from './loader.declaration';
import { stringFilter } from './loader.lib';

type SyncLoaderConfig = {
  /**
   * Should not be mapped unit
   */
  options: string[] | Store<string[]>;

  filter?: (options: string[], query: string) => string[];
};

const createSyncLoader = (config: SyncLoaderConfig): OptionsLoader => {
  const $options = is.store(config.options)
    ? (config.options as Store<string[]>)
    : createStore<string[]>(config.options as string[]);

  return {
    $options,

    $$load: atom(() => {
      const start = createEvent();
      const search = createEvent<{ query: string }>();

      return {
        start,

        search,

        $pending: createStore(false),

        done: sample({ clock: start, source: $options }),

        fail: createEvent(),
      };
    }),

    $$loadMore: atom(() => {
      const start = createEvent<{ skip: number; search: string }>();

      return {
        start,

        $hasMore: createStore(false),

        $pending: createStore(false),

        done: start.map(() => ({
          newOptions: [] as string[],
          hasMore: false as boolean,
        })),

        fail: createEvent(),
      };
    }),

    $$search: {
      sync: true,
      filter: config.filter ?? stringFilter,
    },

    reset: createEvent(),
  };
};

export { createSyncLoader };
