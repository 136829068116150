import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { Paper } from '@kuna-pay/ui/ui/paper';

import {
  CheckoutProgressTracker,
  InvoiceAmounts,
  InvoiceTabs,
} from '../shared';
import { InvoiceAddress } from './invoice-address';
import styles from './procced-payment.module.scss';

type ProceedPaymentViewProps = PropsWithChildren & {
  className?: string;
};

const ProceedPaymentView = memo(
  ({ className, children }: ProceedPaymentViewProps) => (
    <>
      <CheckoutProgressTracker />

      <Paper className={clsx(styles.root, className)}>
        <InvoiceTabs>
          <div className={styles.container}>
            <InvoiceAddress className={styles.invoiceAddress} />

            <InvoiceAmounts.Root>
              <InvoiceAmounts.AmountLeft />

              <InvoiceAmounts.ReceivedAmount />
            </InvoiceAmounts.Root>

            {children}
          </div>
        </InvoiceTabs>
      </Paper>
    </>
  )
);

export { ProceedPaymentView };
