import type { PublicInvoiceDetailsOutput } from '@kuna-pay/accept-payment/pages/checkout/api';
import type { IProgressState } from '@kuna-pay/accept-payment/pages/checkout/model';

import { BaseProgressState } from './base.progress.state';

class NonQuestionnaireProgressState extends BaseProgressState {
  public override empty(_invoice: null): IProgressState {
    return {
      init: 'inactive',
      payment: 'inactive',
      done: 'inactive',
    };
  }

  public override created(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'active',
      payment: 'inactive',
      done: 'inactive',
    };
  }

  public override confirmationAwaiting(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'pending',
      payment: 'inactive',
      done: 'inactive',
    };
  }

  public override limitsOutOfRange(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'pending',
      payment: 'inactive',
      done: 'inactive',
    };
  }

  public override paymentAwaiting(
    invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    const isInvoiceHasSomeTransfers =
      !!invoice.Transfers && invoice.Transfers.length > 0;

    if (isInvoiceHasSomeTransfers) {
      return {
        init: 'completed',
        payment: 'pending',
        done: 'inactive',
      };
    }

    return {
      init: 'completed',
      payment: 'active',
      done: 'inactive',
    };
  }

  /**
   * @note For statuses below we don't show progress bar
   */
  public override paid(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    return {
      init: 'completed',
      payment: 'completed',
      done: 'completed',
    };
  }

  public override partiallyPaid(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'completed',
      payment: 'completed',
      done: 'completed',
    };
  }

  public override timeout(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'completed',
      payment: 'completed',
      done: 'completed',
    };
  }

  public override deactivated(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'completed',
      payment: 'completed',
      done: 'completed',
    };
  }

  public override declined(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'completed',
      payment: 'completed',
      done: 'completed',
    };
  }

  public override suspended(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'completed',
      payment: 'completed',
      done: 'completed',
    };
  }

  public override arrested(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'completed',
      payment: 'completed',
      done: 'completed',
    };
  }

  public override currencyMismatch(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'completed',
      payment: 'completed',
      done: 'completed',
    };
  }

  public override unknown(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    return {
      init: 'inactive',
      payment: 'inactive',
      done: 'inactive',
    };
  }
}

export { NonQuestionnaireProgressState };
