import { RecaptchaModel } from '@kuna-pay/core/shared/recaptcha';

import { environment } from '@kuna-pay/accept-payment/shared/config';
import { $$recaptchaFeatureFlag } from '@kuna-pay/accept-payment/shared/feature-flags/features';

const $$recaptcha = RecaptchaModel.createModel({
  $isEnabled: $$recaptchaFeatureFlag.$enabled,

  SITE_KEY: environment.reCaptcha.siteKey,
});

export { $$recaptcha };
