import {
  PublicPaymentMethodOutput,
  QueryFindPublicPaymentMethodsToPayInvoiceArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findPublicPaymentMethodsToPayInvoiceRequest = (
  select: Select<PublicPaymentMethodOutput> | string
) => `query findPublicPaymentMethodsToPayInvoice($id: String!) {
  data: findPublicPaymentMethodsToPayInvoice(id: $id) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'PublicPaymentMethodOutput')}`;
export const findPublicPaymentMethodsToPayInvoice =
  (select: Select<PublicPaymentMethodOutput> | string) =>
  (args: QueryFindPublicPaymentMethodsToPayInvoiceArgs) =>
    query<PublicPaymentMethodOutput[]>(
      findPublicPaymentMethodsToPayInvoiceRequest(select),
      args
    );
