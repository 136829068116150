import { memo } from 'react';
import Linkify from 'react-linkify';

import { ExternalLink } from '@kuna-pay/ui/router';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './support-info-text.module.scss';

type CompanySupportInfoTextProps = {
  text: string;
};

const CompanySupportInfoText = memo(({ text }: CompanySupportInfoTextProps) => (
  <Linkify
    componentDecorator={(decoratedHref, decoratedText, key) => (
      <ExternalLink className='text-deepblue500' key={key} href={decoratedHref}>
        {decoratedText}
      </ExternalLink>
    )}
  >
    <Typography
      className={styles.text}
      variant='numbers1'
      wordBreak
      nowrap={false}
    >
      {text}
    </Typography>
  </Linkify>
));

export type { CompanySupportInfoTextProps };
export { CompanySupportInfoText };
