import clsx from 'clsx';
import Decimal from 'decimal.js-light';
import { useUnit } from 'effector-react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import { Warning } from '@kuna-pay/accept-payment/shared/ui/warning';

import { CheckoutPageModel } from '../../../page.model';
import styles from './out-of-range.module.scss';

const OutOfRangeInvoiceAmount: FC<{ className?: string }> = ({ className }) => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.choose-payment-method.invoice-amount',
  });

  const {
    InvoiceAsset,
    PaymentMethod,
    invoiceAmount,
    paymentAmount,
    buyerFee,
    PaymentAsset,
    ...invoice
  } = useUnit($$model.$invoice)!;
  const AssetFormat = useAssetFormat();
  //FIXME
  const [options, paymentCostHigherThanFee] = useUnit([
    $$model.$$chosePaymentMethod.$$selectPaymentMethod.$$search.$allOptions,
    $$model.$$chosePaymentMethod.$paymentCostHigherThanFee,
  ]);

  // TODO: Why we need to search through all options?
  const Product = (() => {
    if (invoice.Product) return invoice.Product;

    const { Product } = options.find(
      ({ asset, code }) =>
        PaymentMethod?.code === code && PaymentMethod?.asset === asset
    )!;

    return Product;
  })();

  const isMax = !!Product?.maxAmount
    ? new Decimal(paymentAmount).greaterThanOrEqualTo(Product.maxAmount)
    : false;

  const isMin = !!Product?.minAmount
    ? new Decimal(paymentAmount).lessThanOrEqualTo(Product.minAmount)
    : false;

  return (
    <div className={clsx(className, styles.wrapper)}>
      {isMin ? (
        <Warning
          title={t('warnings.min.title', {
            replace: {
              amount: AssetFormat.formatAmount(
                Product!.minAmount!,
                PaymentAsset!
              ),
            },
          })}
        >
          {t('warnings.min.description')}
        </Warning>
      ) : (
        /**
         * Should not be shown if min amount warning already in view
         */
        <>
          {paymentCostHigherThanFee.isOutOfLimit && (
            <Warning
              title={t('warnings.fee-higher-than-amount.title', {
                replace: {
                  amount: AssetFormat.formatAmount(
                    paymentCostHigherThanFee.minAmount,
                    PaymentAsset!
                  ),
                },
              })}
            >
              {t('warnings.fee-higher-than-amount.description')}
            </Warning>
          )}
        </>
      )}

      {isMax && (
        <Warning
          title={t('warnings.max.title', {
            replace: {
              amount: AssetFormat.formatAmount(
                Product!.maxAmount!,
                PaymentAsset!
              ),
            },
          })}
        >
          {t('warnings.max.description')}
        </Warning>
      )}

      <div className={styles.root}>
        <Typography variant='body3'>{t('invoice-amount')}</Typography>

        <Typography variant='h5'>
          {AssetFormat.formatAmount(invoiceAmount, InvoiceAsset)}
        </Typography>

        <Typography variant='body3'>
          ≈
          {AssetFormat.formatAmount(
            new Decimal(paymentAmount).sub(buyerFee),
            PaymentAsset!
          )}
        </Typography>
      </div>
    </div>
  );
};

export { OutOfRangeInvoiceAmount };
