import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import { NotFoundTemplate as CoreNotFoundTemplate } from '@kuna-pay/core/shared/ui/not-found-template';

import { Footer } from '../footer';
import styles from './not-found-template.module.scss';

type NotFoundTemplateProps = PropsWithChildren & {
  classes?: {
    footer?: string;
  };
};

const NotFoundTemplate = memo(
  ({ classes, children }: NotFoundTemplateProps) => (
    <CoreNotFoundTemplate>
      <Footer
        className={clsx(styles.footer, styles.notFoundFooter, classes?.footer)}
      >
        {children}
      </Footer>
    </CoreNotFoundTemplate>
  )
);

NotFoundTemplate.displayName = 'NotFoundTemplate';

export { NotFoundTemplate };
