import { useUnit } from 'effector-react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanySupportInfoText } from '@kuna-pay/accept-payment/entities/company';
import { LogEventOnMount } from '@kuna-pay/accept-payment/shared/analytics';

import { CheckoutPageModel } from '../../../page.model';
import { AccordionDetails } from '../details';

const SupportInfo: FC<{ className?: string }> = ({ className }) => {
  const $$model = CheckoutPageModel.useModel();
  const { t } = useTranslation();
  const { status, Company } = useUnit($$model.$invoice)!;

  if (!Company.supportInfo) return null;

  return (
    <AccordionDetails
      className={className}
      title={t('pages.checkout.shared.support-info.title', {
        replace: { company: Company.title },
      })}
    >
      <LogEventOnMount
        key={status}
        event='Contact Support Viewed'
        properties={{ status }}
      />

      <CompanySupportInfoText text={Company.supportInfo} />
    </AccordionDetails>
  );
};

export { SupportInfo };
