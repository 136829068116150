import { useUnit } from 'effector-react';
import type { FC } from 'react';

import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';

import { CheckoutPageModel } from '../../../page.model';
import { ConfirmationAwaitingInvoiceAmount } from './confirmation-awaiting.component';
import { InitialInvoiceAmount } from './initial.component';
import { OutOfRangeInvoiceAmount } from './out-of-range.component';
import { SkeletonInvoiceAmount } from './skeleton.component';

type InvoiceAmountProps = {
  className?: string;
};

const InvoiceAmount: FC<InvoiceAmountProps> = ({ className }) => {
  const $$model = CheckoutPageModel.useModel();
  const [isSkeletonLoading, paymentCostHigherThanFee] = useUnit([
    $$model.$$chosePaymentMethod.$loadingInitialRates,
    $$model.$$chosePaymentMethod.$paymentCostHigherThanFee,
  ]);

  const status = useUnit($$model.$status)!;

  if (isSkeletonLoading) {
    return <SkeletonInvoiceAmount className={className} />;
  }

  if (
    status === InvoiceStatus.LimitsOutOfRange ||
    paymentCostHigherThanFee.isOutOfLimit
  ) {
    return <OutOfRangeInvoiceAmount className={className} />;
  }

  if (status === InvoiceStatus.ConfirmationAwaiting) {
    return <ConfirmationAwaitingInvoiceAmount className={className} />;
  }

  return <InitialInvoiceAmount className={className} />;
};

export { InvoiceAmount };
