import { Suspense } from 'react';

import { lazyWithRetryAndRefresh } from '@kuna-pay/utils/lazy';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';

import { ChangeLanguage } from '@kuna-pay/accept-payment/features/change-language';
import { Footer } from '@kuna-pay/accept-payment/shared/ui/footer';
import { MainLayout } from '@kuna-pay/accept-payment/widgets/layouts/main';

import styles from './page.module.scss';

const Page = lazyWithRetryAndRefresh(
  'CocktailPromoPage',
  () => import('./page.component')
);

const CocktailPromoRoute = {
  Component: ({ price }: { price?: string | null }) => (
    <Suspense
      fallback={
        <MainLayout
          classes={{
            root: styles.layout,
            footer: styles.layoutFooter,
          }}
        >
          <Skeleton containerClassName={styles.loading} flex fullWidth />

          <Footer className={styles.footer}>
            <ChangeLanguage />
          </Footer>
        </MainLayout>
      }
    >
      <Page price={price} />
    </Suspense>
  ),
};

export { CocktailPromoRoute };
