import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';
import { CheckoutPageModel } from '@kuna-pay/accept-payment/pages/checkout/page.model';
import { AccordionDetails } from '@kuna-pay/accept-payment/pages/checkout/ui/shared/details';
import { LogEventOnMount } from '@kuna-pay/accept-payment/shared/analytics';

import styles from './invoice-details.module.scss';

type InvoiceDetailsProps = { className?: string };

const InvoiceDetails = createCompoundComponent(
  (Components) =>
    ({ className }: InvoiceDetailsProps) => {
      const $$model = CheckoutPageModel.useModel();

      const { status } = useUnit($$model.$invoice)!;

      return (
        <Components.Root className={className}>
          <LogEventOnMount
            key={status}
            event='Payment Details Viewed'
            properties={{ status }}
          />

          <Components.InvoiceAmount />

          {status === InvoiceStatus.PartiallyPaid && (
            /**
             * Should be shown only if invoice is not paid in full
             * bcs if it is paid in full, then paymentAmount === paidAmount
             */
            <Components.CurrencyAmount />
          )}

          <Components.ExchangeRate />

          <Components.Fee />

          <Components.Network />

          <Components.InvoiceNumber />

          <Components.ExternalOrderId />

          <Components.UserDetails />
        </Components.Root>
      );
    },
  {
    Root: ({
      className,
      ...props
    }: Partial<ComponentPropsWithoutRef<typeof AccordionDetails>>) => {
      const { t } = useTranslation(undefined, {
        keyPrefix: 'pages.checkout.shared.invoice-details',
      });

      return (
        <AccordionDetails
          className={clsx(styles.root, className)}
          title={t('title')}
          {...props}
        />
      );
    },

    InvoiceAmount: () => {
      const $$model = CheckoutPageModel.useModel();
      const { t } = useTranslation(undefined, {
        keyPrefix: 'pages.checkout.shared.invoice-details.invoice-amount',
      });
      const { invoiceAmount, InvoiceAsset } = useUnit($$model.$invoice)!;
      const AssetFormat = useAssetFormat();

      return (
        <AccordionDetails.Row>
          <AccordionDetails.Label>{t('label')}</AccordionDetails.Label>

          <AccordionDetails.Value title={invoiceAmount}>
            {AssetFormat.formatAmount(invoiceAmount, InvoiceAsset)}
          </AccordionDetails.Value>
        </AccordionDetails.Row>
      );
    },

    CurrencyAmount: () => {
      const $$model = CheckoutPageModel.useModel();

      const { t } = useTranslation();
      const AssetFormat = useAssetFormat();
      const { paymentAmount, PaymentAsset } = useUnit($$model.$invoice)!;

      return (
        <AccordionDetails.Row>
          <AccordionDetails.Label>
            {t('pages.checkout.shared.invoice-details.currency-amount.label')}
          </AccordionDetails.Label>

          <AccordionDetails.Value title={paymentAmount}>
            {AssetFormat.formatAmount(paymentAmount, PaymentAsset)}
          </AccordionDetails.Value>
        </AccordionDetails.Row>
      );
    },

    ExchangeRate: () => {
      const $$model = CheckoutPageModel.useModel();
      const { t } = useTranslation();
      const AssetFormat = useAssetFormat();
      const { rate, InvoiceAsset, PaymentAsset } = useUnit($$model.$invoice)!;

      return (
        <AccordionDetails.Row>
          <AccordionDetails.Label>
            {t('pages.checkout.shared.invoice-details.exchange-rate.label')}
          </AccordionDetails.Label>

          <AccordionDetails.Value>
            {AssetFormat.formatExchangeRate(PaymentAsset!, rate, InvoiceAsset)}
          </AccordionDetails.Value>
        </AccordionDetails.Row>
      );
    },

    Fee: () => {
      const $$model = CheckoutPageModel.useModel();
      const { t } = useTranslation();
      const AssetFormat = useAssetFormat();
      const { buyerFee, PaymentAsset } = useUnit($$model.$invoice)!;

      return (
        <AccordionDetails.Row>
          <AccordionDetails.Label>
            {t('pages.checkout.shared.invoice-details.fee.label')}
          </AccordionDetails.Label>

          <AccordionDetails.Value title={buyerFee}>
            {AssetFormat.formatAmount(buyerFee, PaymentAsset!)}
          </AccordionDetails.Value>
        </AccordionDetails.Row>
      );
    },

    Network: () => {
      const $$model = CheckoutPageModel.useModel();
      const { t } = useTranslation();
      const { PaymentMethod } = useUnit($$model.$invoice)!;

      return (
        <>
          {!!PaymentMethod?.network && (
            <AccordionDetails.Row>
              <AccordionDetails.Label>
                {t('pages.checkout.shared.invoice-details.network.label')}
              </AccordionDetails.Label>

              <AccordionDetails.Value title={PaymentMethod.network}>
                {PaymentMethod.network}
              </AccordionDetails.Value>
            </AccordionDetails.Row>
          )}
        </>
      );
    },

    InvoiceNumber: () => {
      const $$model = CheckoutPageModel.useModel();
      const { t } = useTranslation();
      const { id } = useUnit($$model.$invoice)!;

      return (
        <AccordionDetails.Row>
          <AccordionDetails.Label>
            {t('pages.checkout.shared.invoice-details.invoice-id.label')}
          </AccordionDetails.Label>

          <AccordionDetails.IdValue
            value={id}
            customMessage={t(
              'pages.checkout.shared.invoice-details.invoice-id.copy.message'
            )}
          >
            {id}
          </AccordionDetails.IdValue>
        </AccordionDetails.Row>
      );
    },

    ExternalOrderId: () => {
      const $$model = CheckoutPageModel.useModel();
      const { t } = useTranslation();
      const { externalOrderId } = useUnit($$model.$invoice)!;

      return (
        <>
          {!!externalOrderId && (
            <AccordionDetails.Row>
              <AccordionDetails.Label>
                {t(
                  'pages.checkout.shared.invoice-details.external-order-id.label'
                )}
              </AccordionDetails.Label>

              <AccordionDetails.IdValue
                value={externalOrderId!}
                customMessage={t(
                  'pages.checkout.shared.invoice-details.external-order-id.copy.message'
                )}
              >
                {externalOrderId}
              </AccordionDetails.IdValue>
            </AccordionDetails.Row>
          )}
        </>
      );
    },

    UserDetails: () => {
      const $$model = CheckoutPageModel.useModel();
      const { t } = useTranslation();
      const { productCategory, productDescription } = useUnit(
        $$model.$invoice
      )!;

      return (
        <>
          {(!!productCategory || !!productDescription) && (
            <>
              <AccordionDetails.Divider />

              {!!productCategory && (
                <AccordionDetails.Column>
                  <AccordionDetails.Label>
                    {t(
                      'pages.checkout.shared.invoice-details.user-details.category.label'
                    )}
                  </AccordionDetails.Label>

                  <AccordionDetails.Value>
                    {productCategory}
                  </AccordionDetails.Value>
                </AccordionDetails.Column>
              )}

              {!!productDescription && (
                <AccordionDetails.Column>
                  <AccordionDetails.Label>
                    {t(
                      'pages.checkout.shared.invoice-details.user-details.description.label'
                    )}
                  </AccordionDetails.Label>

                  <AccordionDetails.Value
                    className={styles.preWrap}
                    wordBreak
                    nowrap={false}
                  >
                    {productDescription}
                  </AccordionDetails.Value>
                </AccordionDetails.Column>
              )}
            </>
          )}
        </>
      );
    },
  }
);

export { InvoiceDetails };
