import clsx from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@kuna-pay/ui/ui/typography';

import notFoundImg from './404.png';
import styles from './not-found-template.module.scss';

const NotFoundTemplate: FC<PropsWithChildren & { className?: string }> = ({
  children,
  className,
}) => {
  const { t } = useTranslation('core', {
    keyPrefix: 'shared.ui.not-found-template',
  });

  return (
    <>
      <div className={clsx(styles.root, className)}>
        <img src={notFoundImg} alt='' />

        <Typography variant='h5'>{t('title')}</Typography>

        <Typography className='text-black600' variant='body3'>
          {t('description')}
        </Typography>
      </div>

      {children}
    </>
  );
};

NotFoundTemplate.displayName = 'NotFoundTemplate';

export { NotFoundTemplate };
