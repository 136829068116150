import { createEvent } from 'effector';
import { restore } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';

const SelectRootFactory = modelFactory(() => {
  const change = createEvent<boolean>();
  const reset = createEvent();
  const $open = restore(change, false).reset(reset);

  return {
    $isOpen: $open,
    closed: change.filter({ fn: (isOpen) => !isOpen }),
    opened: change.filter({ fn: (isOpen) => isOpen }),
    open: change.prepend(() => true),
    close: change.prepend(() => false),
    reset,

    $$ui: {
      $open,
      change,

      open: change.prepend(() => true),
      close: change.prepend(() => false),
    },
    __: {},
  };
});

export { SelectRootFactory };
