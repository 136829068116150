import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { ControlInputAdornmentProps } from '@kuna-pay/ui/components/control';
import { Control } from '@kuna-pay/ui/components/control';
import { CloseIcon } from '@kuna-pay/ui/icons';
import { IconButton } from '@kuna-pay/ui/ui/button';

import styles from './search.clear.module.scss';

type SearchClearRootProps = PropsWithChildren &
  Omit<ControlInputAdornmentProps, 'position'> & {
    hidden?: boolean;
    onClear?: () => void;
  };

type SearchClearProps = Omit<SearchClearRootProps, 'children'>;

const SearchClear = createCompoundComponent(
  ({ Root, Icon }) =>
    forwardRef<HTMLButtonElement, SearchClearProps>((props, ref) => (
      <Root ref={ref} {...props}>
        <Icon />
      </Root>
    )),
  {
    Root: forwardRef<HTMLButtonElement, SearchClearRootProps>(
      ({ className, children, size, hidden, onClear, ...props }, ref) => {
        hidden ||= !onClear;

        return (
          <Control.Input.Adornment
            className={clsx(styles.root, className, 'disable-transition', {
              [styles.hidden]: hidden,
            })}
            position='end'
            size={size}
            asChild
            {...props}
          >
            <IconButton ref={ref} onClick={() => onClear?.()} disabled={hidden}>
              {children}
            </IconButton>
          </Control.Input.Adornment>
        );
      }
    ),

    Icon: CloseIcon,
  }
);

export { SearchClear };
export type { SearchClearRootProps };
