import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';
import { InvoiceStatusText } from '@kuna-pay/core/entities/invoice/ui/status/text.ui';
import { InvoiceStatus } from '@kuna-pay/core/generated/public/graphql';

import { SendReceipt } from '@kuna-pay/accept-payment/pages/checkout/ui/send-receipt';
import {
  $$rateYourExperienceFeedback,
  RateYourExperienceFeedbackKey,
} from '@kuna-pay/accept-payment/shared/feedback';

import successImg from '../../../assets/success.png';
import { CheckoutPageModel } from '../../../page.model';
import { InvoiceDetails, InvoiceTabs, RedirectUrlButton } from '../../shared';
import styles from './paid.module.scss';

const OrderDetailsPaid: FC<PropsWithChildren & { className?: string }> = ({
  className,
  children,
}) => {
  const $$model = CheckoutPageModel.useModel();
  const invoice = useUnit($$model.$invoice)!;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.paid',
  });
  const AssetFormat = useAssetFormat();

  const sendReceipt = useUnit($$model.$$orderDetails.$$sendReceipt);

  const { PaymentAsset, paidAmount, isPaymentAfterTimeout } = invoice!;

  useEffect(() => {
    $$rateYourExperienceFeedback.rate(
      RateYourExperienceFeedbackKey.InvoicePayment
    );
  }, []);

  return (
    <Paper
      className={clsx(className, styles.root, {
        [styles.grow]: !sendReceipt.isOpen,
      })}
    >
      {!sendReceipt.isOpen ? (
        <InvoiceTabs noTopPadding>
          <div className={styles.container}>
            <div className={styles.imgContainer}>
              <img src={successImg} width={112} height={112} alt='' />
            </div>

            <Typography className={styles.title} variant='h7'>
              <InvoiceStatusText
                status={InvoiceStatus.Paid}
                isAfterTimeout={isPaymentAfterTimeout}
              />
            </Typography>

            <div className={styles.amountContainter}>
              <Typography className='text-black500' variant='body3'>
                {t('paid-amount')}
              </Typography>

              <Typography variant='h6'>
                {AssetFormat.formatAmount(paidAmount, PaymentAsset!)}
              </Typography>
            </div>

            <InvoiceDetails />

            <RedirectUrlButton />

            <SendReceipt.Button
              $$model={$$model.$$orderDetails.$$sendReceipt}
            />

            {children}
          </div>
        </InvoiceTabs>
      ) : (
        <>
          <SendReceipt $$model={$$model.$$orderDetails.$$sendReceipt} />

          {children}
        </>
      )}
    </Paper>
  );
};

export { OrderDetailsPaid };
