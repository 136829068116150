import type { Store } from 'effector';
import { combine } from 'effector';

import type { PublicInvoiceDetailsOutput } from '../../api';
import { ProgressQuestionnaireState } from './questionnaire.progress.state';

class ProgressModel {
  public static createModel(
    $invoice: Store<PublicInvoiceDetailsOutput | null>
  ) {
    return {
      $state: combine($invoice, (invoice) =>
        new ProgressQuestionnaireState().state(invoice)
      ),
    };
  }
}

export { ProgressModel };
