import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TagProps } from '@kuna-pay/ui/ui/tag';
import { Tag } from '@kuna-pay/ui/ui/tag';
import type { TransferStatus } from '@kuna-pay/core/generated/public/graphql';

import { TransactionsStatusMapping } from './transaction-status.mappings';
import { TransactionStatusText } from './transaction-status.text';

type TransactionStatusTagProps = Partial<Omit<TagProps, 'children'>> & {
  status: TransferStatus;
};
const TransactionStatusTag = createCompoundComponent(
  (Components) =>
    ({ status, ...props }: TransactionStatusTagProps) =>
      (
        <Components.Root status={status} {...props}>
          <Components.Text status={status} />
        </Components.Root>
      ),
  {
    Root: ({
      status,
      ...props
    }: Partial<TagProps> & { status: TransferStatus }) => (
      <Tag variant={TransactionsStatusMapping.tagVariant[status]} {...props} />
    ),

    Text: TransactionStatusText,
  }
);

export { TransactionStatusTag };
export type { TransactionStatusTagProps };
