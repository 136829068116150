/* eslint-disable react/destructuring-assignment */
import clsx from 'clsx';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { InfoIcon } from '@kuna-pay/ui/icons';
import type { PaperProps } from '@kuna-pay/ui/ui/paper';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Tooltip } from '@kuna-pay/ui/ui/tooltip';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './properties.module.scss';

type DescriptionDetailsPropertiesRootProps = Omit<PaperProps, 'color'> & {
  color?: 'white' | 'grey' | 'transparent';

  disablePadding?: boolean;

  theme?: DescriptionDetailsPropertiesTheme;
};

type DescriptionDetailsPropertiesTheme = {
  title: TypographyProps['variant'];
  value: TypographyProps['variant'];
  tooltip: TypographyProps['variant'];
};

const ThemeContext = createContext<DescriptionDetailsPropertiesTheme>({
  title: 'body3',
  value: 'subtitle5',
  tooltip: 'numbers2',
});

const DescriptionDetailsProperties = createCompoundComponent(
  ({ Root }) => Root,
  {
    Provider: ({
      children,
      ...theme
    }: PropsWithChildren & DescriptionDetailsPropertiesTheme) => (
      <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
    ),

    Root: ({
      className,
      color = 'grey',
      theme,
      disablePadding,
      ...props
    }: DescriptionDetailsPropertiesRootProps) => {
      const content = (
        <Paper
          className={clsx(
            styles.container,
            {
              [styles.grey]: color === 'grey',
              [styles.white]: color === 'white',
              [styles.transparent]: color === 'transparent',
              [styles.containerPadding]: !disablePadding,
            },
            className
          )}
          rounded='lg'
          {...props}
        />
      );

      if (theme) {
        return (
          <ThemeContext.Provider value={theme}>{content}</ThemeContext.Provider>
        );
      }

      return content;
    },

    SectionTitle: ({
      className,
      ...props
    }: PartialField<TypographyProps, 'variant'>) => (
      <Typography
        className={clsx(styles.sectionTitle, className)}
        variant='subtitle2'
        nowrap
        {...props}
      />
    ),

    Row: ({
      className,
      variant = 'horizontal',
      ...props
    }: HTMLAttributes<HTMLDivElement> & {
      variant?: 'horizontal' | 'vertical';
    }) => (
      <div
        className={clsx(
          styles.row,
          { [styles.vertical]: variant === 'vertical' },
          className
        )}
        {...props}
      />
    ),

    Title: ({
      className,
      ...props
    }: PartialField<TypographyProps, 'variant'>) => {
      const theme = useContext(ThemeContext);

      return (
        <Typography
          className={clsx(styles.title, className)}
          variant={theme.title}
          nowrap
          {...props}
        />
      );
    },

    TitleWithTooltip: ({
      children,
      tooltip = {},
      ...props
    }: PartialField<TypographyProps, 'variant'> & {
      tooltip: PartialField<TypographyProps, 'variant'>;
    }) => {
      const theme = useContext(ThemeContext);

      return (
        <div className={styles.tooltipContainer}>
          <DescriptionDetailsProperties.Title {...props}>
            {children}
          </DescriptionDetailsProperties.Title>

          <Tooltip
            side='right'
            sideOffset={1}
            content={
              <Typography
                className={styles.tooltipText}
                variant={theme.tooltip}
                {...tooltip}
              />
            }
          >
            <InfoIcon className={styles.tooltipIcon} />
          </Tooltip>
        </div>
      );
    },

    Value: ({
      className,
      title,
      children,
      ...props
    }: PartialField<TypographyProps, 'variant'>) => {
      const theme = useContext(ThemeContext);

      return (
        <Typography
          className={className}
          variant={theme.value}
          nowrap
          title={title ?? (typeof children === 'string' ? children : title)}
          {...props}
        >
          {children}
        </Typography>
      );
    },

    Empty: (
      props: Omit<PartialField<TypographyProps, 'variant'>, 'children'>
    ) => {
      const theme = useContext(ThemeContext);

      return (
        <Typography variant={theme.value} {...props}>
          -
        </Typography>
      );
    },

    Divider: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.divider, className)} {...props} />
    ),
  }
);

export { DescriptionDetailsProperties };
export type { DescriptionDetailsPropertiesRootProps };
