/**
 * Build environment
 */

if (import.meta.env.PROD) {
  try {
    console.log(
      'Bundled at:',
      new Date(parseInt(import.meta.env.CREATED_AT)).toLocaleString('UK')
    );
  } catch (e) {
    console.error('Failed to parse bundle date. \n', e);
  }
}

const environment = {
  stage: import.meta.env.STAGE,

  development: import.meta.env.DEV,
  production: import.meta.env.PROD,

  backend: {
    gw: {
      url: import.meta.env.API_URL,
    },
  },

  sentry: {
    dsn: import.meta.env.SENTRY_DSN,
    environment: import.meta.env.SENTRY_ENVIRONMENT,
    release: import.meta.env.SENTRY_RELEASE_COMMIT_SHA,
  },

  amplitude: {
    apiKey: import.meta.env.AMPLITUDE_API_KEY,
    release: import.meta.env.SENTRY_RELEASE_COMMIT_SHA,
  },

  clarity: {
    id: import.meta.env.MICROSOFT_CLARITY_ID,
  },

  reCaptcha: {
    siteKey: import.meta.env.RE_CAPTCHA_SITE_KEY,
  },
};

if (import.meta.env.STAGE !== 'PROD') {
  console.log('Environment', { environment });
}

export { environment };
