import { HttpTransport } from '@kuna/graphql-client';

import { KunaPayGraphqlClient } from '@kuna-pay/core/shared/api/http';

import { environment } from '@kuna-pay/accept-payment/shared/config';

const nonAuthGraphqlClient = new KunaPayGraphqlClient({
  transport: new HttpTransport({
    url: environment.backend.gw.url,
  }),
});

export { nonAuthGraphqlClient };
