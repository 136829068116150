import { createContext, useContext } from 'react';

import { invariant } from '@kuna-pay/utils/invariant';

import type {
  ControlColor,
  ControlSize,
  ControlState,
  ControlVariant,
  TriggerControlSize,
} from './control.types';

const ControlContext = createContext<{
  size: ControlSize;
  variant: ControlVariant;
  color: ControlColor;
} | null>(null);

const useControlContext = () => {
  const value = useContext(ControlContext);

  invariant.error(
    value,
    'useControlContext must be used within a ControlContext.Provider'
  );

  return value;
};

const ControlContextProvider = ControlContext.Provider;

const TriggerControlContext = createContext<{
  size: TriggerControlSize;
  variant: ControlVariant;
  color: ControlColor;
  state: ControlState;
} | null>(null);

const useTriggerControlContext = () => {
  const value = useContext(TriggerControlContext);

  invariant.error(
    value,
    'useControlContext must be used within a ControlContext.Provider'
  );

  return value;
};

const TriggerControlContextProvider = TriggerControlContext.Provider;

export {
  ControlContextProvider,
  TriggerControlContextProvider,
  useControlContext,
  useTriggerControlContext,
};
