import type { Select } from '@kuna/graphql-util/dist/select';
import { createEffect } from 'effector';

import { ASSET_SELECT } from '@kuna-pay/core/entities/asset';

import type {
  NonAuthInvoiceDetailsOutput,
  QueryFindPublicInvoiceByIdArgs,
  QuestionnaireForNonAuthOutput,
} from '@kuna-pay/accept-payment/generated/graphql';
import { ProductFeeStrategy } from '@kuna-pay/accept-payment/generated/graphql';
import { findPublicInvoiceById } from '@kuna-pay/accept-payment/shared/api/generated/Invoice/request/findPublicInvoiceById';
import { findOneQuestionnaireToFillForNonAuth } from '@kuna-pay/accept-payment/shared/api/generated/Questionnaire/request/findOneQuestionnaireToFillForNonAuth';

type PublicInvoiceDetailsOutput = NonAuthInvoiceDetailsOutput & {
  questionnaire: QuestionnaireForNonAuthOutput | null;

  buyerFee: string;
};

const findPublicInvoiceByIdFx = createEffect({
  handler: async (
    params: QueryFindPublicInvoiceByIdArgs
  ): Promise<PublicInvoiceDetailsOutput> => {
    const SELECT: Select<NonAuthInvoiceDetailsOutput> = {
      id: true,
      completedAt: true,
      isPaymentAfterTimeout: true,

      companyId: true,
      status: true,
      createdAt: true,
      creatorId: true,
      expireAt: true,
      paymentAmount: true,
      paidAmount: true,
      paymentFee: true,
      paymentAssetCode: true,
      invoiceAmount: true,
      invoiceAssetCode: true,
      externalOrderId: true,
      rate: true,
      updatedAt: true,
      productCategory: true,
      productDescription: true,
      rateFreezeAt: true,
      Address: {
        address: true,
        memo: true,
        id: true,
        source: true,
      },
      Company: {
        id: true,
        title: true,
        supportInfo: true,
        avatar: true,
        websiteUrl: true,
      },
      PaymentAsset: {
        ...ASSET_SELECT,
        position: true,
        description: true,
        isInvoiceCurrency: true,
        isPaymentCurrency: true,
        isReferenceCurrency: true,
        isAutoConvertCurrency: true,
      },

      PaymentMethod: {
        code: true,
        network: true,
        position: true,
        asset: true,
        processor: true,
      },

      Product: {
        feeStrategy: true,
        minAmount: true,
        maxAmount: true,
      },

      Transfers: {
        id: true,
        status: true,
        amount: true,
        processedAmount: true,
        fee: true,
        createdAt: true,
        Details: {
          txId: true,
        },
        PaymentMethod: {
          code: true,
          network: true,
          position: true,
          asset: true,
          processor: true,
        },
        AmlDetails: {
          riskScore: true,
        },
        Asset: ASSET_SELECT,
      },

      InvoiceAsset: {
        ...ASSET_SELECT,
        position: true,
        description: true,
        isInvoiceCurrency: true,
        isPaymentCurrency: true,
        isReferenceCurrency: true,
        isAutoConvertCurrency: true,
      },
      Customization: {
        redirect: {
          url: true,
          buttonTitle: true,
        },
      },
    };

    const [result, questionnaire] = await Promise.all([
      findPublicInvoiceById(SELECT)(params),

      findOneQuestionnaireToFillForNonAuth({
        id: true,
        Details: {
          isFilled: true,
          schema: {
            name: true,
            description: true,
            position: true,
            type: true,
            label: true,
            validators: {
              type: true,
              value: true,
            },
          },
        },
      })({ entityId: params.id }),
    ]);

    if (questionnaire?.Details?.schema) {
      questionnaire.Details.schema.sort((a, b) => a.position - b.position);
    }

    if (result.Customization?.redirect?.url) {
      if (
        !(
          result.Customization.redirect.url.startsWith('https://') ||
          result.Customization.redirect.url.startsWith('http://')
        )
      ) {
        result.Customization.redirect.url = `https://${result.Customization.redirect.url}`;
      }
    }

    return Object.assign(result, {
      questionnaire: questionnaire ?? null,

      buyerFee:
        result.Product?.feeStrategy === ProductFeeStrategy.Internal
          ? '0'
          : (result.paymentFee as string),
    });
  },
});

export { findPublicInvoiceByIdFx };
export type { PublicInvoiceDetailsOutput };
