import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencyMismatchNoticeIcon } from '@kuna-pay/core/entities/transaction';
import { Notice } from '@kuna-pay/core/entities/transaction/ui/notice';

import styles from './currency-mismatch-notice.module.scss';

type BuyerCurrencyMismatchInvoiceDepositNoticeProps = {
  className?: string;
};

const BuyerCurrencyMismatchInvoiceDepositNotice = memo(
  ({ className }: BuyerCurrencyMismatchInvoiceDepositNoticeProps) => {
    const { t } = useTranslation('common', {
      keyPrefix:
        'entities.transaction.invoice-deposit.notice.currency-mismatch',
    });

    return (
      <Notice
        className={clsx(styles.root, className)}
        t={t}
        icon={<CurrencyMismatchNoticeIcon />}
      />
    );
  }
);

export { BuyerCurrencyMismatchInvoiceDepositNotice };
export type { BuyerCurrencyMismatchInvoiceDepositNoticeProps };
