import { createFeatureFlagFactory } from '@kuna-pay/core/shared/feature-flags';

import type { AvailableFeatureFlags } from '../types';
import { $$featureFlags } from './model.singleton';

const createFeatureFlag = createFeatureFlagFactory<AvailableFeatureFlags>(
  $$featureFlags.$features
);

export { createFeatureFlag };
