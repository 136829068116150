import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { TransferStatus } from '@kuna-pay/core/generated/public/graphql';

import { TransactionsStatusMapping } from './transaction-status.mappings';

const TransactionStatusText: FC<{ status: TransferStatus }> = ({ status }) => {
  const { t } = useTranslation('core', {
    keyPrefix: 'entities.transaction.ui.status',
  });

  return <>{t(TransactionsStatusMapping.text[status] ?? status)}</>;
};

export { TransactionStatusText };
