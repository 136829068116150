import clsx from 'clsx';
import type { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import styles from './input.module.scss';

type UnstyledInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  /**
   *  Types of property 'crossOrigin' are incompatible.
   *  Type 'string | undefined' is not assignable to type '"" | "anonymous" | "use-credentials" | undefined'.
   *  Type 'string' is not assignable to type '"" | "anonymous" | "use-credentials" | undefined'.
   */
  'crossOrigin'
>;

const UnstyledInput = Object.assign(
  forwardRef<HTMLInputElement, UnstyledInputProps>(
    ({ className, ...props }, ref) => (
      <input ref={ref} className={clsx(styles.input, className)} {...props} />
    )
  ),
  {
    className: styles.input,
  }
);

type UnstyledTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;
const UnstyledTextarea = Object.assign(
  forwardRef<HTMLTextAreaElement, UnstyledTextareaProps>(
    ({ className, ...props }, ref) => (
      <textarea
        ref={ref}
        className={clsx(styles.textarea, className)}
        {...props}
      />
    )
  ),
  {
    className: styles.textarea,
  }
);

export type { UnstyledInputProps, UnstyledTextareaProps };
export { UnstyledInput, UnstyledTextarea };
