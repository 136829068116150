import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { InvoiceStatusText } from '@kuna-pay/core/entities/invoice/ui/status/text.ui';

import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';
import { CheckoutPageModel } from '@kuna-pay/accept-payment/pages/checkout/page.model';

import errorImg from '../../../assets/error.png';
import { InvoiceDetails, SupportInfo } from '../../shared';
import styles from './timeout.module.scss';

const OrderDetailsTimeout: FC<PropsWithChildren & { className?: string }> = ({
  className,
  children,
}) => {
  const $$model = CheckoutPageModel.useModel();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.timeout',
  });
  const { Company } = useUnit($$model.$invoice)!;

  return (
    <Paper className={clsx(className, styles.root)}>
      <div className={styles.imgContainer}>
        <img src={errorImg} width={112} height={112} alt='' />
      </div>

      <Typography className={styles.title} variant='h7'>
        <InvoiceStatusText status={InvoiceStatus.Timeout} />
      </Typography>

      <Typography className={styles.subtitle} variant='body3'>
        {t('description', { replace: { company: Company.title } })}
      </Typography>

      <InvoiceDetails
        className={clsx({
          [styles.invoiceDetails]: !!Company.supportInfo,
        })}
      />

      <SupportInfo />

      {children}
    </Paper>
  );
};

export { OrderDetailsTimeout };
