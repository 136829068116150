import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';

import { ControlContextProvider, useControlContext } from './control.context';
import type {
  ControlInputAdornmentProps,
  ControlInputContentProps,
  ControlInputRootProps,
} from './control.input.types';
import styles from './control.input.module.scss';

const ControlInput = {
  Root: forwardRef<HTMLDivElement, ControlInputRootProps>(
    ({ asChild, className, variant, color, state, size, ...props }, ref) => {
      const Component = asChild ? Slot : 'div';

      const contextValue = useMemo(
        () => ({ size, variant, color }),
        [size, variant, color]
      );

      return (
        <ControlContextProvider value={contextValue}>
          <Component
            ref={ref}
            className={clsx(
              styles.root,
              {
                [styles.contained]: variant === 'contained',
                [styles.outlined]: variant === 'outlined',
              },
              {
                [styles.grey]: color === 'grey',
                [styles.white]: color === 'white',
              },
              className
            )}
            data-state={state}
            {...props}
          />
        </ControlContextProvider>
      );
    }
  ),

  Content: forwardRef<HTMLDivElement, ControlInputContentProps>(
    (
      { asChild, className, hasStartAdornment, hasEndAdornment, ...props },
      ref
    ) => {
      const Component = asChild ? Slot : 'div';

      const { size } = useControlContext();

      return (
        <Component
          ref={ref}
          className={clsx(
            styles.content,
            {
              [styles.xs]: size === 'xs',
              [styles.sm]: size === 'sm',
              [styles.md]: size === 'md',
              [styles.lg]: size === 'lg',
              [styles.xl]: size === 'xl',
            },
            {
              [styles.start]: hasStartAdornment,
              [styles.end]: hasEndAdornment,
            },
            className
          )}
          {...props}
        />
      );
    }
  ),

  Adornment: forwardRef<HTMLDivElement, ControlInputAdornmentProps>(
    ({ asChild, className, position, ...props }, ref) => {
      const Component = asChild ? Slot : 'div';

      const { size } = useControlContext();

      return (
        <Component
          ref={ref}
          className={clsx(
            styles.adornment,
            {
              [styles.xs]: size === 'xs',
              [styles.sm]: size === 'sm',
              [styles.md]: size === 'md',
              [styles.lg]: size === 'lg',
              [styles.xl]: size === 'xl',
            },
            {
              [styles.start]: position === 'start',
              [styles.end]: position === 'end',
            },
            className
          )}
          {...props}
        />
      );
    }
  ),
};

export { ControlInput };
