import '@kuna-pay/utils/vite/define-global';
import '@kuna-pay/ui/global';

import { withErrorBoundary } from '@kuna-pay/ui/error-boundary';

import { Pages } from '@kuna-pay/accept-payment/pages';

import { withProvider } from './app.provider';
import { AppBoot } from './boot';

const App = withErrorBoundary(
  withProvider(() => (
    <AppBoot>
      <Pages />
    </AppBoot>
  ))
);
export { App };
