import * as RadixPopover from '@radix-ui/react-popover';
import clsx from 'clsx';
import type { TouchEvent, WheelEvent } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import { forwardRef } from 'react';

import { Modal } from '@kuna-pay/ui/ui/modal';

import styles from './content.module.scss';

type DropdownContentProps = RadixPopover.PopoverContentProps & {
  disableFlex?: boolean;
  disablePadding?: boolean;
  hasSearchAndSearchSizeIsSmall?: boolean; // xs|sm

  rounded?: 'none' | 'sm' | 'md' | 'lg';

  /**
   *
   * If scroll inside <Select.Content> is not working, try to set this prop to true
   *
   * TODO: Check if onWheel event is fixed original issue
   */
  disablePortal?: boolean;
};

const SelectContent = forwardRef<HTMLDivElement, DropdownContentProps>(
  (
    {
      className,
      disableFlex,
      disablePadding,
      hasSearchAndSearchSizeIsSmall,
      rounded = 'sm',
      disablePortal,
      ...props
    },
    ref
  ) => {
    const $$fixScrollForModaledPopover = useFixScrollForModaledPopover();
    const { isWithinModal } = useContext(Modal.Context);

    const element = (
      <RadixPopover.Content
        className={clsx(
          styles.headless,
          styles.content,
          hasSearchAndSearchSizeIsSmall && [
            styles.hasSearch,
            styles.searchSizeSmall,
          ],
          { [styles.md]: !disablePadding },
          { [styles.flex]: !disableFlex },
          {
            [styles.roundedSm]: rounded === 'sm',
            [styles.roundedMd]: rounded === 'md',
            [styles.roundedLg]: rounded === 'lg',
          },
          className
        )}
        ref={ref}
        sideOffset={4}
        collisionPadding={20}
        onWheel={
          isWithinModal ? $$fixScrollForModaledPopover.onWheel : undefined
        }
        onTouchStart={
          isWithinModal ? $$fixScrollForModaledPopover.onTouchStart : undefined
        }
        onTouchMove={
          isWithinModal ? $$fixScrollForModaledPopover.onTouchMove : undefined
        }
        {...props}
      />
    );

    if (disablePortal) {
      return <>{element}</>;
    }

    return <RadixPopover.Portal>{element}</RadixPopover.Portal>;
  }
);

/**
 * @see https://kunatech.atlassian.net/browse/KUPAY-2043
 * @see https://kunatech.atlassian.net/browse/KUPAY-2253?focusedCommentId=33436
 * @see https://github.com/radix-ui/primitives/issues/1159#issuecomment-1741282769
 * @see https://github.com/shadcn-ui/ui/pull/2123
 */
const useFixScrollForModaledPopover = () => {
  const touchPosRef = useRef<number | null>(null);

  const onWheel = (event: WheelEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const isScrollingDown = event.deltaY > 0;

    if (isScrollingDown) {
      event.currentTarget.dispatchEvent(
        new KeyboardEvent('keydown', { key: 'ArrowDown' })
      );
    } else {
      event.currentTarget.dispatchEvent(
        new KeyboardEvent('keydown', { key: 'ArrowUp' })
      );
    }
  };

  const onTouchStart = (event: TouchEvent<HTMLDivElement>) => {
    touchPosRef.current = event.changedTouches[0]?.clientY ?? null;
  };

  const onTouchMove = (event: TouchEvent<HTMLDivElement>) => {
    const touchPos = event.changedTouches[0]?.clientY ?? null;

    if (touchPosRef.current === null || touchPos === null) {
      return;
    }

    event.stopPropagation();
    const isScrollingDown = touchPosRef.current < touchPos;

    if (isScrollingDown) {
      event.currentTarget.dispatchEvent(
        new KeyboardEvent('keydown', { key: 'ArrowDown' })
      );
    } else {
      event.currentTarget.dispatchEvent(
        new KeyboardEvent('keydown', { key: 'ArrowUp' })
      );
    }

    touchPosRef.current = touchPos;
  };

  return { onWheel, onTouchStart, onTouchMove };
};

export { SelectContent };
