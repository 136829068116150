import { type ElementRef, useLayoutEffect, useRef, useState } from 'react';

const useCollectBuyerInfoHeaderColumnWrapped = () => {
  const containerRef = useRef<ElementRef<'div'>>(null);
  const companyColumnRef = useRef<ElementRef<'div'>>(null);
  const amountColumnRef = useRef<ElementRef<'div'>>(null);
  const [isRowWrapped, setRowWrapped] = useState(false);

  useLayoutEffect(() => {
    function calculateOffset() {
      if (!companyColumnRef.current || !amountColumnRef.current) {
        return;
      }

      const companyColumnTop =
        companyColumnRef.current.getBoundingClientRect().top;

      const amountColumnTop =
        amountColumnRef.current.getBoundingClientRect().top;

      setRowWrapped(amountColumnTop > companyColumnTop);
    }

    const observer = new ResizeObserver((entries) => {
      entries.forEach((_entry) => {
        calculateOffset();
      });
    });

    calculateOffset();

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return {
    isRowWrapped,

    containerRef,
    companyColumnRef,
    amountColumnRef,
  };
};

export { useCollectBuyerInfoHeaderColumnWrapped };
