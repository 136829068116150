import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '@kuna-pay/ui/router';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';

import { RiskScorePieChart } from '../../risk-score';
import type { NoticeProps } from '../base';
import { Notice } from '../base';
import styles from './risk-score.module.scss';

type RiskScoreNoticeProps = Omit<NoticeProps, 't' | 'tOptions'> &
  Partial<Pick<NoticeProps, 't' | 'tOptions'>> & {
    riskScore: string;
    keyPrefix: string;
    namespace: string;
  };

const RiskScoreNotice = memo(
  ({
    riskScore,
    className,
    namespace,
    keyPrefix,
    ...props
  }: RiskScoreNoticeProps) => {
    const { t, i18n } = useTranslation(namespace, { keyPrefix });

    return (
      <Notice
        className={clsx(styles.root, className)}
        t={t}
        components={{
          'aml-policy': (
            <ExternalLink
              className={Typography.classes.numbers1}
              href={urls.aml.policy.withLocale(i18n.language)}
              primary
            />
          ),
        }}
        icon={<RiskScorePieChart percent={parseFloat(riskScore)} />}
        {...props}
      />
    );
  }
);

export type { RiskScoreNoticeProps };
export { RiskScoreNotice };
