import { pageView } from '@kuna-pay/utils/effector/factorio';

import { ChangeLanguage } from '@kuna-pay/accept-payment/features/change-language';
import { NotFoundTemplate } from '@kuna-pay/accept-payment/shared/ui/not-found-template';
import { MainLayout } from '@kuna-pay/accept-payment/widgets/layouts/main';

import { NotFoundPageModel } from './page.model';

const NotFoundPage = pageView(NotFoundPageModel, () => (
  <MainLayout>
    <NotFoundTemplate>
      <ChangeLanguage />
    </NotFoundTemplate>
  </MainLayout>
));

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
