function getSecondsFromTimeInSeconds(
  timeInSeconds: number,
  config = { leftPad: true }
) {
  const seconds = (timeInSeconds % 60).toString();

  return config.leftPad ? seconds.padStart(2, '0') : seconds;
}

function getMinutesFromTimeInSeconds(timeInSeconds: number): string {
  return Math.floor(timeInSeconds / 60)
    .toString()
    .padStart(2, '0');
}

export { getMinutesFromTimeInSeconds, getSecondsFromTimeInSeconds };
