import { listen } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import { CookieConsent } from '@kuna-pay/core/shared/cookie-consent';

import { $$analytics } from '@kuna-pay/accept-payment/shared/analytics';
import { environment } from '@kuna-pay/accept-payment/shared/config';

const $$cookieConsent = atom(() => {
  const $$consent = CookieConsent.factory.createModel({
    name: '$$cookie-consent',

    persist: 'cookie-consent',
  });

  listen({
    name: '$$cookie-consent.onSave',
    clock: $$consent.done,
    handler: ({ analytics }) => {
      // Because we only have PROD stage for clarity
      if (environment.stage === 'PROD') {
        import('react-microsoft-clarity').then(({ clarity }) => {
          if (!clarity.hasStarted()) {
            clarity.init(environment.clarity.id);
          }

          // Microsoft Clarity still can work if consent is not provided
          if (analytics) {
            clarity.consent();
          }
        });
      }

      // Exclude analytics from local development cycle
      // to not exceed the limit of events
      if (!import.meta.env.DEV) {
        if (analytics) {
          void $$analytics.initFx({});
        }
      }
    },
  });

  return {
    init: $$consent.init,
    $$ui: {
      $$consent,
    },
  };
});

export { $$cookieConsent };
