import type { Store } from 'effector';
import { combine, createEvent, createStore, sample, split } from 'effector';
import { and, not, snapshot, throttle } from 'patronum';
import { v4 } from 'uuid';

import { modelFactory } from '@kuna-pay/utils/effector';
import { atom, bridge } from '@kuna-pay/utils/misc';

import { createIntersectionObserver } from '../lib';
import type { OptionsLoader } from './loader';

type OptionsState = 'options' | 'loading' | 'searching' | 'not-found' | 'empty';

const SelectOptionsFactory = modelFactory(
  ({
    $$loader,
    $searchQuery,
  }: {
    $$loader: OptionsLoader;

    $searchQuery: Store<string>;
  }) => {
    const reset = createEvent();

    const $options =
      $$loader.$options ?? createStore<string[]>([], { name: '$options' });

    if ($options.reinit) {
      $options
        .on($$loader.$$load.done, (_, payload) => [...new Set(payload)])

        .on($$loader.$$loadMore.done, (options, { newOptions }) => [
          ...new Set(options.concat(newOptions)),
        ]);
    }

    const $filteredOptions = combine(
      $options,
      $searchQuery,
      (options, query) => {
        if (!$$loader.$$search.sync) return options;

        return $$loader.$$search.filter(options, query);
      }
    );

    const $optionsLength = combine($options, (options) => options.length);
    const $filteredOptionsLength = combine(
      $filteredOptions,
      (options) => options.length
    );

    const $$loadMore = atom(() => {
      const $$observer = createIntersectionObserver();

      const $hasMore = createStore(true)
        .on($searchQuery, () => true)
        .on($$loader.$$loadMore.done, (_, { hasMore }) => hasMore)
        .reset(reset);

      const $uniqueIDToRetriggerObserverThenAlreadyInViewAfterLoadMore =
        createStore(v4())
          .on($$loader.$$load.done, () => v4())
          .reset(reset);

      bridge(() => {
        /**
         * Need this bcs of case
         * where backend returns same options as before,
         * even though `skip` is increased
         */

        const $prevOptions = snapshot({
          source: $options,
          clock: $$loader.$$loadMore.start,
        });

        const isNewLoadedOptionsChangedOriginalOptions = sample({
          clock: $$loader.$$loadMore.done,
          source: {
            current: $options,
            previous: $prevOptions,
          },
          fn: ({ current, previous }, { hasMore }) => {
            if (!hasMore) return false;

            return isArrayEqual(current, previous);
          },
        });

        $uniqueIDToRetriggerObserverThenAlreadyInViewAfterLoadMore.on(
          isNewLoadedOptionsChangedOriginalOptions,
          (prevId, shouldCreateNewId) => (shouldCreateNewId ? v4() : prevId)
        );
      });

      bridge(() => {
        sample({
          clock: $$observer.intersected,
          source: {
            search: $searchQuery,
            skip: $$loader.$$search.sync
              ? $optionsLength
              : $filteredOptionsLength,
          },

          filter: and(not($$loader.$$loadMore.$pending), $hasMore),

          target: $$loader.$$loadMore.start,
        });
      });

      return {
        $id: $uniqueIDToRetriggerObserverThenAlreadyInViewAfterLoadMore,
        $$observer: $$observer,
      };
    });

    bridge(() => {
      const change = split($searchQuery.updates, {
        empty: (query) => query === '',
        filled: (query) => query !== '',
      });

      /**
       * We shouldnt call any loader if search is sync
       * because we already have all options in store
       */
      if (!$$loader.$$search.sync) {
        sample({
          clock: throttle({
            source: change.filled,

            timeout: 100,
          }),

          fn: (query) => ({ query }),

          target: $$loader.$$load.search,
        });

        sample({
          clock: change.empty,
          target: $$loader.$$load.start,
        });
      }
    });

    const $state = combine(
      $filteredOptionsLength,
      $searchQuery,
      $$loader.$$load.$pending,
      (optionsLength, query, isLoading): OptionsState => {
        switch (true) {
          case isLoading:
            return 'loading';

          case optionsLength === 0 && !!query:
            return 'not-found';

          case optionsLength === 0 && !query:
            return 'empty';

          default:
            return 'options';
        }
      }
    );

    return {
      $state,

      $$options: {
        $raw: $options,
        $filtered: $filteredOptions,
      },

      reset:
        // Cause mapped stores
        $options.reinit ?? createEvent(),

      $$ui: {
        $options: $filteredOptions,
        $optionsLength: $filteredOptionsLength,
        $state,
        $$loadMore,
      },
      __: {},
    };
  }
);

function isArrayEqual(a: string[], b: string[]) {
  return a.length === b.length && a.every((item, index) => item === b[index]);
}

export type { OptionsState };
export { SelectOptionsFactory };
