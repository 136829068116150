import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';

import type { PublicInvoiceDetailsOutput } from '../../api';
import type { IProgressState } from './progress.types';

abstract class BaseProgressState {
  public state(invoice: PublicInvoiceDetailsOutput | null): IProgressState {
    if (!invoice) {
      return this.empty(invoice);
    }

    switch (invoice.status) {
      case InvoiceStatus.Created: {
        return this.created(invoice);
      }

      case InvoiceStatus.ConfirmationAwaiting: {
        return this.confirmationAwaiting(invoice);
      }

      case InvoiceStatus.LimitsOutOfRange: {
        return this.limitsOutOfRange(invoice);
      }

      case InvoiceStatus.PaymentAwaiting: {
        return this.paymentAwaiting(invoice);
      }

      case InvoiceStatus.Paid: {
        return this.paid(invoice);
      }

      case InvoiceStatus.PartiallyPaid: {
        return this.partiallyPaid(invoice);
      }

      case InvoiceStatus.Timeout: {
        return this.timeout(invoice);
      }

      case InvoiceStatus.Deactivated: {
        return this.deactivated(invoice);
      }

      case InvoiceStatus.Declined: {
        return this.declined(invoice);
      }

      case InvoiceStatus.Suspended: {
        return this.suspended(invoice);
      }

      case InvoiceStatus.Arrested: {
        return this.arrested(invoice);
      }

      case InvoiceStatus.CurrencyMismatch: {
        return this.currencyMismatch(invoice);
      }

      default: {
        return this.unknown(invoice);
      }
    }
  }

  public empty(_invoice: null): IProgressState {
    throw new Error('Not implemented');
  }

  public created(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public confirmationAwaiting(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    throw new Error('Not implemented');
  }

  public limitsOutOfRange(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    throw new Error('Not implemented');
  }

  public paymentAwaiting(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public paid(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public partiallyPaid(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public timeout(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public deactivated(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public declined(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public suspended(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public arrested(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }

  public currencyMismatch(
    _invoice: PublicInvoiceDetailsOutput
  ): IProgressState {
    throw new Error('Not implemented');
  }

  public unknown(_invoice: PublicInvoiceDetailsOutput): IProgressState {
    throw new Error('Not implemented');
  }
}

export { BaseProgressState };
