import type { ComponentPropsWithoutRef } from 'react';

import { FormControl } from '@kuna-pay/ui/components/form-control';
import type { FieldModel } from '@kuna-pay/form';
import { useField } from '@kuna-pay/form';

const SingleSelectFormControl = Object.assign(
  ({
    field,
    ...props
  }: Omit<
    ComponentPropsWithoutRef<typeof FormControl>,
    'focused' | 'disabled' | 'hasError' | 'hasValue' | 'helperText'
  > & {
    field:
      | FieldModel<string | null | undefined>
      | FieldModel<string | null>
      | FieldModel<string>;
  }) => {
    const { error, focused, value, disabled, shouldShowValidation } = useField(
      field as FieldModel<string | null>
    );

    return (
      <FormControl
        helperText={shouldShowValidation ? error : ''}
        focused={focused}
        hasValue={!!value}
        hasError={shouldShowValidation && !!error}
        disabled={disabled}
        {...props}
      />
    );
  },
  {
    Control: FormControl,
  }
);

export { SingleSelectFormControl };
