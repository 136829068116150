import * as RadixAvatar from '@radix-ui/react-avatar';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';

import type { TypographyProps } from '../typography';
import { Typography } from '../typography';
import { useAvatarLoading } from './avatar.model';
import type {
  AvatarFallbackProps,
  AvatarGroupProps,
  AvatarImageProps,
  AvatarProps,
  AvatarRootProps,
  AvatarSize,
} from './avatar.props';
import styles from './avatar.module.scss';

const Avatar = createCompoundComponent(
  ({ Root, Image, Fallback }) =>
    forwardRef<HTMLDivElement, AvatarProps>(
      (
        {
          children,
          src,
          variant = 'img',
          delayMs,
          onLoadingStatusChange,
          ...props
        },
        ref
      ) => {
        const { isLoaded, onLocalLoadingStatusChange } = useAvatarLoading(
          onLoadingStatusChange
        );

        return (
          <Root {...props} ref={ref} variant={variant} transparent={isLoaded}>
            <Image
              src={src ?? ''}
              alt=''
              size={props.size}
              variant={variant}
              onLoadingStatusChange={onLocalLoadingStatusChange}
            />

            <Fallback
              className={styles.avatarFallback}
              delayMs={delayMs}
              size={props.size}
            >
              {children}
            </Fallback>
          </Root>
        );
      }
    ),
  {
    Root: forwardRef<HTMLSpanElement, AvatarRootProps>(
      ({ className, variant, size, transparent, ...props }, ref) => (
        <RadixAvatar.Root
          ref={ref}
          className={clsx(styles.root, className)}
          data-size={size}
          data-variant={variant}
          data-transparent={!!transparent}
          {...props}
        />
      )
    ),

    Image: forwardRef<HTMLImageElement, AvatarImageProps>(
      ({ className, variant, size, ...props }, ref) => (
        <RadixAvatar.Image
          ref={ref}
          className={clsx(styles.image, className)}
          data-size={size}
          data-variant={variant}
          {...props}
        />
      )
    ),

    Fallback: forwardRef<HTMLSpanElement, AvatarFallbackProps>(
      ({ children, className, size, delayMs, ...props }, ref) => (
        <RadixAvatar.Fallback
          ref={ref}
          className={clsx(styles.fallback, className)}
          delayMs={delayMs}
          asChild
        >
          <Typography variant={sizeToFallbackTypography[size]} {...props}>
            {children}
          </Typography>
        </RadixAvatar.Fallback>
      )
    ),

    Group: forwardRef<HTMLDivElement, AvatarGroupProps>(
      ({ className, size, ...props }, ref) => (
        <div
          ref={ref}
          className={clsx(styles.group, className)}
          data-size={size}
          {...props}
        />
      )
    ),
  }
);

const sizeToFallbackTypography: Record<AvatarSize, TypographyProps['variant']> =
  {
    //TODO: Sync with design
    xs: 'small3',

    sm: 'small3',

    //TODO: Sync with design
    md: 'numbers2',

    lg: 'numbers2',

    xl: 'body3',

    //TODO: Sync with design
    xxl: 'body3',

    xxxl: 'body3',
  };

export { Avatar };
