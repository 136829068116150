class MerchantIndustryConfig {
  public static readonly highPriorityOptions = [
    'automotive',
    'education',
    'event-services',
    'financial-services',
    'gambling-casinos',
    'health-wellness-and-fitness',
    'it-services-and-it-consulting',
    'legal-services',
    'luxury-goods-and-jewelry',
    'marketing-and-advertising',
    'medical-equipment-manufacturing',
    'real-estate',
    'translation-and-localization',
    'transportation-logistics-supply-chain-and-storage',
    'wine-and-spirits',
  ];

  public static readonly defaultOptions = [
    'accounting',
    'alternative-dispute-resolution',
    'alternative-medicine',
    'animation',
    'apparel-and-fashion',
    'architecture-and-planning',
    'arts-and-crafts',
    'aviation-and-aerospace',
    'banking',
    'biotechnology',
    'broadcast-media',
    'broadcast-media-production-and-distribution',
    'building-materials',
    'business-supplies-and-equipment',
    'capital-markets',
    'chemicals',
    'civic-and-social-organization',
    'civil-engineering',
    'commercial-real-estate',
    'computer-and-network-security',
    'computer-games',
    'computer-hardware',
    'computer-networking',
    'construction',
    'consumer-electronics',
    'consumer-goods',
    'consumer-services',
    'cosmetics',
    'dairy',
    'defense-and-space',
    'design',
    'education-management',
    'e-learning',
    'electrical-electronic-manufacturing',
    'entertainment',
    'entertainment-providers',
    'environmental-services',
    'executive-office',
    'facilities-services',
    'farming',
    'fine-art',
    'fishery',
    'food-beverages',
    'food-production',
    'fund-raising',
    'furniture',
    'glass-ceramics-concrete',
    'government-administration',
    'government-relations',
    'graphic-design',
    'higher-education',
    'hospital-health-care',
    'hospitality',
    'human-resources',
    'import-and-export',
    'individual-family-services',
    'industrial-automation',
    'information-services',
    'insurance',
    'international-affairs',
    'international-trade-and-development',
    'internet',
    'investment-banking',
    'investment-management',
    'judiciary',
    'law-enforcement',
    'law-practice',
    'legislative-office',
    'leisure-travel-tourism',
    'libraries',
    'machinery',
    'management-consulting',
    'maritime',
    'market-research',
    'mechanical-or-industrial-engineering',
    'media-production',
    'medical-devices',
    'medical-practice',
    'mental-health-care',
    'military',
    'mining-metals',
    'motion-pictures-and-film',
    'motor-vehicle-manufacturing',
    'museums-and-institutions',
    'music',
    'nanotechnology',
    'newspapers',
    'nonprofit-organization-management',
    'oil-and-gas',
    'online-media',
    'package-freight-delivery',
    'packaging-and-containers',
    'paper-forest-products',
    'performing-arts',
    'personal-care-product-manufacturing',
    'pharmaceuticals',
    'philanthropy',
    'photography',
    'plastics',
    'political-organization',
    'primary-secondary-education',
    'printing',
    'professional-training-coaching',
    'program-development',
    'public-policy',
    'public-relations-and-communications',
    'public-safety',
    'publishing',
    'railroad-manufacture',
    'ranching',
    'recreational-facilities-services',
    'renewables-environment',
    'research',
    'restaurants',
    'retail',
    'retail-apparel-and-fashion',
    'retail-luxury-goods-jewelry',
    'security-and-investigations',
    'semiconductors',
    'shipbuilding',
    'sporting-goods',
    'sports',
    'staffing-and-recruiting',
    'supermarkets',
    'telecommunications',
    'textiles',
    'think-tanks',
    'tobacco',
    'transportation-trucking-railroad',
    'utilities',
    'venture-capital-private-equity',
    'veterinary',
    'warehousing',
    'wholesale',
    'wireless',
    'writing-and-editing',
  ];

  public static readonly lowPriorityOptions = ['other'];

  public static readonly NONE_OPTION = 'none';

  public static readonly options = [
    ...MerchantIndustryConfig.highPriorityOptions,
    ...MerchantIndustryConfig.defaultOptions,
    ...MerchantIndustryConfig.lowPriorityOptions,
  ];
}

export { MerchantIndustryConfig };
