import clsx from 'clsx';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { InvoiceStatusText } from '@kuna-pay/core/entities/invoice/ui/status/text.ui';

import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';
import { LogEventOnMount } from '@kuna-pay/accept-payment/shared/analytics';

import errorImg from '../../../assets/error.png';
import { InvoiceDetails } from '../../shared';
import styles from './deactivated.module.scss';

type OrderDetailsDeactivatedProps = PropsWithChildren & { className?: string };

const OrderDetailsDeactivated: FC<OrderDetailsDeactivatedProps> = ({
  className,
  children,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.deactivated',
  });

  return (
    <Paper className={clsx(className, styles.root)}>
      <div className={styles.imgContainer}>
        <img src={errorImg} width={112} height={112} alt='' />
      </div>

      <Typography className={styles.title} variant='h7'>
        <InvoiceStatusText status={InvoiceStatus.Deactivated} />
      </Typography>

      <Typography className={styles.subtitle} variant='body3'>
        {t('description')}
      </Typography>

      <InvoiceDetails.Root className={styles.invoiceDetails}>
        <LogEventOnMount
          key={InvoiceStatus.Deactivated}
          event='Payment Details Viewed'
          properties={{ status: InvoiceStatus.Deactivated }}
        />

        <InvoiceDetails.InvoiceAmount />

        <InvoiceDetails.InvoiceNumber />
      </InvoiceDetails.Root>

      {children}
    </Paper>
  );
};

export { OrderDetailsDeactivated };
