import { createEffect } from 'effector';

import { preRequestPublicInvoice } from '@kuna-pay/accept-payment/shared/api/generated/Invoice/request/preRequestPublicInvoice';

const changeCurrentPaymentMethodFx = createEffect(
  preRequestPublicInvoice({
    id: true,
    amount: true,
    fee: true,
    processedAmount: true,
    rate: true,
    rateFreezeAt: true,
    issues: true,
    isCanBePaid: true,
  })
);

export { changeCurrentPaymentMethodFx };
