import { ControlInput } from './control.input.component';
import { ControlTrigger } from './control.trigger.component';

const Control = {
  Input: ControlInput,

  Trigger: ControlTrigger,
};

export { Control };
