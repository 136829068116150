import { useStoreMap, useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { useContext } from 'react';

import { modelView } from '@kuna-pay/utils/effector';
import type { ChildrenProps } from '@kuna-pay/ui/components/select/lib';

import type { OptionsState as OptionsStateType } from '../../model';
import { SelectOptionsFactory } from '../../model';
import { OptionsConfigContext } from './context';

const OptionState = Object.assign(
  ({ children }: ChildrenProps<OptionsStateType>) => {
    const $$options = SelectOptionsFactory.useModel();

    const state = useUnit($$options.$state);

    return <>{typeof children === 'function' ? children(state) : children}</>;
  },
  {
    Provider: modelView(
      SelectOptionsFactory,
      ({ children }: PropsWithChildren) => <>{children}</>
    ),

    Empty: createOptionsStateComponent('empty'),

    Searching: createOptionsStateComponent('searching'),

    Loading: ({
      children,
      ...props
    }: PropsWithChildren & { includeSearching?: boolean }) => {
      const $$options = SelectOptionsFactory.useModel();

      const includeSearchingFromContext = useContext(OptionsConfigContext);

      const includeSearching =
        typeof props.includeSearching === 'boolean'
          ? props.includeSearching
          : includeSearchingFromContext;

      const isMatchedState = useStoreMap(
        $$options.$state,
        (state) => state === 'loading'
      );

      const isMatchedSearchingState = useStoreMap(
        $$options.$state,
        (state) => state === 'searching'
      );

      if (includeSearching) {
        if (isMatchedState || isMatchedSearchingState) return <>{children}</>;

        return null;
      }

      if (!isMatchedState) return null;

      return <>{children}</>;
    },

    NotFound: createOptionsStateComponent('not-found'),

    Options: createOptionsStateComponent('options'),
  }
);

function createOptionsStateComponent(shouldMatchState: OptionsStateType) {
  return ({ children }: PropsWithChildren) => {
    const $$options = SelectOptionsFactory.useModel();

    const isMatchedState = useStoreMap(
      $$options.$state,
      (state) => state === shouldMatchState
    );

    if (!isMatchedState) return null;

    return <>{children}</>;
  };
}

export { OptionState };
