import {
  createEffect,
  createEvent,
  createStore,
  sample,
  scopeBind,
} from 'effector';

import { UNIT_SHAPE } from '@kuna-pay/utils/effector';
import { bridge } from '@kuna-pay/utils/misc';

function createIntersectionObserver() {
  const mounted = createEvent<HTMLElement>();
  const unmounted = createEvent();
  const intersected = createEvent<IntersectionObserverEntry>();

  const $ref = createStore<HTMLElement | null>(null);
  const $observer = createStore<IntersectionObserver | null>(null);

  const setupFx = createEffect<HTMLElement, IntersectionObserver>();
  const destroyFx = createEffect<IntersectionObserver, void>();

  bridge(() => {
    $ref.on(mounted, (_, ref) => ref);

    sample({
      clock: $ref,
      filter: Boolean,
      target: setupFx,
    });

    $observer.on(setupFx.doneData, (_, observer) => observer);

    setupFx.use((ref) => {
      const boundIntersected = scopeBind(intersected, { safe: true });

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) return;

          boundIntersected(entry);
        });
      });

      observer.observe(ref);

      return observer;
    });
  });

  bridge(() => {
    sample({
      clock: unmounted,
      source: $observer,
      filter: Boolean,
      target: destroyFx,
    });

    destroyFx.use((observer) => {
      observer.disconnect();
    });

    $ref.reset(unmounted);
    $observer.reset(destroyFx.finally);
  });

  return {
    intersected,
    $$ui: { mounted, unmounted },
    [UNIT_SHAPE]: () => ({ mounted, unmounted }),
  };
}

export { createIntersectionObserver };
