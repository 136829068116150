import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDateFormat } from '@kuna-pay/utils/date';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';
import {
  TransactionStatusTag,
  TransactionType,
} from '@kuna-pay/core/entities/transaction';
import { TransferType } from '@kuna-pay/core/generated/public/graphql';

import {
  BuyerArrestedInvoiceDepositNotice,
  BuyerCurrencyMismatchInvoiceDepositNotice,
  BuyerSuspendedInvoiceDepositNotice,
  InvoiceDepositRiskScoreTag,
} from '@kuna-pay/accept-payment/entities/transaction';
import { TransferStatus } from '@kuna-pay/accept-payment/generated/graphql';

import { BuyerInvoiceDepositCardPresenter } from './card.presenter';
import type { BuyerInvoiceDepositCardProps } from './card.types';
import styles from './card.module.scss';

const presenter = new BuyerInvoiceDepositCardPresenter();

const BuyerInvoiceDepositCard = memo(
  ({ transaction, invoice, company }: BuyerInvoiceDepositCardProps) => {
    const { t } = useTranslation('common', {
      keyPrefix: 'entities.transaction.invoice-deposit.card',
    });
    const DateFormat = useDateFormat();
    const AssetFormat = useAssetFormat();

    return (
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.type}>
            <TransactionType.Icon type={TransferType.InvoiceDeposit} />

            <TransactionType.Root variant='subtitle5'>
              {t('type')}
            </TransactionType.Root>

            {presenter.canShowRiskScore(transaction) && (
              <>
                {presenter.isRiskScoreExists(transaction) && (
                  <InvoiceDepositRiskScoreTag
                    riskScore={transaction.AmlDetails.riskScore}
                  />
                )}
              </>
            )}
          </div>

          <TransactionStatusTag status={transaction.status} size='md' />
        </div>

        <div className={styles.footer}>
          <Typography variant='subtitle3'>
            {transaction.processedAmount ? (
              <>
                {AssetFormat.formatAmount(
                  transaction.processedAmount,
                  transaction.Asset
                )}
              </>
            ) : (
              <>-</>
            )}
          </Typography>

          <Typography className='text-black600' variant='body3'>
            {DateFormat.formatDateTime(transaction.createdAt)}
          </Typography>
        </div>

        {transaction.status === TransferStatus.Suspended && (
          <BuyerSuspendedInvoiceDepositNotice
            className={styles.notice}
            companyTitle={company.title}
          />
        )}

        {transaction.status === TransferStatus.Arrested && (
          <BuyerArrestedInvoiceDepositNotice className={styles.notice} />
        )}

        {presenter.isCurrencyMismatch(transaction, invoice) && (
          <BuyerCurrencyMismatchInvoiceDepositNotice
            className={styles.notice}
          />
        )}
      </div>
    );
  }
);

export { BuyerInvoiceDepositCard };
