import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '@kuna-pay/ui/router';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';

import { CurrencyMismatchNoticeIcon } from '../../../assets';
import type { NoticeProps } from '../base';
import { Notice } from '../base';
import styles from './currency-mismatch.module.scss';

type CurrencyMismatchNoticeProps = Omit<NoticeProps, 'icon' | 't'> &
  Partial<Pick<NoticeProps, 't'>> & {
    namespace: string;
    keyPrefix: string;
  };

const CurrencyMismatchNotice = memo(
  ({
    className,
    namespace,
    keyPrefix,
    ...props
  }: CurrencyMismatchNoticeProps) => {
    const { t } = useTranslation(namespace, {
      keyPrefix,
    });

    return (
      <Notice
        className={clsx(styles.root, className)}
        t={t}
        components={{
          'kuna-io': (
            <ExternalLink
              className={Typography.classes.numbers1}
              href={urls.services.exchange}
              primary
            />
          ),
        }}
        icon={<CurrencyMismatchNoticeIcon />}
        {...props}
      />
    );
  }
);

export type { CurrencyMismatchNoticeProps };
export { CurrencyMismatchNotice };
