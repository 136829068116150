import type { PropsWithChildren } from 'react';
import type { ComponentPropsWithoutRef } from 'react';

import { modelView } from '@kuna-pay/utils/effector';

import { SingleSelectModel } from './model';
import {
  SelectContent,
  SelectNotFound,
  SelectRoot,
  SelectSearch,
  SelectTrigger,
  SingleSelectFormControl,
  SingleSelectOptions,
  SingleSelectValue,
} from './ui';

const Provider = modelView(
  SingleSelectModel,
  ({ children }: PropsWithChildren) => {
    const { $$root, $$search, $$options } = SingleSelectModel.useModel();

    return (
      <SelectRoot.Provider $$model={$$root}>
        <SelectSearch.Provider $$model={$$search}>
          <SingleSelectOptions.Provider $$model={$$options}>
            {children}
          </SingleSelectOptions.Provider>
        </SelectSearch.Provider>
      </SelectRoot.Provider>
    );
  }
);

const SingleSelect = Object.assign(
  ({
    $$model,
    ...props
  }: PropsWithChildren &
    ComponentPropsWithoutRef<typeof SelectRoot> &
    ComponentPropsWithoutRef<typeof Provider>) => (
    <Provider $$model={$$model}>
      <SelectRoot {...props} />
    </Provider>
  ),

  {
    /**
     * Factory
     */
    factory: SingleSelectModel,

    /**
     * Provider
     */
    Provider,

    /**
     * Core
     */
    Root: SelectRoot,

    Content: Object.assign(
      (props: ComponentPropsWithoutRef<typeof SelectContent>) => (
        <SelectContent {...props} />
      ),
      {
        Empty: Object.assign(
          ({
            title = 'Options list is empty',
            ...props
          }: Partial<ComponentPropsWithoutRef<typeof SelectNotFound>>) => (
            <SelectNotFound title={title} {...props} />
          ),
          SelectNotFound
        ),

        Loading: Object.assign(
          ({
            title = 'Loading...',
            ...props
          }: Partial<ComponentPropsWithoutRef<typeof SelectNotFound>>) => (
            <SelectNotFound title={title} {...props} />
          ),
          SelectNotFound
        ),

        NotFound: Object.assign(
          ({
            title = 'No search results found',
            subtitle = 'Please try again with a different search query',
          }: Partial<ComponentPropsWithoutRef<typeof SelectNotFound>>) => (
            <SelectNotFound title={title} subtitle={subtitle} />
          ),
          SelectNotFound
        ),
      }
    ),

    Control: {
      Root: SingleSelectFormControl,
      Trigger: SelectTrigger,
    },
    Search: SelectSearch,

    /**
     * Features
     */
    Value: SingleSelectValue,
    Options: SingleSelectOptions,
  }
);

export { SingleSelect };
