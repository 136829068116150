import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, FC, HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './form-control.core.module.scss';

/**
 * ==============================
 *
 *       Type definitions
 *
 * ==============================
 */

// 48, 40, 36, 32, 26
type FormControlSize = 'xs' | 'sm' | 'md' | 'lg';
type FormControlState = 'idle' | 'focus' | 'error' | 'disabled';

/**
 * ==============================
 *
 *        FormControlText
 *
 * ==============================
 */

const variants: Record<FormControlSize, TypographyProps['variant']> = {
  xs: 'small2',
  sm: 'small2',
  md: 'small2',
  lg: 'numbers1',
};

type FormControlTextProps = Omit<TypographyProps, 'variant'> & {
  size: FormControlSize;
};

const FormControlText: FC<FormControlTextProps> = ({
  className,
  size,
  ...props
}) => (
  <Typography
    className={clsx(styles.text, className)}
    variant={variants[size]}
    data-size={size}
    {...props}
  />
);

const FormControlLabel: FC<
  ComponentPropsWithoutRef<typeof FormControlText> & {
    focused?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    error?: boolean;

    variant?: 'filled' | 'outlined';
  }
> = ({
  className,
  error,
  size,
  hidden,
  focused,
  disabled,
  variant,
  ...props
}) => (
  <FormControlText
    className={clsx(
      styles.label,
      {
        [styles.error]: error,
        [styles.focused]: focused,
        [styles.disabled]: disabled,
        [styles.hidden]: hidden,
      },
      {
        [styles.filled]: variant === 'filled',
        [styles.outlined]: variant === 'outlined',
      },
      className
    )}
    size={size}
    {...props}
  />
);

type FormControlHelderTextProps = ComponentPropsWithoutRef<
  typeof FormControlText
> & {
  hasError?: boolean;
};

const FormControlHelderText: FC<FormControlHelderTextProps> = ({
  className,
  hasError,
  ...props
}) => (
  <FormControlText
    className={clsx(styles.helperText, { [styles.error]: hasError }, className)}
    {...props}
  />
);

/**
 * ==============================
 *
 *            Layout
 *
 * ==============================
 */
type FormControlLayoutProps = HTMLAttributes<HTMLDivElement> & {
  size: FormControlSize;

  asChild?: boolean;
};

const sizeVariants: Record<FormControlSize, string> = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
};

const FormControlRoot = forwardRef<HTMLDivElement, FormControlLayoutProps>(
  ({ className, size, asChild, ...props }, ref) => {
    const Component = asChild ? Slot : 'div';

    return (
      <Component
        className={clsx(styles.root, className, sizeVariants[size])}
        ref={ref}
        {...props}
      />
    );
  }
);

const FormControlHeader = forwardRef<HTMLDivElement, FormControlLayoutProps>(
  ({ className, size, asChild, ...props }, ref) => {
    const Component = asChild ? Slot : 'div';

    return (
      <Component
        className={clsx(styles.header, className, sizeVariants[size])}
        ref={ref}
        {...props}
      />
    );
  }
);

const FormControlFooter = forwardRef<HTMLDivElement, FormControlLayoutProps>(
  ({ className, size, asChild, ...props }, ref) => {
    const Component = asChild ? Slot : 'div';

    return (
      <Component
        className={clsx(styles.footer, className, sizeVariants[size])}
        ref={ref}
        {...props}
      />
    );
  }
);

/**
 * ==============================
 *
 *            Exports
 *
 * ==============================
 */
export {
  FormControlFooter,
  FormControlHeader,
  FormControlHelderText,
  FormControlLabel,
  FormControlRoot,
  FormControlText,
};
export type { FormControlSize, FormControlState };
