import clsx from 'clsx';
import type { TFunction } from 'i18next';
import type { HTMLAttributes, ReactNode } from 'react';
import { cloneElement, isValidElement, memo } from 'react';
import { Trans } from 'react-i18next';

import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './notice.module.scss';

type NoticeProps = HTMLAttributes<HTMLDivElement> & {
  icon?: ReactNode;

  t: TFunction;

  tOptions?: Record<string, unknown>;
  components?: Record<string, ReactNode>;
};

const Notice = memo(
  ({
    t,
    children,
    icon,
    className,
    tOptions,
    components = {},
    ...props
  }: NoticeProps) => (
    <div className={clsx(styles.root, className)} {...props}>
      {isValidElement<{ className?: string }>(icon)
        ? cloneElement(icon, { className: styles.icon })
        : null}

      <div className={styles.content}>
        <Typography variant='subtitle6'>{t('title')}</Typography>

        <Typography className={styles.description} variant='numbers2'>
          <Trans
            t={t}
            i18nKey='description'
            tOptions={tOptions}
            components={{
              medium: <Typography variant='numbers1' />,

              ...components,
            }}
          />
        </Typography>

        {children}
      </div>
    </div>
  )
);

export { Notice };
export type { NoticeProps };
