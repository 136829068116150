import type * as RadixAvatar from '@radix-ui/react-avatar';
import { useState } from 'react';

const useAvatarLoading = (
  onChange: RadixAvatar.AvatarImageProps['onLoadingStatusChange']
) => {
  const [isLoaded, setLoaded] = useState(false);

  const onLocalLoadingStatusChange: RadixAvatar.AvatarImageProps['onLoadingStatusChange'] =
    (status) => {
      onChange?.(status);

      switch (status) {
        case 'loading': {
          setLoaded(false);

          return;
        }

        case 'loaded': {
          setLoaded(true);

          return;
        }
      }
    };

  return { isLoaded, onLocalLoadingStatusChange };
};

export { useAvatarLoading };
