import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

import { CookieConsent } from '@kuna-pay/core/shared/cookie-consent';

import { ChangeLanguage } from '@kuna-pay/accept-payment/features/change-language';
import { RateYourExperienceStack } from '@kuna-pay/accept-payment/shared/feedback';
import { Footer } from '@kuna-pay/accept-payment/shared/ui/footer';

import { $$cookieConsent } from './cookie-consent.model';
import styles from './main-layout.module.scss';

type MainLayoutProps = PropsWithChildren & {
  classes?: {
    root?: string;
    content?: string;
    footer?: string;
  };
};

const MainLayout = ({ children, classes }: MainLayoutProps) => (
  <>
    <div className={clsx(styles.root, classes?.root)}>
      <div className={clsx(styles.content, classes?.content)}>{children}</div>

      <Footer className={classes?.footer}>
        <ChangeLanguage />
      </Footer>
    </div>

    <CookieConsent $$model={$$cookieConsent.$$ui.$$consent} />
    <RateYourExperienceStack />
  </>
);

export { MainLayout };
