import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';
import { InvoiceStatusText } from '@kuna-pay/core/entities/invoice/ui/status/text.ui';
import { InvoiceStatus } from '@kuna-pay/core/generated/public/graphql';

import { Warning } from '@kuna-pay/accept-payment/shared/ui/warning';

import warningImg from '../../../assets/warning.png';
import { CheckoutPageModel } from '../../../page.model';
import { SendReceipt } from '../../send-receipt';
import {
  InvoiceAmounts,
  InvoiceDetails,
  InvoiceTabs,
  SupportInfo,
} from '../../shared';
import styles from './partially-paid.module.scss';

const OrderDetailsPartiallyPaid: FC<
  PropsWithChildren & { className?: string }
> = ({ className, children }) => {
  const $$model = CheckoutPageModel.useModel();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.partially-paid',
  });
  const AssetFormat = useAssetFormat();
  const invoice = useUnit($$model.$invoice)!;
  const sendReceipt = useUnit($$model.$$orderDetails.$$sendReceipt);
  const { paidAmount, PaymentAsset, Company, isPaymentAfterTimeout } = invoice!;

  return (
    <Paper
      className={clsx(className, styles.root, {
        [styles.grow]: !sendReceipt.isOpen,
      })}
    >
      {!sendReceipt.isOpen ? (
        <InvoiceTabs noTopPadding>
          <div className={styles.container}>
            <div className={styles.imgContainer}>
              <img src={warningImg} width={126} height={126} alt='' />
            </div>

            <Typography className={styles.title} variant='h7'>
              <InvoiceStatusText
                status={InvoiceStatus.PartiallyPaid}
                isAfterTimeout={isPaymentAfterTimeout}
              />
            </Typography>

            <div className={styles.amountContainter}>
              <Typography className='text-black500' variant='body3'>
                {t('paid-amount')}
              </Typography>

              <Typography variant='h6'>
                {AssetFormat.formatAmount(paidAmount, PaymentAsset!)}
              </Typography>
            </div>

            <Warning
              className={styles.divider}
              title={t('warning.title', {
                replace: { company: Company.title },
              })}
            >
              {t('warning.description')}
            </Warning>

            <InvoiceAmounts.Root>
              <InvoiceAmounts.AmountLeft />

              <InvoiceAmounts.ReceivedAmount />

              <InvoiceAmounts.PaymentAmount />
            </InvoiceAmounts.Root>

            <InvoiceDetails className={styles.invoiceDetails} />

            <SupportInfo />

            <SendReceipt.Button
              className={styles.sendReceiptButton}
              $$model={$$model.$$orderDetails.$$sendReceipt}
            />

            {children}
          </div>
        </InvoiceTabs>
      ) : (
        <>
          <SendReceipt $$model={$$model.$$orderDetails.$$sendReceipt} />

          {children}
        </>
      )}
    </Paper>
  );
};

export { OrderDetailsPartiallyPaid };
