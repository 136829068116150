import { AmplitudeAnalyticsFactory } from '@kuna-pay/core/shared/analytics/amplitude';

import { environment } from '@kuna-pay/accept-payment/shared/config';

import type { CheckoutEvents } from './analytics.types';

const $$analytics = AmplitudeAnalyticsFactory.createModel<CheckoutEvents>({
  API_KEY: environment.amplitude.apiKey,

  initOptions: {
    /**
     * @see https://help.amplitude.com/hc/en-us/articles/360017800371-Releases-See-how-users-respond-to-changes-in-your-product
     */
    appVersion: environment.amplitude.release ?? 'Development',

    defaultTracking: {
      pageViews: false,
    },

    trackingOptions: {
      language: false,
    },
  },
});

export { $$analytics };
