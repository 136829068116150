import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';

import { modelView } from '@kuna-pay/utils/effector';

import { MultiSelectModel } from './model';
import {
  MultiSelectFormControl,
  MultiSelectOptions,
  MultiSelectValue,
  SelectContent,
  SelectNotFound,
  SelectRoot,
  SelectSearch,
  SelectTrigger,
} from './ui';

const Provider = modelView(
  MultiSelectModel,
  ({ children }: PropsWithChildren) => {
    const { $$root, $$search, $$options } = MultiSelectModel.useModel();

    return (
      <SelectRoot.Provider $$model={$$root}>
        <SelectSearch.Provider $$model={$$search}>
          <MultiSelectOptions.Provider $$model={$$options}>
            {children}
          </MultiSelectOptions.Provider>
        </SelectSearch.Provider>
      </SelectRoot.Provider>
    );
  }
);

const MultiSelect = Object.assign(
  ({
    $$model,
    ...props
  }: PropsWithChildren &
    ComponentPropsWithoutRef<typeof SelectRoot> &
    ComponentPropsWithoutRef<typeof Provider>) => (
    <Provider $$model={$$model}>
      <SelectRoot {...props} />
    </Provider>
  ),
  {
    /**
     * Factory
     */
    factory: MultiSelectModel,

    /**
     * Provider
     */
    Provider,

    /**
     * Core
     */
    Root: SelectRoot,
    Content: Object.assign(
      (props: ComponentPropsWithoutRef<typeof SelectContent>) => (
        <SelectContent {...props} />
      ),
      {
        Empty: Object.assign(
          ({
            title = 'Options list is empty',
            ...props
          }: Partial<ComponentPropsWithoutRef<typeof SelectNotFound>>) => (
            <SelectNotFound title={title} {...props} />
          ),
          SelectNotFound
        ),

        Loading: Object.assign(
          ({
            title = 'Loading...',
            ...props
          }: Partial<ComponentPropsWithoutRef<typeof SelectNotFound>>) => (
            <SelectNotFound title={title} {...props} />
          ),
          SelectNotFound
        ),

        NotFound: Object.assign(
          ({
            title = 'No search results found',
            subtitle = 'Please try again with a different search query',
          }: Partial<ComponentPropsWithoutRef<typeof SelectNotFound>>) => (
            <SelectNotFound title={title} subtitle={subtitle} />
          ),
          SelectNotFound
        ),
      }
    ),

    Control: {
      Root: MultiSelectFormControl,
      Trigger: SelectTrigger,
    },
    Search: SelectSearch,

    /**
     * Features
     */
    Value: MultiSelectValue,
    Options: MultiSelectOptions,
  }
);

export { MultiSelect };
