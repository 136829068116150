import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@kuna-pay/ui/ui/divider';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';
import { MerchantAvatar } from '@kuna-pay/core/entities/merchant';

import { CheckoutPageModel } from '../../page.model';
import { useCollectBuyerInfoHeaderColumnWrapped } from './collect-buyer-info-header.hook';
import styles from './collect-buyer-info-header.module.scss';

const CollectBuyerInfoHeader = memo(() => {
  const $$model = CheckoutPageModel.useModel();
  const AssetFormat = useAssetFormat();

  const {
    Company: { avatar, title },
    invoiceAmount,
    InvoiceAsset,
  } = useUnit($$model.$invoice)!;

  const { t } = useTranslation();

  const { isRowWrapped, amountColumnRef, companyColumnRef, containerRef } =
    useCollectBuyerInfoHeaderColumnWrapped();

  return (
    <>
      <div className={styles.header}>
        <MerchantAvatar className={styles.avatar} src={avatar} size='xl' />

        <div ref={containerRef} className={styles.headerCompany}>
          <div className={styles.headerCompanyText} ref={companyColumnRef}>
            <Typography variant='numbers2'>
              {t('pages.checkout.collect-buyer-info.invoice.company.label')}
            </Typography>

            <Typography
              className={clsx(styles.headerCompanyTitleText, {
                [styles.amountWrapped]: isRowWrapped,
              })}
              variant='subtitle5'
              title={title}
              nowrap
            >
              {title}
            </Typography>
          </div>

          <div ref={amountColumnRef} className={styles.headerAmountText}>
            <Typography
              className={clsx(styles.headerAmountTextTitle, {
                [styles.headerAmountTextTitleWrapped]: isRowWrapped,
              })}
              variant='numbers2'
            >
              {t('pages.checkout.collect-buyer-info.invoice.amount.label')}
            </Typography>

            <Typography variant='subtitle5' wordBreak nowrap>
              {AssetFormat.formatAmount(invoiceAmount, InvoiceAsset)}
            </Typography>
          </div>
        </div>
      </div>

      <Divider className={styles.headerDivider} />
    </>
  );
});

export { CollectBuyerInfoHeader };
