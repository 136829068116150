import { useStoreMap } from 'effector-react';
import type { PropsWithChildren } from 'react';

import type { ChildrenProps } from '@kuna-pay/ui/components/select/lib';

import { MultiSelectModel } from '../../model';
import { MultiValue } from './value.multi';

const MultiValueState = {
  Empty: ({ children }: PropsWithChildren) => {
    const { $$value } = MultiSelectModel.useModel();
    const isEmpty = useStoreMap($$value.$state, (state) => state === 'empty');

    if (!isEmpty) return null;

    return <>{children}</>;
  },

  Filled: ({ children }: ChildrenProps<string[]>) => {
    const { $$value } = MultiSelectModel.useModel();
    const isFilled = useStoreMap($$value.$state, (state) => state === 'filled');

    if (!isFilled) return null;

    if (typeof children === 'function')
      return <MultiValue>{children}</MultiValue>;

    return <>{children}</>;
  },
};

export { MultiValueState };
