import { isTransactionCurrencyMismatchOfInvoice } from '@kuna-pay/core/entities/transaction';
import { TransferType } from '@kuna-pay/core/generated/public/graphql';

import { TransferStatus } from '@kuna-pay/accept-payment/generated/graphql';

import type { BuyerInvoiceDepositCardProps } from './card.types';

class BuyerInvoiceDepositCardPresenter {
  public canShowRiskScore = (
    transaction: BuyerInvoiceDepositCardProps['transaction']
  ) =>
    transaction.status === TransferStatus.Suspended ||
    transaction.status === TransferStatus.Arrested;

  public isRiskScoreExists = (
    transaction: BuyerInvoiceDepositCardProps['transaction']
  ): transaction is BuyerInvoiceDepositCardProps['transaction'] & {
    AmlDetails: { riskScore: string };
  } => !!transaction.AmlDetails?.riskScore;

  public isCurrencyMismatch = (
    transaction: BuyerInvoiceDepositCardProps['transaction'],
    invoice: BuyerInvoiceDepositCardProps['invoice']
  ) =>
    isTransactionCurrencyMismatchOfInvoice(
      { asset: transaction.Asset.code, type: TransferType.InvoiceDeposit },
      invoice
    );
}

export { BuyerInvoiceDepositCardPresenter };
