import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { HorizontalStepper } from '@kuna-pay/accept-payment/shared/ui/horizontal-stepper';

import { CheckoutPageModel } from '../../../page.model';
import styles from './checkout-progress-tracker.module.scss';

const CheckoutProgressTracker = memo(
  ({ className }: { className?: string }) => {
    const { $$progress } = CheckoutPageModel.useModel();
    const { t } = useTranslation(undefined, {
      keyPrefix: 'pages.checkout.shared.checkout-progress-tracker',
    });
    const state = useUnit($$progress.$state);

    return (
      <HorizontalStepper.Root
        className={clsx(styles.root, className, {
          [styles.withoutDetails]: !state.details,
        })}
      >
        {state.details && (
          <HorizontalStepper.Step.Root state={state.details} align='start'>
            <HorizontalStepper.Step.Connector nextStepAlign='center' />

            <HorizontalStepper.Step.Icon />

            <HorizontalStepper.Step.Label>
              {t('details')}
            </HorizontalStepper.Step.Label>
          </HorizontalStepper.Step.Root>
        )}

        <HorizontalStepper.Step.Root
          state={state.init}
          align={state.details ? 'center' : 'start'}
        >
          <HorizontalStepper.Step.Connector nextStepAlign='center' />

          <HorizontalStepper.Step.Icon />

          <HorizontalStepper.Step.Label>
            {t('init')}
          </HorizontalStepper.Step.Label>
        </HorizontalStepper.Step.Root>

        <HorizontalStepper.Step.Root state={state.payment} align='center'>
          <HorizontalStepper.Step.Connector
            state={state.payment === 'pending' ? 'active' : state.payment}
            nextStepAlign='end'
          />

          <HorizontalStepper.Step.Icon />

          <HorizontalStepper.Step.Label>
            {t('payment')}
          </HorizontalStepper.Step.Label>
        </HorizontalStepper.Step.Root>

        <HorizontalStepper.Step.Root state={state.done} align='end'>
          <HorizontalStepper.Step.Icon />

          <HorizontalStepper.Step.Label>
            {t('done')}
          </HorizontalStepper.Step.Label>
        </HorizontalStepper.Step.Root>
      </HorizontalStepper.Root>
    );
  }
);

export { CheckoutProgressTracker };
