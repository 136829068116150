import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import { memo } from 'react';

import { WarningIcon } from '@kuna-pay/ui/icons';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './warning.module.scss';

type WarningProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  title: string;

  classes?: {
    content?: string;
  };
};

const Warning = memo(
  ({ title, children, className, classes, ...props }: WarningProps) => (
    <div className={clsx(styles.root, className)} {...props}>
      <div className={styles.iconContainer}>
        <WarningIcon className='text-yellow400' />
      </div>

      <div className={clsx(styles.content, classes?.content)}>
        <Typography variant='subtitle6'>{title}</Typography>

        {children && (
          <Typography className={styles.description} variant='numbers2'>
            {children}
          </Typography>
        )}
      </div>
    </div>
  )
);

export { Warning };
export type { WarningProps };
