import type { PropsWithChildren } from 'react';

import { modelView } from '@kuna-pay/utils/effector';

import { SelectOptionsFactory } from '../../model';
import { OptionsConfigContext } from './context';
import { SelectOptionsList } from './list';
import { MultiSelectCheckboxOption } from './option.multi';
import { OptionState } from './state';

const MultiSelectOptions = {
  Provider: modelView(
    SelectOptionsFactory,
    ({ children }: PropsWithChildren) => <>{children}</>
  ),

  State: OptionState,

  List: SelectOptionsList,

  ConfigProvider: OptionsConfigContext.Provider,

  Option: {
    Checkbox: MultiSelectCheckboxOption,
  },
};

export { MultiSelectOptions };
