import clsx from 'clsx';
import type { PropsWithChildren, ReactNode } from 'react';
import { forwardRef } from 'react';

import { TagCloseIcon } from '../../icons';
import type { TypographyProps } from '../typography';
import { Typography } from '../typography';
import clearStyles from './tag-clear.module.scss';
import rootStyles from './tag-root.module.scss';
import textStyles from './tag-text.module.scss';

type TagVariant = 'info' | 'common' | 'warning' | 'danger' | 'success';
type TagSize = 'sm' | 'md' | 'lg';

type TagProps = PropsWithChildren & {
  variant: TagVariant;
  start?: ReactNode;
  onClear?: () => void;
  typography?: Partial<TypographyProps>;

  size?: TagSize;

  className?: string;
  classes?: { root?: string; text?: string; clear?: string };
};

const typographyBySizes: Record<TagSize, TypographyProps['variant']> = {
  sm: 'small2',
  md: 'numberAdmin2',
  lg: 'numbers1',
};

const Tag = forwardRef<HTMLDivElement, TagProps>(
  (
    {
      children,
      className,
      classes,
      size = 'sm',
      variant = 'info',
      start,
      typography = {},
      onClear,
      ...props
    },
    ref
  ) => (
    <div
      ref={ref}
      className={clsx(
        rootStyles.root,
        className,
        classes?.root,
        rootStyles[variant],
        {
          [rootStyles.rootSm]: size === 'sm',
          [rootStyles.rootMd]: size === 'md',
          [rootStyles.rootLg]: size === 'lg',
          [rootStyles.hasStart]: !!start,
        }
      )}
      {...props}
    >
      {start}
      <Typography
        className={clsx(textStyles.text, classes?.text, textStyles[variant], {
          [textStyles.hasStart]: !!start,
          [textStyles.hasClear]: !!onClear,
        })}
        variant={typographyBySizes[size]}
        nowrap
        {...typography}
      >
        {children}
      </Typography>

      {!!onClear && (
        <TagCloseIcon
          className={clsx(
            clearStyles.clear,
            classes?.clear,
            clearStyles[variant]
          )}
          onClick={onClear}
        />
      )}
    </div>
  )
);

export { Tag };
export type { TagProps, TagVariant };
