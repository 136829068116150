import type { ComponentPropsWithoutRef } from 'react';

import { FormControl } from '@kuna-pay/ui/components/form-control';
import type { FieldModel } from '@kuna-pay/form';
import { useField } from '@kuna-pay/form';

const MultiSelectFormControl = ({
  field,
  ...props
}: Omit<
  ComponentPropsWithoutRef<typeof FormControl>,
  'focused' | 'disabled' | 'hasError' | 'hasValue' | 'helperText'
> & {
  field: FieldModel<string[]>;
}) => {
  const { error, focused, value, shouldShowValidation, disabled } =
    useField(field);

  return (
    <FormControl
      helperText={shouldShowValidation ? error : ''}
      focused={focused}
      hasValue={!!value && value.length > 0}
      hasError={shouldShowValidation && !!error}
      disabled={disabled}
      {...props}
    />
  );
};

export { MultiSelectFormControl };
