import { createEvent, createStore } from 'effector';

import { call, listen, setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';

import type { AvailableLanguages } from '@kuna-pay/accept-payment/generated/graphql';
import { $$i18n } from '@kuna-pay/accept-payment/shared/i18n';

const $$changeLanguage = atom(() => {
  const changeLanguage = createEvent<AvailableLanguages>(
    '$$changeLanguage.changeLanguage'
  );

  const $changingLanguageTo = createStore<AvailableLanguages | null>(null, {
    name: '$$changeLanguage.$changingLanguageTo',
  });

  listen({
    clock: changeLanguage,
    source: $$i18n.$instance,
    handler: async (language, i18n) => {
      setState($changingLanguageTo, language);
      await call(i18n.changeLanguage, language);
    },
  });

  return {
    $$ui: {
      changeLanguage,
      $changingLanguageTo,
    },
  };
});

export { $$changeLanguage };
