import clsx from 'clsx';
import type { ImgHTMLAttributes } from 'react';
import { memo } from 'react';

import loaderPng from './loader.png';
import styles from './loader.module.scss';

type LoaderProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>;

const Loader = memo(({ className, ...props }: LoaderProps) => (
  <img
    className={clsx(styles.root, className)}
    src={loaderPng}
    width={24}
    height={24}
    alt=''
    {...props}
  />
));

export { Loader };
export type { LoaderProps };
