import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';
import { cloneElement } from 'react';
import { useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';
import type { TransferType as PublicTransferType } from '@kuna-pay/core/generated/public/graphql';

import {
  TransactionFailedIcon,
  TransactionTypeIcon,
} from './transaction-type.icon';
import { transactionTypeToI18n } from './transaction-type.text';
import styles from './transaction-type.module.scss';

type TransactionTypeProps = Partial<TypographyProps> & {
  type: PublicTransferType;

  isFailed?: boolean;

  hideIcon?: boolean;
};

type TransactionTypeIconProps = Pick<
  TransactionTypeProps,
  'type' | 'isFailed'
> &
  ComponentPropsWithoutRef<typeof TransactionFailedIcon>;

const TransactionType = createCompoundComponent(
  (Components) =>
    ({ type, hideIcon, isFailed, ...props }: TransactionTypeProps) =>
      (
        <Components.Root {...props}>
          {!hideIcon && <Components.Icon type={type} isFailed={isFailed} />}

          <Components.Text type={type} />
        </Components.Root>
      ),
  {
    Root: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        className={clsx(styles.root, className)}
        variant='subtitle5'
        {...props}
      />
    ),

    Icon: ({ type, isFailed, ...props }: TransactionTypeIconProps) => {
      const Icon = isFailed ? (
        <TransactionFailedIcon />
      ) : (
        TransactionTypeIcon[type]
      );

      if (!Icon) return null;

      return cloneElement(Icon, props);
    },

    Text: ({ type }: { type: PublicTransferType }) => {
      const { t } = useTranslation('core');

      return <>{t(transactionTypeToI18n[type])}</>;
    },
  }
);

export { TransactionType };
