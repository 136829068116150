import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC } from 'react';

import {
  BuyerInvoiceDepositCard,
  EmptyInvoiceDepositList,
} from '@kuna-pay/accept-payment/entities/transaction';

import { CheckoutPageModel } from '../../../page.model';
import styles from './invoice-transactions.module.scss';

type InvoiceTransactionsProps = {
  className?: string;
  noTopPadding?: boolean;
};

const InvoiceTransactions: FC<InvoiceTransactionsProps> = ({
  className,
  noTopPadding,
}) => {
  const $$model = CheckoutPageModel.useModel();
  const data = useUnit($$model.$invoice)!;

  if (!data.Transfers || data.Transfers.length === 0) {
    return (
      <div className={clsx(className, styles.emptyRoot, styles.root)}>
        <EmptyInvoiceDepositList className={styles.empty} />
      </div>
    );
  }

  return (
    <div className={clsx(className, styles.root)}>
      <div
        className={clsx(styles.transactions, {
          [styles.noTopPadding]: noTopPadding,
        })}
      >
        {data.Transfers.map((transaction) => (
          <BuyerInvoiceDepositCard
            key={transaction.id}
            company={data.Company}
            transaction={transaction}
            invoice={data}
          />
        ))}
      </div>
    </div>
  );
};

export { InvoiceTransactions };
