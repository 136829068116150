import { TransferType as PublicTransferType } from '@kuna-pay/core/generated/public/graphql';

import {
  TransactionFailedIcon,
  TransactionTypeConvertIcon,
  TransactionTypeDepositIcon,
  TransactionTypeInvoiceDepositSolidIcon,
  TransactionTypePayoutIcon,
  TransactionTypeReferralIcon,
  TransactionTypeWithdrawIcon,
} from '../../assets';

const TransactionTypeIcon = {
  [PublicTransferType.InvoiceDeposit]: (
    <TransactionTypeInvoiceDepositSolidIcon />
  ),
  [PublicTransferType.Deposit]: <TransactionTypeDepositIcon />,
  [PublicTransferType.Withdraw]: <TransactionTypeWithdrawIcon />,
  [PublicTransferType.PayoutWithdraw]: <TransactionTypePayoutIcon />,
  [PublicTransferType.Convert]: <TransactionTypeConvertIcon />,
  [PublicTransferType.ReferralReward]: <TransactionTypeReferralIcon />,

  // no-op
  [PublicTransferType.Refund]: null,
};

export { TransactionFailedIcon, TransactionTypeIcon };
