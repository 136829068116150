import { AssetComparator } from '@kuna-pay/core/entities/asset';
import type { Maybe } from '@kuna-pay/core/generated/public/graphql';

type PaymentMethodComparatorEntity = {
  code: string;
  position: number;

  // IDK why, but backend is not required to return this field
  Asset?: Maybe<{ code: string; position: number }>;
};

class PaymentMethodComparator {
  public static ASC = (
    a: PaymentMethodComparatorEntity,
    b: PaymentMethodComparatorEntity
  ) => {
    if (a.position !== b.position) {
      return Comparator.ASC(a.position, b.position);
    }

    // if position is the same, sort by asset position
    // bcs it is not related to payment method
    if (a.Asset && b.Asset) {
      return AssetComparator.compare(a.Asset, b.Asset);
    }

    // Explicit zero instead of `a.position - b.position` (which is zero bcs of equality check)
    // to avoid implicit dependency
    // thus avoiding a potential bug
    return 0;
  };
}

class Comparator {
  /**
   * 1
   * 2
   * 3
   * 4
   */
  public static ASC = (a: number, b: number) => a - b;

  /**
   * 4
   * 3
   * 2
   * 1
   */
  public static DESC = (a: number, b: number) => b - a;
}

export { PaymentMethodComparator };
export type { PaymentMethodComparatorEntity };
