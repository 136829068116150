import {
  ExecuteInvoiceOutput,
  MutationExecutePublicInvoiceArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const executePublicInvoiceRequest = (
  select: Select<ExecuteInvoiceOutput> | string
) => `mutation executePublicInvoice($id: String!) {
  data: executePublicInvoice(id: $id) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'ExecuteInvoiceOutput')}`;
export const executePublicInvoice =
  (select: Select<ExecuteInvoiceOutput> | string) =>
  (args: MutationExecutePublicInvoiceArgs) =>
    query<ExecuteInvoiceOutput>(executePublicInvoiceRequest(select), args);
