import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@kuna-pay/ui/ui/typography';

import { Loader } from '../loader';
import {
  getMinutesFromTimeInSeconds,
  getSecondsFromTimeInSeconds,
} from './timer.lib';
import styles from './timer.module.scss';

type TimerProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  timeInSeconds: number;

  className?: string;
};

type MiniTimerProps = Omit<TimerProps, 'title'>;

const Timer = memo(
  ({ className, title, timeInSeconds, ...props }: TimerProps) => {
    const minutes = getMinutesFromTimeInSeconds(timeInSeconds);
    const seconds = getSecondsFromTimeInSeconds(timeInSeconds);

    return (
      <div className={clsx(styles.root, className)} {...props}>
        <Typography className={styles.title} variant='numbers1'>
          {title}
        </Typography>

        <Loader width={24} height={24} />

        <Typography as='time' variant='subtitle4'>
          {minutes}:{seconds}
        </Typography>
      </div>
    );
  }
);

const MiniTimer = memo(
  ({ className, timeInSeconds, ...props }: MiniTimerProps) => {
    const seconds = getSecondsFromTimeInSeconds(timeInSeconds, {
      leftPad: false,
    });
    const { t } = useTranslation();

    return (
      <div className={clsx(styles.miniRoot, className)} {...props}>
        <Loader width={16} height={16} />

        <Typography as='time' variant='numbers2'>
          {t('shared.ui.timer.mini.seconds', { replace: { seconds } })}
        </Typography>
      </div>
    );
  }
);

export { MiniTimer, Timer };
export type { TimerProps };
