import * as RadixPopover from '@radix-ui/react-popover';
import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { ComponentPropsWithoutRef } from 'react';
import * as React from 'react';
import { forwardRef, useContext } from 'react';

import type {
  ControlState,
  ControlTriggerRootProps,
} from '@kuna-pay/ui/components/control';
import { Control } from '@kuna-pay/ui/components/control';
import { FormControl } from '@kuna-pay/ui/components/form-control';
import type { AsChild } from '@kuna-pay/ui/components/select/lib';
import { SelectRootFactory } from '@kuna-pay/ui/components/select/model';
import { DropdownIcon } from '@kuna-pay/ui/icons';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './trigger.module.scss';

const Trigger = {
  Root: forwardRef<HTMLButtonElement, ControlTriggerRootProps>(
    ({ state, ...props }, ref) => {
      const formState = useContext(FormControl.Context);

      const fixedProps = useFixTriggerForTouchDevice();

      state ??= formState;

      return (
        <RadixPopover.Trigger {...fixedProps} asChild>
          <Control.Trigger.Root ref={ref} state={state} {...props} />
        </RadixPopover.Trigger>
      );
    }
  ),

  Text: forwardRef<
    HTMLSpanElement,
    TypographyProps &
      AsChild & {
        state?: ControlState;
      }
  >(({ asChild, className, state, ...props }, ref) => {
    const Component = asChild ? Slot : Typography;

    const contextState = useContext(FormControl.Context);
    state ??= contextState;

    return (
      <Component
        ref={ref}
        className={clsx(
          {
            [styles.focus]: state === 'focus',
            [styles.error]: state === 'error',
            [styles.disabled]: state === 'disabled',
          },
          className
        )}
        nowrap
        {...props}
      />
    );
  }),

  Icon: ({
    className,
    adornmentClassName,
    ...props
  }: ComponentPropsWithoutRef<typeof DropdownIcon> & {
    adornmentClassName?: string;
  }) => {
    const $$root = SelectRootFactory.useModel();
    const [open] = useUnit([$$root.$open]);

    const { variant } = Control.Trigger.useTriggerControlContext();

    let { state } = Control.Trigger.useTriggerControlContext();

    const contextState = useContext(FormControl.Context);
    state ??= contextState;

    return (
      <Control.Trigger.Adornment className={adornmentClassName} position='end'>
        <DropdownIcon
          className={clsx(
            className,
            styles.icon,
            {
              [styles.open]: open,
            },
            {
              [styles.contained]: variant === 'contained',
              [styles.outlined]: variant === 'outlined',
            },
            {
              [styles.focus]: state === 'focus',
              [styles.error]: state === 'error',
              [styles.disabled]: state === 'disabled',
            }
          )}
          data-open={open}
          {...props}
        />
      </Control.Trigger.Adornment>
    );
  },

  Content: Control.Trigger.Content,
};

/**
 *
 * @see KUPAY-2253
 * @see https://github.com/radix-ui/primitives/issues/2418#issuecomment-1926605763
 */
const useFixTriggerForTouchDevice = (): ComponentPropsWithoutRef<
  typeof RadixPopover.Trigger
> => {
  const $$root = SelectRootFactory.useModel();
  const [open, onOpenChange] = useUnit([$$root.$open, $$root.change]);
  const isTouchDevice = 'ontouchstart' in window;

  return isTouchDevice
    ? {
        onPointerDown: (e) => e.preventDefault(),
        onClick: () => onOpenChange(!open),
      }
    : {};
};

export { Trigger };
