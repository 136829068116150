import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';
import { createContext, forwardRef } from 'react';

import type { UnstyledButtonProps } from '@kuna-pay/ui/ui/button';
import { UnstyledButton } from '@kuna-pay/ui/ui/button';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import type { ChildrenProps } from '../../lib';
import styles from './option.module.scss';

type ItemOptionProps = Omit<UnstyledButtonProps, 'children'> &
  ChildrenProps<{ option: string; checked: boolean }> & {
    option: string;
    size?: OptionSize;
  };

type OptionSize = 'sm' | 'md';

const CheckedContext = createContext<boolean>(false);

const Option = {
  Item: forwardRef<
    HTMLButtonElement,
    ComponentPropsWithoutRef<typeof UnstyledButton> & {
      size?: OptionSize;
    }
  >(({ className, size = 'sm', ...props }, ref) => (
    <UnstyledButton
      ref={ref}
      className={clsx(
        styles.root,
        {
          [styles.sm]: size === 'sm',
          [styles.md]: size === 'md',
        },
        className
      )}
      {...props}
    />
  )),

  Text: forwardRef<HTMLSpanElement, TypographyProps>(
    ({ className, ...props }, ref) => (
      <Typography
        ref={ref}
        className={clsx(styles.text, styles.sm, className)}
        nowrap
        {...props}
      />
    )
  ),
};

export { CheckedContext, Option };
export type { ItemOptionProps, OptionSize };
