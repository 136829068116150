import { createEvent, createStore, sample } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';

const SelectSearchFactory = modelFactory(() => {
  const change = createEvent<string>();
  const clear = createEvent();
  const reset = createEvent();

  const $query = createStore<string>('')
    .on(change, (_, payload) => payload)
    .on(clear, () => '');

  sample({
    clock: reset,
    target: [$query.reinit!],
  });

  return {
    $query,
    clear,

    reset,

    $$ui: {
      $query,
      change,
    },

    __: {},
  };
});

export { SelectSearchFactory };
