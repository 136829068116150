import i18next from 'i18next';

import { capitalize } from '@kuna-pay/utils/string';
import { MerchantIndustryConfig } from '@kuna-pay/core/entities/merchant';

class MerchantIndustryTranslator {
  public static t(industry: string | null) {
    if (!industry || industry === MerchantIndustryConfig.NONE_OPTION) {
      return capitalize(MerchantIndustryConfig.NONE_OPTION);
    }

    return i18next.t(`entities.merchant.industry.options.${industry}`, {
      ns: 'core',
    });
  }
}

export { MerchantIndustryTranslator };
