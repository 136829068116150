import clsx from 'clsx';
import Decimal from 'decimal.js-light';
import { useUnit } from 'effector-react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import { MiniTimer } from '@kuna-pay/accept-payment/shared/ui/timer';

import { CheckoutPageModel } from '../../../page.model';
import { ConfirmationAwaitingStepper } from './confirmation-awaiting.stepper.component';
import styles from './confirmation-awaiting.module.scss';

const ConfirmationAwaitingInvoiceAmount: FC<{ className?: string }> = ({
  className,
}) => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.choose-payment-method.invoice-amount',
  });
  const AssetFormat = useAssetFormat();

  const [isRefetchingRatesPending, timeLeft] = useUnit([
    $$model.$$chosePaymentMethod.$changingPaymentMethod,
    $$model.$$chosePaymentMethod.$$invoicePaymentMethodExpireTimer.$timeLeft,
  ]);

  const {
    InvoiceAsset,
    invoiceAmount,
    paymentAmount,
    buyerFee,
    rate,
    PaymentAsset,
  } = useUnit($$model.$invoice)!;

  const isNumber = typeof timeLeft === 'number';

  return (
    <div className={clsx(styles.root, className)}>
      <ConfirmationAwaitingStepper.Connector />

      <div className={styles.rowWrapper}>
        <ConfirmationAwaitingStepper.StepCircle />

        <div className={styles.row}>
          <Typography className={styles.amount} variant='subtitle5'>
            {t('invoice-amount')}
          </Typography>

          <Typography variant='subtitle5'>
            {AssetFormat.formatAmount(invoiceAmount, InvoiceAsset)}
          </Typography>
        </div>
      </div>

      <div className={styles.rowWrapper}>
        <ConfirmationAwaitingStepper.StepRateCircle
          loading={isRefetchingRatesPending}
        />

        <div className={styles.row}>
          <Typography className={styles.rate} variant='numbers1'>
            {t('exchange-rate')}

            {isNumber && <MiniTimer timeInSeconds={timeLeft} />}
          </Typography>

          <Typography className={styles.rate} variant='numbers1'>
            {AssetFormat.formatExchangeRate(PaymentAsset!, rate, InvoiceAsset)}
          </Typography>
        </div>
      </div>

      <div className={styles.rowWrapper}>
        <ConfirmationAwaitingStepper.StepCircle />

        <div className={styles.row}>
          <Typography className={styles.amount} variant='subtitle5'>
            {t('currency-amount')}
          </Typography>

          <Typography
            className={clsx({ 'text-black400': isRefetchingRatesPending })}
            variant='subtitle5'
          >
            {AssetFormat.formatAmount(
              new Decimal(paymentAmount).sub(buyerFee),
              PaymentAsset!
            )}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { ConfirmationAwaitingInvoiceAmount };
