import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '@kuna-pay/ui/router';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';

import { ArrestedNoticeIcon } from '../../../assets';
import type { NoticeProps } from '../base';
import { Notice } from '../base';
import styles from './arrested.module.scss';

type ArrestedNoticeProps = Omit<NoticeProps, 't'> &
  Partial<Pick<NoticeProps, 't'>> & {
    namespace: string;
    keyPrefix: string;
  };

const ArrestedNotice = memo(
  ({ namespace, keyPrefix, ...props }: ArrestedNoticeProps) => {
    const { t, i18n } = useTranslation(namespace, {
      keyPrefix,
    });

    return (
      <Notice
        className={styles.root}
        t={t}
        icon={<ArrestedNoticeIcon />}
        components={{
          'aml-policy': (
            <ExternalLink
              className={Typography.classes.numbers1}
              href={urls.aml.policy.withLocale(i18n.language)}
              primary
            />
          ),
        }}
        {...props}
      />
    );
  }
);

export type { ArrestedNoticeProps };
export { ArrestedNotice };
