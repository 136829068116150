import { invariant } from '@kuna-pay/utils/invariant';
import type {
  InvoiceDetailsOutput,
  PublicTransferWithAmlDetailsOutput,
} from '@kuna-pay/core/generated/public/graphql';
import { TransferType } from '@kuna-pay/core/generated/public/graphql';

function isTransactionCurrencyMismatchOfInvoice(
  transaction: Pick<PublicTransferWithAmlDetailsOutput, 'asset' | 'type'>,
  invoice: Pick<InvoiceDetailsOutput, 'paymentAssetCode'>
): boolean {
  /**
   * Currency mismatch is only applicable for invoice deposit transactions.
   */
  if (transaction.type !== TransferType.InvoiceDeposit) {
    return false;
  }

  invariant.log(
    !!transaction.asset && !!invoice.paymentAssetCode,
    'Transaction and invoice has no asset. Please check if you are using the correct query or if the data is correct.'
  );

  return transaction.asset !== invoice.paymentAssetCode;
}

export { isTransactionCurrencyMismatchOfInvoice };
