import { useStoreMap } from 'effector-react';
import type { PropsWithChildren, ReactNode } from 'react';

import type { ChildrenProps } from '@kuna-pay/ui/components/select/lib';

import { SingleSelectModel } from '../../model';
import { SingleValue } from './value.single';

const SingleValueState = {
  Empty: ({ children }: PropsWithChildren) => {
    const { $$value } = SingleSelectModel.useModel();
    const isEmpty = useStoreMap($$value.$state, (state) => state === 'empty');

    if (!isEmpty) return null;

    return <>{children}</>;
  },

  Filled: ({ children }: ChildrenProps<string>) => {
    const { $$value } = SingleSelectModel.useModel();
    const isFilled = useStoreMap($$value.$state, (state) => state === 'filled');

    if (!isFilled) return null;

    if (typeof children === 'function')
      return (
        <SingleValue>
          {/* Value always `string` bcs of state */}
          {children as (value: string | null) => ReactNode}
        </SingleValue>
      );

    return <>{children}</>;
  },
};

export { SingleValueState };
