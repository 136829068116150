import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import { CheckoutPageModel } from '../../../page.model';
import styles from './initial.module.scss';

const InitialInvoiceAmount: FC<{ className?: string }> = ({ className }) => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.choose-payment-method.invoice-amount',
  });

  const { InvoiceAsset, invoiceAmount } = useUnit($$model.$invoice)!;
  const AssetFormat = useAssetFormat();

  return (
    <div className={clsx(styles.root, className)}>
      <Typography className={styles.title} variant='body3'>
        {t('invoice-amount')}
      </Typography>

      <Typography variant='h5'>
        {AssetFormat.formatAmount(invoiceAmount, InvoiceAsset)}
      </Typography>
    </div>
  );
};

export { InitialInvoiceAmount };
