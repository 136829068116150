import clsx from 'clsx';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type {
  ControlColor,
  ControlInputAdornmentProps,
} from '@kuna-pay/ui/components/control';
import { Control } from '@kuna-pay/ui/components/control';
import { SearchIcon } from '@kuna-pay/ui/icons';

import styles from './search.module.scss';

type SearchStartRootProps = Omit<ControlInputAdornmentProps, 'position'>;

type SearchStartIconProps = Omit<ComponentProps<'svg'>, 'color'> & {
  color: ControlColor;
};

type SearchStartProps = Omit<ControlInputAdornmentProps, 'position'> &
  Pick<SearchStartIconProps, 'color'>;

const SearchStart = createCompoundComponent(
  ({ Icon, Root }) =>
    forwardRef<HTMLDivElement, SearchStartProps>(({ color, ...props }, ref) => (
      <Root ref={ref} {...props}>
        <Icon color={color} />
      </Root>
    )),

  {
    Root: forwardRef<HTMLDivElement, SearchStartRootProps>((props, ref) => (
      <Control.Input.Adornment ref={ref} position='start' {...props} />
    )),

    Icon: forwardRef<SVGSVGElement, SearchStartIconProps>(
      ({ className, color, ...props }, ref) => (
        <SearchIcon
          ref={ref}
          className={clsx(styles.icon, className, {
            [styles.white]: color === 'white',
            [styles.grey]: color === 'grey',
          })}
          {...props}
        />
      )
    ),
  }
);

export { SearchStart };
export type { SearchStartIconProps, SearchStartProps, SearchStartRootProps };
