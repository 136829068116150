import clsx from 'clsx';
import type { FC } from 'react';

import { Skeleton } from '@kuna-pay/ui/ui/skeleton';

import styles from './skeleton.module.scss';

const SkeletonInvoiceAmount: FC<{ className?: string }> = ({ className }) => (
  <div className={clsx(styles.root, className)}>
    <Skeleton height={88} borderRadius={4} fullWidth />
  </div>
);

export { SkeletonInvoiceAmount };
