import { useStoreMap } from 'effector-react';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from '@kuna-pay/ui/ui/tabs';

import { CheckoutPageModel } from '../../../page.model';
import { InvoiceTransactions } from '../invoice-transactions';
import styles from './invoice-tabs.module.scss';

type InvoiceTabsProps = PropsWithChildren & {
  // (?) Should add prop to forward Footer to InvoiceTransactions (for mobile)
  noTopPadding?: boolean;
};

enum InvoiceTabsEnum {
  Details = 'details',
  Transactions = 'transactions',
}

const InvoiceTabs: FC<InvoiceTabsProps> = ({ children, noTopPadding }) => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.shared.invoice-tabs',
  });

  const transactionsCount = useStoreMap(
    $$model.$invoice,
    (data) => data?.Transfers?.length ?? 0
  );

  return (
    <Tabs
      className={styles.root}
      variant='round'
      defaultValue={InvoiceTabsEnum.Details}
    >
      <Tabs.List className={styles.list}>
        <Tabs.Trigger className='full-width' value={InvoiceTabsEnum.Details}>
          {t('details')}
        </Tabs.Trigger>

        <Tabs.Trigger
          className='full-width'
          value={InvoiceTabsEnum.Transactions}
        >
          {t('transactions', { replace: { count: transactionsCount } })}
        </Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content className={styles.content} value={InvoiceTabsEnum.Details}>
        {children}
      </Tabs.Content>

      <Tabs.Content
        className={styles.content}
        value={InvoiceTabsEnum.Transactions}
      >
        <InvoiceTransactions noTopPadding={noTopPadding} />
      </Tabs.Content>
    </Tabs>
  );
};

export { InvoiceTabs };
