import {
  NonAuthInvoiceDetailsOutput,
  QueryFindPublicInvoiceByIdArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findPublicInvoiceByIdRequest = (
  select: Select<NonAuthInvoiceDetailsOutput> | string
) => `query findPublicInvoiceById($id: String!) {
  data: findPublicInvoiceById(id: $id) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'NonAuthInvoiceDetailsOutput')}`;
export const findPublicInvoiceById =
  (select: Select<NonAuthInvoiceDetailsOutput> | string) =>
  (args: QueryFindPublicInvoiceByIdArgs) =>
    query<NonAuthInvoiceDetailsOutput>(
      findPublicInvoiceByIdRequest(select),
      args
    );
