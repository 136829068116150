import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { TagProps } from '@kuna-pay/ui/ui/tag';
import { Tag } from '@kuna-pay/ui/ui/tag';
import { formatPercent } from '@kuna-pay/core/shared/lib/percent';

type InvoiceDepositRiskScoreTagProps = Pick<TagProps, 'size'> & {
  riskScore: string | number;
};

const InvoiceDepositRiskScoreTag = memo(
  ({ riskScore, size = 'md', ...props }: InvoiceDepositRiskScoreTagProps) => {
    const { t } = useTranslation('common', {
      keyPrefix: 'entities.transaction.invoice-deposit.risk-score.tag',
    });

    return (
      <Tag variant='danger' size={size} {...props}>
        {t('content', {
          replace: {
            riskScore: formatPercent(riskScore, {
              showDecimals: false,
              showPlusSign: false,
            }),
          },
        })}
      </Tag>
    );
  }
);

export { InvoiceDepositRiskScoreTag };
export type { InvoiceDepositRiskScoreTagProps };
