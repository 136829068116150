import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ConvertStatus } from '@kuna-pay/core/generated/public/graphql';

import { AutoConversionStatusMapping } from './status.mappings';

const AutoConversionStatusText: FC<{ status: ConvertStatus }> = ({
  status,
}) => {
  const { t } = useTranslation('core', {
    keyPrefix: 'entities.transaction.ui.invoice-deposit.auto-conversion.status',
  });

  return <>{t(AutoConversionStatusMapping.text[status] ?? status)}</>;
};

export { AutoConversionStatusText };
