import { useUnit } from 'effector-react';
import type { ComponentPropsWithoutRef } from 'react';
import React, { Fragment, useEffect, useRef } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';

import { SelectOptionsFactory } from '../../model';

const SelectOptionsList = createCompoundComponent(
  ({ List, InfiniteLoader }) =>
    (props: ComponentPropsWithoutRef<typeof List>) =>
      (
        <>
          <List {...props} />

          <InfiniteLoader />
        </>
      ),
  {
    List: ({
      renderItem,
    }: {
      renderItem: (
        item: string,
        index: number,
        options: string[]
      ) => React.ReactNode;
    }) => {
      const { $options } = SelectOptionsFactory.useModel();

      return (
        <>
          {useUnit($options).map((option, index, options) => (
            <Fragment key={option}>
              {renderItem(option, index, options)}
            </Fragment>
          ))}
        </>
      );
    },

    InfiniteLoader: () => {
      const { $$loadMore } = SelectOptionsFactory.useModel();

      const [key] = useUnit([$$loadMore.$id]);
      const observer = useUnit($$loadMore.$$observer);

      const ref = useRef<HTMLDivElement>(null);

      useEffect(() => {
        if (!ref.current) return observer.unmounted;

        observer.mounted(ref.current);

        return observer.unmounted;
      }, [key]);

      return <div ref={ref} key={key} style={{ height: 1 }} />;
    },
  }
);

export { SelectOptionsList };
