import { combine } from 'effector';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import type { SchemaOf } from 'yup';
import { object } from 'yup';
import { string } from 'yup';
import type { RequiredStringSchema } from 'yup/lib/string';

import { listen, setState } from '@kuna-pay/utils/effector';
import {
  createExternalDepsRef,
  useLocalModel,
  useSyncExternalDeps,
} from '@kuna-pay/utils/react/use-local-model';
import { objectEntries } from '@kuna-pay/utils/typescript';
import { notify } from '@kuna-pay/ui/notification';
import { createForm } from '@kuna-pay/form';

import type { QuestionnaireFieldOutput } from '@kuna-pay/accept-payment/generated/graphql';
import {
  QuestionnaireFieldType,
  QuestionnaireFieldValidatorType,
} from '@kuna-pay/accept-payment/generated/graphql';
import type { PublicInvoiceDetailsOutput } from '@kuna-pay/accept-payment/pages/checkout/api';
import { saveAnswersToQuestionnaireForNonAuth } from '@kuna-pay/accept-payment/shared/api/generated/Questionnaire/request/saveAnswersToQuestionnaireForNonAuth';

import { CheckoutPageModel } from '../../page.model';
import { QuestionnaireCompletedEvent } from './collect-buyer-info.analytics';

const useCollectBuyerInfoForm = () => {
  const $$model = CheckoutPageModel.useModel();
  const invoice = useUnit($$model.$invoice)!;
  const { i18n } = useTranslation();

  const { $$form, $$ref } = useLocalModel(() => {
    const $$ref = createExternalDepsRef({ i18n, invoice });
    const $$form = createForm<Record<string, string>>({
      initialValues: combine($$ref.$current, ({ i18n }) =>
        new CollectBuyerInfoForm(i18n).initialValuesFromSchema(invoice)
      ),
      schema: combine($$ref.$current, ({ i18n }) =>
        new CollectBuyerInfoForm(i18n).createValidationSchema(invoice)
      ),
    });

    listen({
      clock: $$form.submitted,
      source: $$ref.$current,
      handler: async ({ formError: _, ...values }, { invoice, i18n }) => {
        try {
          setState($$form.$disabled, true);

          await saveAnswersToQuestionnaireForNonAuth({ success: true })({
            id: invoice.questionnaire!.id,

            answers: Object.fromEntries(
              objectEntries(values).map(([name, value]) => [name, value.trim()])
            ),
          });

          const questionnaireCompletedEvent = new QuestionnaireCompletedEvent();
          void questionnaireCompletedEvent.logEvent(values);

          await $$model.getInitialInvoiceInfoFx().catch(() => {
            //TODO: Remove this when handled by the $$model.getInitialInvoiceInfoFx
            notify.warning(
              i18n.t('pages.checkout.requests.load-invoice.failure.unknown')
            );
          });
        } catch (e) {
          notify.warning(
            i18n.t(
              'pages.checkout.requests.submit-questionnaire-answers.failure.unknown'
            )
          );
        } finally {
          setState($$form.$disabled, false);
        }
      },
    });

    return {
      $$form,
      $$ref,
    };
  });
  useSyncExternalDeps($$ref, { i18n, invoice });

  return { $$form, invoice };
};

class CollectBuyerInfoForm {
  public constructor(
    private readonly $$i18n: ReturnType<typeof useTranslation>['i18n']
  ) {}

  public initialValuesFromSchema(invoice: PublicInvoiceDetailsOutput) {
    return (invoice.questionnaire?.Details?.schema ?? []).reduce(
      (initialValues, field) => {
        initialValues[field.name] = '';

        return initialValues;
      },
      {} as Record<string, string>
    );
  }

  public createValidationSchema(
    invoice: PublicInvoiceDetailsOutput
  ): SchemaOf<Record<string, string>> {
    const schemaFields = invoice.questionnaire?.Details?.schema ?? [];

    type FieldSchema = RequiredStringSchema<
      string | undefined,
      Record<string, string>
    >;

    return object(
      schemaFields.reduce((validationSchemaKv, field) => {
        validationSchemaKv[field.name] = field.validators.reduce(
          (schema, validator) => {
            switch (validator.type) {
              case QuestionnaireFieldValidatorType.Min: {
                if (validator.value !== null && validator.value !== undefined) {
                  return schema.min(validator.value);
                }

                return schema;
              }

              case QuestionnaireFieldValidatorType.Max: {
                if (validator.value !== null && validator.value !== undefined) {
                  return schema.max(validator.value);
                }

                return schema;
              }

              case QuestionnaireFieldValidatorType.EmaIl: {
                return schema.email();
              }

              default: {
                return schema;
              }
            }
          },

          string().trim().defined().required().label(this.label(field))
        );

        return validationSchemaKv;
      }, {} as Record<string, FieldSchema>)
    );
  }

  public label(field: QuestionnaireFieldOutput) {
    if (field.label) {
      return field.label;
    }

    switch (field.type) {
      case QuestionnaireFieldType.Email: {
        return this.$$i18n.t(
          `pages.checkout.collect-buyer-info.form.fields.email.label`
        );
      }

      case QuestionnaireFieldType.FirstName: {
        return this.$$i18n.t(
          `pages.checkout.collect-buyer-info.form.fields.first-name.label`
        );
      }

      case QuestionnaireFieldType.LastName: {
        return this.$$i18n.t(
          `pages.checkout.collect-buyer-info.form.fields.last-name.label`
        );
      }

      case QuestionnaireFieldType.BillingAddress: {
        return this.$$i18n.t(
          `pages.checkout.collect-buyer-info.form.fields.address.label`
        );
      }

      default: {
        return field.label ?? field.name ?? '';
      }
    }
  }

  public placeholder(field: QuestionnaireFieldOutput) {
    if (field.description) {
      return field.description;
    }

    switch (field.type) {
      case QuestionnaireFieldType.Email: {
        return this.$$i18n.t(
          `pages.checkout.collect-buyer-info.form.fields.email.placeholder`
        );
      }

      case QuestionnaireFieldType.FirstName: {
        return this.$$i18n.t(
          `pages.checkout.collect-buyer-info.form.fields.first-name.placeholder`
        );
      }

      case QuestionnaireFieldType.LastName: {
        return this.$$i18n.t(
          `pages.checkout.collect-buyer-info.form.fields.last-name.placeholder`
        );
      }

      case QuestionnaireFieldType.BillingAddress: {
        return this.$$i18n.t(
          `pages.checkout.collect-buyer-info.form.fields.address.placeholder`
        );
      }

      default: {
        return field.description ?? '';
      }
    }
  }
}

export { CollectBuyerInfoForm, useCollectBuyerInfoForm };
