/* eslint-disable padding-line-between-statements */
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { Field, Form } from '@kuna-pay/form';

import {
  QuestionnaireFieldType,
  QuestionnaireFieldValidatorType,
} from '@kuna-pay/accept-payment/generated/graphql';

import { CheckoutProgressTracker } from '../shared';
import {
  CollectBuyerInfoForm,
  useCollectBuyerInfoForm,
} from './collect-buyer-info.hook';
import { CollectBuyerInfoHeader } from './collect-buyer-info-header.component';
import styles from './collect-buyer-info.module.scss';

type CollectBuyerInfoProps = PropsWithChildren & {
  className?: string;
};

const CollectBuyerInfo = memo(
  ({ children, className }: CollectBuyerInfoProps) => {
    const { t, i18n } = useTranslation();
    const { $$form, invoice } = useCollectBuyerInfoForm();

    return (
      <>
        <CheckoutProgressTracker />

        <Paper className={clsx(styles.root, className)}>
          <CollectBuyerInfoHeader />

          <Typography className={styles.title} variant='h7'>
            {t('pages.checkout.collect-buyer-info.title')}
          </Typography>

          <Typography className={styles.subtitle} variant='body3'>
            {t('pages.checkout.collect-buyer-info.description')}
          </Typography>

          <Form
            name='collect-buyer-info-form'
            className={styles.form}
            use={$$form}
          >
            <div className={styles.formFields}>
              {invoice.questionnaire!.Details!.schema!.map((field) => {
                const collectBuyerInfoForm = new CollectBuyerInfoForm(i18n);

                const sharedProps = {
                  variant: 'outlined',
                  size: 'md-lg',
                  field: $$form.fields[field.name],
                  name: field.name,
                  label: collectBuyerInfoForm.label(field),
                  placeholder: collectBuyerInfoForm.placeholder(field),
                } satisfies ComponentPropsWithoutRef<typeof Field.TextField>;

                switch (field.type) {
                  case QuestionnaireFieldType.BillingAddress: {
                    const maxLengthValidator = field.validators.find(
                      (validator) =>
                        validator.type === QuestionnaireFieldValidatorType.Max
                    );

                    return (
                      <Field.TextAreaField
                        key={field.name}
                        maxLength={maxLengthValidator?.value}
                        {...sharedProps}
                      />
                    );
                  }

                  case QuestionnaireFieldType.Email:
                  case QuestionnaireFieldType.LastName:
                  case QuestionnaireFieldType.FirstName: {
                    return (
                      <Field.TextField key={field.name} {...sharedProps} />
                    );
                  }

                  // This is a catch-all for any other field type for future cases
                  default: {
                    return (
                      <Field.TextField key={field.name} {...sharedProps} />
                    );
                  }
                }
              })}
            </div>

            <Form.Submit
              variant='contained'
              color='primary'
              size='xl'
              fullWidth
            >
              {t('pages.checkout.collect-buyer-info.form.submit')}
            </Form.Submit>
          </Form>

          {children}

          <div className={styles.submitSpacer} />
        </Paper>

        <div className={styles.spacer} />
      </>
    );
  }
);

export { CollectBuyerInfo };
