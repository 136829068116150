import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Paper } from '@kuna-pay/ui/ui/paper';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { InvoiceStatusText } from '@kuna-pay/core/entities/invoice/ui/status/text.ui';
import { InvoiceStatus } from '@kuna-pay/core/generated/public/graphql';

import { CheckoutPageModel } from '@kuna-pay/accept-payment/pages/checkout/page.model';
import { SendReceipt } from '@kuna-pay/accept-payment/pages/checkout/ui/send-receipt';

import errorImg from '../../../assets/error.png';
import {
  InvoiceAmounts,
  InvoiceDetails,
  InvoiceTabs,
  SupportInfo,
} from '../../shared';
import styles from './suspended.module.scss';

const OrderDetailsSuspended: FC<PropsWithChildren & { className?: string }> = ({
  className,
  children,
}) => {
  const $$model = CheckoutPageModel.useModel();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.suspended',
  });
  const sendReceipt = useUnit($$model.$$orderDetails.$$sendReceipt);
  const { Company } = useUnit($$model.$invoice)!;

  return (
    <Paper
      className={clsx(className, styles.root, {
        [styles.grow]: !sendReceipt.isOpen,
      })}
    >
      {!sendReceipt.isOpen ? (
        <InvoiceTabs noTopPadding>
          <div className={styles.container}>
            <div className={styles.imgContainer}>
              <img src={errorImg} width={126} height={126} alt='' />
            </div>

            <Typography className={styles.title} variant='h7' center>
              <InvoiceStatusText status={InvoiceStatus.Suspended} />
            </Typography>

            <Typography className={styles.description} variant='body3' center>
              {t('description')}
            </Typography>

            <InvoiceAmounts.Root>
              <InvoiceAmounts.AmountLeft />

              <InvoiceAmounts.ReceivedAmount />

              <InvoiceAmounts.PaymentAmount />
            </InvoiceAmounts.Root>

            <InvoiceDetails
              className={clsx({
                [styles.invoiceDetails]: !!Company.supportInfo,
              })}
            />

            <SupportInfo />

            <SendReceipt.Button
              className={clsx({
                [styles.sendReceiptButton]: !!Company.supportInfo,
              })}
              $$model={$$model.$$orderDetails.$$sendReceipt}
            />

            {children}
          </div>
        </InvoiceTabs>
      ) : (
        <>
          <SendReceipt $$model={$$model.$$orderDetails.$$sendReceipt} />

          {children}
        </>
      )}
    </Paper>
  );
};

export { OrderDetailsSuspended };
