import clsx from 'clsx';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { CopyButtonProps } from '@kuna-pay/ui/ui/button';
import { CopyButton } from '@kuna-pay/ui/ui/button';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './details.module.scss';

type DetailsProps = PropsWithChildren & {
  className?: string;
  valueTheme?: 'light' | 'dark';
};

const ValueThemeContext = createContext<'light' | 'dark'>('light');

const Details = createCompoundComponent(
  ({ Root }) =>
    ({ className, children, valueTheme = 'dark' }: DetailsProps) =>
      (
        <ValueThemeContext.Provider value={valueTheme}>
          <Root className={className}>{children}</Root>
        </ValueThemeContext.Provider>
      ),
  {
    Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.root, className)} {...props} />
    ),

    Row: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.row, className)} {...props} />
    ),

    Label: ({
      className,
      ...props
    }: PartialField<TypographyProps, 'variant'>) => (
      <Typography
        className={clsx(styles.label, className)}
        variant='body3'
        nowrap
        {...props}
      />
    ),

    Value: ({
      className,
      ...props
    }: PartialField<TypographyProps, 'variant'>) => {
      const theme = useContext(ValueThemeContext);

      return (
        <Typography
          className={clsx(styles.value, styles[theme], className)}
          variant='subtitle5'
          nowrap
          {...props}
        />
      );
    },

    IdValue: ({
      className,
      value,
      customMessage,
      classes,
      ...props
    }: PartialField<TypographyProps, 'variant'> & CopyButtonProps) => {
      const theme = useContext(ValueThemeContext);

      return (
        <CopyButton
          classes={classes}
          value={value}
          customMessage={customMessage}
        >
          <Typography
            className={clsx(styles.value, styles.id, styles[theme], className)}
            variant='subtitle5'
            nowrap
            {...props}
          />
        </CopyButton>
      );
    },
  }
);

export { Details };
