import { objectValues } from '@kuna-pay/utils/typescript';
import { RateYourExperienceManager } from '@kuna-pay/core/shared/feedback/features/rate-your-experience';

import { publicRateUX } from '@kuna-pay/accept-payment/shared/api/generated/Feedback/request/publicRateUX';
import { LocalStoragePersistKeys } from '@kuna-pay/accept-payment/shared/persist';

enum RateYourExperienceFeedbackKey {
  InvoicePayment = 'invoice-payment',
}

const $$rateYourExperienceFeedback =
  RateYourExperienceManager.factory.createModel<RateYourExperienceFeedbackKey>({
    keys: objectValues(RateYourExperienceFeedbackKey),

    sendFeedbackFx: async (key, values) => {
      await publicRateUX({ success: true })({
        feature: key,
        rating: values.rate,
        feedback: values.feedback,
      });
    },

    PERSIST_KEY: LocalStoragePersistKeys.RateYourExperienceFeedback,
  });

export { $$rateYourExperienceFeedback, RateYourExperienceFeedbackKey };
