import { Query, SearcherFactory } from '@m31coding/fuzzy-search';
import type { Store } from 'effector';
import { combine } from 'effector';

import { modelFactory } from '@kuna-pay/utils/effector';
import type {
  ExternalMultiValueSource,
  ExternalSingleValueSource,
} from '@kuna-pay/ui/components/select';
import { MultiSelect } from '@kuna-pay/ui/components/select';
import { createSyncLoader } from '@kuna-pay/ui/components/select';
import { SingleSelect } from '@kuna-pay/ui/components/select';

import { MerchantIndustryConfig } from '../config';
import { MerchantIndustryTranslator } from '../i18n';

type MerchantIndustrySingleSelectModelConfig = {
  $language: Store<string>;

  $$value?: ExternalSingleValueSource;

  showNone?: boolean;
};
type MerchantIndustryMultiSelectModelConfig = {
  $language: Store<string>;

  $$value?: ExternalMultiValueSource;

  showNone?: boolean;
};

const MerchantIndustrySingleSelectModel = modelFactory(
  ({ $language, $$value, showNone }: MerchantIndustrySingleSelectModelConfig) =>
    SingleSelect.factory.createModel({
      $$loader: createMerchantIndustrySyncSelectLoader({
        $language,
        showNone,
      }),
      $$value,
    })
);

const MerchantIndustryMultiSelectModel = modelFactory(
  ({ $language, $$value, showNone }: MerchantIndustryMultiSelectModelConfig) =>
    MultiSelect.factory.createModel({
      $$loader: createMerchantIndustrySyncSelectLoader({
        $language,
        showNone,
      }),
      $$value,
    })
);

type CreateMerchantIndustrySyncSelectLoaderConfig = {
  $language: Store<string>;

  showNone?: boolean;
};

const createMerchantIndustrySyncSelectLoader = ({
  $language,
  showNone,
}: CreateMerchantIndustrySyncSelectLoaderConfig) => {
  const searcher = SearcherFactory.createDefaultSearcher<
    { value: string; translated: string },
    string
  >();

  return createSyncLoader({
    options: combine($language, () => {
      const ordered = [
        MerchantIndustryConfig.highPriorityOptions,
        MerchantIndustryConfig.defaultOptions,
        MerchantIndustryConfig.lowPriorityOptions,
      ];

      if (showNone) {
        ordered.push([MerchantIndustryConfig.NONE_OPTION]);
      }

      const options = ordered.flatMap((options) =>
        options.slice().sort((a, b) => IndustryComparator.compare(a, b))
      );

      const translated = options.map((option) => ({
        value: option,
        translated: MerchantIndustryTranslator.t(option),
      }));

      searcher.removeEntities(options);
      searcher.indexEntities(
        translated,
        (entity) => entity.value,
        (entity) => [entity.translated]
      );

      return options;
    }),

    filter: (options, searchQuery) => {
      if (!searchQuery.trim()) {
        return options;
      }

      const matches = searcher.getMatches(
        new Query(searchQuery.toLowerCase(), Infinity, 0)
      );

      return matches.matches.map((match) => match.entity.value);
    },
  });
};

class IndustryComparator {
  public static compare = (a: string, b: string) => {
    const translatedA = MerchantIndustryTranslator.t(a);
    const translatedB = MerchantIndustryTranslator.t(b);

    return translatedA.localeCompare(translatedB);
  };
}

export type {
  MerchantIndustryMultiSelectModelConfig,
  MerchantIndustrySingleSelectModelConfig,
};
export { MerchantIndustryMultiSelectModel, MerchantIndustrySingleSelectModel };
