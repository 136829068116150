import type { Event, Store } from 'effector';
import { combine } from 'effector';
import { createEvent, createStore } from 'effector';

import { atom } from '@kuna-pay/utils/misc';

import type { ValueState } from './value.declaration';
import type { ExternalSingleValueSource } from './value.single.declaration';

const createExternalSingleValue = (config: {
  $$value: ExternalSingleValueSource;
}) => {
  const $$value = config.$$value;
  const $value = $$value.$value as Store<string | null>;
  const change = $$value.change as Event<string | null>;
  const reset = $$value.reset ?? createEvent();

  const { $focused, focus, blur } = atom(() => {
    const focus = $$value.focus ?? createEvent();
    const blur = $$value.blur ?? createEvent();

    const $focused = createStore(false)
      .on(focus, () => true)
      .on(blur, () => false);

    return {
      $focused: $$value.$focused ?? $focused,
      focus,
      blur,
    };
  });

  return {
    /**
     * To trigger close if clicked on selected option
     */
    changed: change,
    $value,

    focus,
    blur,
    reset,

    $$ui: {
      $value,
      $focused,
      change,

      $state: combine(
        $value,
        (value): ValueState => (value ? 'filled' : 'empty')
      ),
    },

    __: {},
  };
};

export { createExternalSingleValue };
