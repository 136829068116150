import { useStoreMap, useUnit } from 'effector-react';
import type { ComponentPropsWithoutRef } from 'react';
import { cloneElement, forwardRef, isValidElement, useContext } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { Checkbox } from '@kuna-pay/ui/ui/checkbox';

import { MultiSelectModel } from '../../model';
import type { ItemOptionProps } from './option.core';
import { CheckedContext, Option } from './option.core';

const MultiSelectCheckboxOption = createCompoundComponent(
  ({ Root, Indicator, Text }) =>
    ({
      option,
      formatOption,
      size,
      ...props
    }: Omit<ComponentPropsWithoutRef<typeof Text>, 'children'> &
      Pick<ItemOptionProps, 'size'> & {
        option: string;

        formatOption?: (option: string) => string;
      }) =>
      (
        <Root option={option} size={size}>
          {({ option }) => {
            const formattedOption = formatOption
              ? formatOption(option)
              : option;

            return (
              <>
                <Text title={formattedOption} {...props}>
                  {formattedOption}
                </Text>

                <Indicator />
              </>
            );
          }}
        </Root>
      ),

  {
    Root: forwardRef<HTMLButtonElement, ItemOptionProps>(
      ({ option, children, ...props }, ref) => {
        const { $$value } = MultiSelectModel.useModel();
        const [onRemoveValue, onAddValue] = useUnit([
          $$value.remove,
          $$value.add,
        ]);

        const checked = useStoreMap({
          keys: [option],
          store: $$value.$value,
          fn: (value, [option]) => value.includes(option),
        });

        const onChange = () => {
          if (checked) {
            onRemoveValue(option);

            return;
          }

          onAddValue(option);
        };

        return (
          <Option.Item ref={ref} onClick={onChange} {...props}>
            <CheckedContext.Provider value={checked}>
              {typeof children === 'function'
                ? children({ option, checked })
                : isValidElement(children)
                ? cloneElement<any>(children, { 'data-checked': checked })
                : children}
            </CheckedContext.Provider>
          </Option.Item>
        );
      }
    ),

    Text: Option.Text,

    Indicator: forwardRef<
      HTMLButtonElement,
      Partial<Omit<ComponentPropsWithoutRef<typeof Checkbox>, 'className'>>
    >((props, ref) => {
      const checked = useContext(CheckedContext);

      return (
        <Checkbox
          ref={ref}
          checked={checked}
          size='lg'
          tabIndex={-1}
          disableInteractive
          {...props}
        />
      );
    }),
  }
);

export { MultiSelectCheckboxOption };
