import {
  QuestionnaireForNonAuthOutput,
  QueryFindOneQuestionnaireToFillForNonAuthArgs,
} from '@kuna-pay/accept-payment/generated/graphql';
import {
  subscription,
  query,
} from '@kuna-pay/accept-payment/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findOneQuestionnaireToFillForNonAuthRequest = (
  select: Select<QuestionnaireForNonAuthOutput> | string
) => `query findOneQuestionnaireToFillForNonAuth($entityId: String) {
  data: findOneQuestionnaireToFillForNonAuth(entityId: $entityId) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'QuestionnaireForNonAuthOutput')}`;
export const findOneQuestionnaireToFillForNonAuth =
  (select: Select<QuestionnaireForNonAuthOutput> | string) =>
  (args: QueryFindOneQuestionnaireToFillForNonAuthArgs) =>
    query<QuestionnaireForNonAuthOutput>(
      findOneQuestionnaireToFillForNonAuthRequest(select),
      args
    );
