import { useTranslation } from 'react-i18next';

import { InvoiceStatus } from '@kuna-pay/core/generated/public/graphql';

type InvoiceStatusTextProps = {
  status: InvoiceStatus;
  isAfterTimeout?: boolean;
};

const useInvoiceStatusTextI18n = () => {
  const { t } = useTranslation('core', {
    keyPrefix: 'entities.invoice.ui.status',
  });

  return {
    t: ({ status, isAfterTimeout }: InvoiceStatusTextProps): string => {
      const i18nKey = isAfterTimeout
        ? invoiceStatusAfterTimeoutI18n[status] ?? invoiceStatusI18n[status]
        : invoiceStatusI18n[status];

      return t(i18nKey);
    },
  };
};

const InvoiceStatusText = ({
  isAfterTimeout,
  status,
}: InvoiceStatusTextProps) => {
  const { t } = useInvoiceStatusTextI18n();

  return <>{t({ status, isAfterTimeout })}</>;
};

function isValidInvoiceStatus(status: string): status is InvoiceStatus {
  return Object.keys(invoiceStatusI18n).includes(status);
}

const invoiceStatusI18n: Record<InvoiceStatus, string> = {
  [InvoiceStatus.Created]: 'created',
  [InvoiceStatus.ConfirmationAwaiting]: 'confirmation-awaiting',
  [InvoiceStatus.LimitsOutOfRange]: 'limits-out-of-range',
  [InvoiceStatus.PaymentAwaiting]: 'payment-awaiting',
  [InvoiceStatus.PartiallyPaid]: 'partially-paid',
  [InvoiceStatus.CurrencyMismatch]: 'currency-mismatch',
  [InvoiceStatus.Paid]: 'paid',
  [InvoiceStatus.Timeout]: 'timeout',
  [InvoiceStatus.Deactivated]: 'deactivated',
  [InvoiceStatus.Declined]: 'declined',
  [InvoiceStatus.Arrested]: 'arrested',
  [InvoiceStatus.Suspended]: 'suspended',
} as const;

const invoiceStatusAfterTimeoutI18n: Partial<Record<InvoiceStatus, string>> = {
  [InvoiceStatus.PartiallyPaid]: 'partially-paid-after-timeout',
  [InvoiceStatus.Paid]: 'paid-after-timeout',
} as const;

export { InvoiceStatusText, isValidInvoiceStatus, useInvoiceStatusTextI18n };
