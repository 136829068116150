import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '@kuna-pay/ui/router';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { Notice } from '@kuna-pay/core/entities/transaction/ui/notice';
import { urls } from '@kuna-pay/core/shared/config';

import styles from './arrested-notice.module.scss';

type BuyerArrestedInvoiceDepositNoticeProps = {
  className?: string;
};

const BuyerArrestedInvoiceDepositNotice = memo(
  ({ className }: BuyerArrestedInvoiceDepositNoticeProps) => {
    const { t, i18n } = useTranslation('common', {
      keyPrefix: 'entities.transaction.invoice-deposit.notice.arrested',
    });

    return (
      <Notice
        className={clsx(styles.root, className)}
        t={t}
        components={{
          'aml-policy': (
            <ExternalLink
              className={Typography.classes.numbers1}
              href={urls.aml.policy.withLocale(i18n.language)}
              primary
            />
          ),
          'typography-numbers1': <Typography variant='numbers1' />,
        }}
      />
    );
  }
);

export { BuyerArrestedInvoiceDepositNotice };
export type { BuyerArrestedInvoiceDepositNoticeProps };
