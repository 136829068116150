import clsx from 'clsx';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';

import { CheckoutPageModel } from '../../../page.model';
import { Details } from '../../shared';
import styles from './invoice-details.module.scss';

type InvoiceDetailsProps = { className?: string };

const InvoiceDetails = ({ className }: InvoiceDetailsProps) => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.choose-payment-method.invoice-details',
  });
  const AssetFormat = useAssetFormat();

  const [isSkeletonLoading, isRefetchingRatesPending] = useUnit([
    $$model.$$chosePaymentMethod.$loadingInitialRates,
    $$model.$$chosePaymentMethod.$changingPaymentMethod,
  ]);

  const {
    status,

    paymentAmount,
    buyerFee,
    PaymentAsset,
    PaymentMethod,
  } = useUnit($$model.$invoice)!;

  const showTotal =
    !!PaymentMethod &&
    !!PaymentAsset &&
    status !== InvoiceStatus.LimitsOutOfRange;

  return (
    <>
      {isSkeletonLoading ? (
        <Details className={clsx(styles.root, className)} valueTheme='light'>
          <Details.Row>
            <Skeleton height={20} borderRadius={4} fullWidth />
          </Details.Row>

          <Details.Row>
            <Skeleton height={20} borderRadius={4} fullWidth />
          </Details.Row>
        </Details>
      ) : (
        <>
          {showTotal && (
            <>
              <div className={styles.divider} />

              <Details
                className={clsx(styles.root, className)}
                valueTheme='light'
              >
                <Details.Row className={styles.fee}>
                  <Details.Label>{t('fee')}:</Details.Label>

                  <Details.Value
                    className={clsx({
                      'text-black400': isRefetchingRatesPending,
                    })}
                    title={AssetFormat.formatAmount(buyerFee, PaymentAsset)}
                  >
                    {AssetFormat.formatAmount(buyerFee, PaymentAsset)}
                  </Details.Value>
                </Details.Row>

                <Details.Row>
                  <Details.Label className={styles.total} variant='subtitle5'>
                    {t('total')}:
                  </Details.Label>

                  <Details.Value
                    className={clsx(styles.total, {
                      'text-black400': isRefetchingRatesPending,
                    })}
                    variant='subtitle2'
                    title={AssetFormat.formatAmount(
                      paymentAmount,
                      PaymentAsset
                    )}
                  >
                    {AssetFormat.formatAmount(paymentAmount, PaymentAsset)}
                  </Details.Value>
                </Details.Row>
              </Details>
            </>
          )}
        </>
      )}
    </>
  );
};

export { InvoiceDetails };
