import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';

import {
  TriggerControlContextProvider,
  useTriggerControlContext,
} from '@kuna-pay/ui/components/control/control.context';
import { UnstyledButton } from '@kuna-pay/ui/ui/button';

import type {
  ControlTriggerAdornmentProps,
  ControlTriggerContentProps,
  ControlTriggerRootProps,
} from './control.trigger.types';
import styles from './control.trigger.module.scss';

/**
 * Includes:
 *  - Select
 *  - SelectOption
 *  - Menus
 *  - Accordion
 *  - NavItem
 */
const ControlTrigger = {
  Root: forwardRef<HTMLButtonElement, ControlTriggerRootProps>(
    (
      { asChild, className, variant, color, size, state = 'idle', ...props },
      ref
    ) => {
      const Component = asChild ? Slot : UnstyledButton;

      const contextValue = useMemo(
        () => ({ size, variant, color, state }),
        [size, variant, color, state]
      );

      return (
        <TriggerControlContextProvider value={contextValue}>
          <Component
            ref={ref}
            className={clsx(
              styles.root,
              {
                [styles.contained]: variant === 'contained',
                [styles.outlined]: variant === 'outlined',
              },
              {
                [styles.grey]: color === 'grey',
                [styles.white]: color === 'white',
              },
              {
                [styles.focus]: state === 'focus',
                [styles.error]: state === 'error',
                [styles.disabled]: state === 'disabled',
              },
              {
                [styles.sm]: size === 'sm',
                [styles.md]: size === 'md',
                [styles.lg]: size === 'lg',
                [styles.xl]: size === 'xl',
                [styles.xxl]: size === 'xxl',
              },
              className
            )}
            disabled={state === 'disabled'}
            {...props}
          />
        </TriggerControlContextProvider>
      );
    }
  ),

  Content: forwardRef<HTMLDivElement, ControlTriggerContentProps>(
    (
      { asChild, className, hasStartAdornment, hasEndAdornment, ...props },
      ref
    ) => {
      const Component = asChild ? Slot : 'div';

      const { size } = useTriggerControlContext();

      return (
        <Component
          ref={ref}
          className={clsx(
            styles.content,
            {
              [styles.sm]: size === 'sm',
              [styles.md]: size === 'md',
              [styles.lg]: size === 'lg',
              [styles.xl]: size === 'xl',
              [styles.xxl]: size === 'xxl',
            },
            {
              [styles.start]: hasStartAdornment,
              [styles.end]: hasEndAdornment,
            },
            className
          )}
          {...props}
        />
      );
    }
  ),

  Adornment: forwardRef<HTMLDivElement, ControlTriggerAdornmentProps>(
    ({ asChild, className, position, ...props }, ref) => {
      const Component = asChild ? Slot : 'div';

      const { size } = useTriggerControlContext();

      return (
        <Component
          ref={ref}
          className={clsx(
            styles.adornment,
            {
              [styles.xxs]: size === 'xxs',
              [styles.sm]: size === 'sm',
              [styles.md]: size === 'md',
              [styles.lg]: size === 'lg',
              [styles.xl]: size === 'xl',
              [styles.xxl]: size === 'xxl',
            },
            {
              [styles.start]: position === 'start',
              [styles.end]: position === 'end',
            },
            className
          )}
          {...props}
        />
      );
    }
  ),

  useTriggerControlContext,
};

export { ControlTrigger };
