import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { modelView } from '@kuna-pay/utils/effector';
import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { Button } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { Field, Form } from '@kuna-pay/form';
import { TimeoutedResend } from '@kuna-pay/core/features/auth/abstract/timeouted-resend';
import { RecaptchaBadgeGate } from '@kuna-pay/core/shared/recaptcha';

import { $$recaptcha } from '@kuna-pay/accept-payment/shared/recaptcha';

import mailIllustrationPng from '../../assets/send-receipt/mail.illustration.png';
import { SendReceiptModel } from '../../model';
import styles from './send-receipt.module.scss';

const SendReceipt = createCompoundComponent(
  () =>
    modelView(SendReceiptModel, () => {
      const $$model = SendReceiptModel.useModel();

      const { t } = useTranslation(undefined, {
        keyPrefix: 'pages.checkout.send-receipt',
      });

      const [onReset] = useUnit([$$model.cancelled]);

      return (
        <div className={styles.root}>
          <img
            className={styles.img}
            src={mailIllustrationPng}
            alt=''
            width={326}
            height={160}
          />

          <Typography className={styles.title} variant='h7' center>
            {t('title')}
          </Typography>

          <Typography className={styles.description} variant='body3' center>
            {t('description')}
          </Typography>

          <Form
            name='send-receipt-form'
            className={styles.form}
            use={$$model.$$form}
          >
            <RecaptchaBadgeGate $$model={$$recaptcha} />

            <Field.TextField
              className={styles.email}
              field={$$model.$$form.fields.email}
              variant='outlined'
              size='lg'
              placeholder={t('form.email.placeholder')!}
            />

            <div className={styles.actions}>
              <Form.Submit
                className={styles.submit}
                variant='contained'
                color='primary'
                size='lg'
                fullWidth
              >
                {t('form.submit')}
              </Form.Submit>

              <Form.Reset
                className={styles.reset}
                variant='text'
                color='primary'
                size='lg'
                fullWidth
                onClick={onReset}
              >
                {t('form.cancel')}
              </Form.Reset>
            </div>
          </Form>
        </div>
      );
    }),
  {
    Button: modelView(
      SendReceiptModel,
      ({ className }: { className?: string }) => {
        const $$model = SendReceiptModel.useModel();

        const { t } = useTranslation(undefined, {
          keyPrefix: 'pages.checkout.send-receipt.button',
        });

        const [timerPending, onOpen] = useUnit([
          $$model.$$resend.$$ui.$pending,
          $$model.open,
        ]);

        return (
          <>
            <Button
              className={className}
              variant='contained'
              color='secondary'
              size='xl'
              typographyVariant='subtitle3'
              fullWidth
              onClick={onOpen}
              disabled={timerPending}
            >
              {t('text')}
            </Button>

            {!!timerPending && (
              <Typography
                className={styles.resend}
                as='p'
                variant='body2'
                center
              >
                {t('resend.text')}{' '}
                <TimeoutedResend.Button $$model={$$model.$$resend} />
              </Typography>
            )}
          </>
        );
      }
    ),
  }
);

export { SendReceipt };
