import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { ArrowLeftIcon, AssetIcon, DropdownIcon } from '@kuna-pay/ui/icons';
import { BaseButton, IconButton } from '@kuna-pay/ui/ui/button';
import { Paper } from '@kuna-pay/ui/ui/paper';
import { Tag } from '@kuna-pay/ui/ui/tag';
import { SearchField } from '@kuna-pay/ui/ui/text-field';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { CheckoutProgressTracker } from '@kuna-pay/accept-payment/pages/checkout/ui/shared';

import { CheckoutPageModel } from '../../../page.model';
import styles from './select-currency.module.scss';

const SelectCurrency = createCompoundComponent(
  ({ SearchInput, SearchResults }) =>
    ({ children, className }: PropsWithChildren & { className?: string }) => {
      const {
        $$chosePaymentMethod: { $$selectPaymentMethod },
      } = CheckoutPageModel.useModel();

      const { t } = useTranslation(undefined, {
        keyPrefix: 'pages.checkout.choose-payment-method.select-currency',
      });

      const { isOpen, onGoBackClick } = useUnit({
        isOpen: $$selectPaymentMethod.$isOpen,
        onGoBackClick: $$selectPaymentMethod.goBackClicked,
      });

      if (!isOpen) return <>{children}</>;

      return (
        <>
          <CheckoutProgressTracker className={styles.tracker} />

          <Paper className={clsx(className, styles.paper)}>
            <div className={styles.root}>
              <div className={styles.header}>
                <IconButton onClick={onGoBackClick}>
                  <ArrowLeftIcon />
                </IconButton>

                <Typography className={styles.headerText} variant='h6'>
                  {t('header')}
                </Typography>
              </div>

              <SearchInput />

              <SearchResults />
            </div>
          </Paper>
        </>
      );
    },

  {
    Button: () => {
      const {
        $$chosePaymentMethod: { $$selectPaymentMethod },
      } = CheckoutPageModel.useModel();

      const { t } = useTranslation(undefined, {
        keyPrefix: 'pages.checkout.choose-payment-method.select-currency',
      });

      const { value, onClick } = useUnit({
        value: $$selectPaymentMethod.$value,
        onClick: $$selectPaymentMethod.buttonClicked,
      });

      return (
        <BaseButton className={styles.button} onClick={onClick}>
          {!!value?.icon ? (
            <img className={styles.buttonAssetIcon} src={value.icon} alt='' />
          ) : (
            <AssetIcon className={styles.buttonAssetIcon} />
          )}

          <span className={styles.buttonContent}>
            {!value ? (
              <Typography className={styles.buttonText} variant='subtitle5'>
                {t('button.text')}
              </Typography>
            ) : (
              <>
                <Typography className={styles.buttonText} variant='subtitle5'>
                  {value.name}
                </Typography>

                {!!value.network && (
                  <Tag className={styles.buttonContentTag} variant='common'>
                    {value.network}
                  </Tag>
                )}
              </>
            )}
          </span>

          <DropdownIcon className={styles.buttonDropdownIcon} />
        </BaseButton>
      );
    },

    SearchInput: () => {
      const {
        $$chosePaymentMethod: { $$selectPaymentMethod },
      } = CheckoutPageModel.useModel();

      const { t } = useTranslation(undefined, {
        keyPrefix: 'pages.checkout.choose-payment-method.select-currency',
      });

      const { query, onQueryClear, onQueryChange } = useUnit({
        query: $$selectPaymentMethod.$$search.$query,
        onQueryChange: $$selectPaymentMethod.$$search.changed,
        onQueryClear: $$selectPaymentMethod.$$search.cleared,
      });

      return (
        <SearchField
          className={styles.searchInput}
          classes={{ input: styles.searchInputInput }}
          type='search'
          placeholder={t('search.placeholder')!}
          value={query}
          onChange={(event) => onQueryChange(event.target.value)}
          onClear={onQueryClear}
          autoComplete='off'
        />
      );
    },

    SearchResults: () => {
      const {
        $$chosePaymentMethod: { $$selectPaymentMethod },
      } = CheckoutPageModel.useModel();

      const { options, onOptionClick } = useUnit({
        options: $$selectPaymentMethod.$$search.$options,
        onOptionClick: $$selectPaymentMethod.searchItemClicked,
      });

      return (
        <div className={styles.searchResults} role='list'>
          {options.map((option) => {
            const { network, code, asset, Asset } = option;

            return (
              <BaseButton
                key={`${code}${network}${Asset!.name}${asset}`}
                className={styles.button}
                onClick={() => onOptionClick(option)}
              >
                {!!Asset?.payload?.icons?.svg ? (
                  <img
                    className={styles.buttonAssetIcon}
                    src={Asset.payload.icons.svg}
                    alt=''
                  />
                ) : (
                  <AssetIcon className={styles.buttonAssetIcon} />
                )}

                <span className={styles.buttonContent}>
                  <Typography className={styles.buttonText} variant='subtitle5'>
                    {asset}
                  </Typography>

                  {!!network && (
                    <Tag className={styles.buttonContentTag} variant='common'>
                      {network}
                    </Tag>
                  )}
                </span>
              </BaseButton>
            );
          })}
        </div>
      );
    },
  }
);

export { SelectCurrency };
