import type Decimal from 'decimal.js-light';
import type { Event } from 'effector';
import { attach, combine, createStore, restore, sample } from 'effector';
import { createGate } from 'effector-react';
import { not, throttle } from 'patronum';

import {
  createDynamicTimer,
  listen,
  modelFactory,
  setState,
} from '@kuna-pay/utils/effector';
import { atom, bridge } from '@kuna-pay/utils/misc';
import { AssetParser } from '@kuna-pay/core/entities/asset';

import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';

import { findPublicInvoiceByIdFx } from './api';
import { ChoosePaymentMethod, ProgressModel, SendReceiptModel } from './model';
import {
  PAYMENT_AWAITING_REFETCH_TIMEOUT_IN_MILLISECONDS,
  PAYMENT_AWAITING_REFETCH_TIMEOUT_IN_SECONDS,
} from './page.config';

const CheckoutPageModel = modelFactory(() => {
  const PageGate = createGate<{ id: string }>();

  const $invoiceId = combine(PageGate.state, (params) => params.id ?? null);
  const $loaded = createStore(false);

  const getInvoiceInfoFx = attach({
    source: PageGate.state,
    effect: findPublicInvoiceByIdFx,
  });

  const getInitialInvoiceInfoFx = attach({ effect: getInvoiceInfoFx });

  const $invoice = restore(getInvoiceInfoFx, null);

  const $status = combine($invoice, (data) => data?.status ?? null);

  const $$chosePaymentMethod = ChoosePaymentMethod.createModel({
    getInvoiceInfoFx,

    $invoiceId,

    $invoiceStatus: $status,
  });

  listen({
    clock: PageGate.open,
    handler: async () => {
      $$chosePaymentMethod.load();

      await getInitialInvoiceInfoFx().finally(() => {
        $$chosePaymentMethod.onInvoiceLoaded();
        setState($loaded, true);
      });
    },
  });

  const $$paymentAwaiting = atom(() => {
    const $$timer = createDynamicTimer();

    bridge(() => {
      sample({
        clock: sample({
          clock: onlyInPaymentAwaitingInvoiceStatus(getInvoiceInfoFx.doneData),
          fn: ({ expireAt }) => expireAt,
        }),
        filter: Boolean,
        fn: (expireAt) => ({ endsAt: new Date(expireAt) }),
        target: $$timer.init,
      });
    });

    bridge(() => {
      sample({
        clock: sample({
          clock: onlyInPaymentAwaitingInvoiceStatus($$timer.tick),

          //Once in {PAYMENT_AWAITING_REFETCH_TIMEOUT_IN_SECONDS} second
          filter: ({ timeLeft }) =>
            timeLeft % PAYMENT_AWAITING_REFETCH_TIMEOUT_IN_SECONDS === 0,
        }),

        filter: not(getInvoiceInfoFx.pending),

        target: getInvoiceInfoFx,
      });
    });

    bridge(() => {
      sample({
        clock: throttle({
          source: onlyInPaymentAwaitingInvoiceStatus($$timer.finally),
          timeout: PAYMENT_AWAITING_REFETCH_TIMEOUT_IN_MILLISECONDS,
        }),
        filter: not(getInitialInvoiceInfoFx.pending),
        //Calls initialLoad bcs status should change
        target: getInitialInvoiceInfoFx,
      });
    });

    function onlyInPaymentAwaitingInvoiceStatus<EventPayload>(
      event: Event<EventPayload>
    ) {
      return sample({
        clock: event,
        filter: combine(
          $status,
          (status) => status === InvoiceStatus.PaymentAwaiting
        ),
      });
    }

    return { $$timer, $$ui: { $$timer } };
  });

  /**
   * @see KUPAY-1783
   */
  const $paymentCostHigherThanFee = combine(
    $invoice,
    (
      invoice
    ):
      | { isOutOfLimit: true; minAmount: Decimal }
      | { isOutOfLimit: false; minAmount: null } => {
      if (
        !invoice ||
        // We don't have paymentAmount if none of payment methods are selected
        invoice.status === InvoiceStatus.Created ||
        !invoice.paymentAmount
      )
        return {
          isOutOfLimit: false,
          minAmount: null,
        };

      const expectedToPay = AssetParser.parseAmount(invoice.paymentAmount);
      const expectedCostForTransaction = AssetParser.parseAmount(
        invoice.paymentFee
      );

      if (expectedToPay.greaterThan(expectedCostForTransaction)) {
        return {
          minAmount: null,
          isOutOfLimit: false,
        };
      }

      return {
        minAmount: expectedCostForTransaction,
        isOutOfLimit: true,
      };
    }
  );

  return {
    $$ui: {
      PageGate,

      $invoiceId,
      $status,
      $invoice,

      $isLoaded: $loaded,
      $isLoading: getInitialInvoiceInfoFx.pending,
      getInitialInvoiceInfoFx,

      $$chosePaymentMethod: {
        ...$$chosePaymentMethod.$$ui,
        $paymentCostHigherThanFee,
      },

      $$paymentAwaiting: $$paymentAwaiting.$$ui,

      $$orderDetails: {
        $$sendReceipt: SendReceiptModel.createModel({ $invoiceId }),
      },

      $$progress: ProgressModel.createModel($invoice),
    },
  };
});

export { CheckoutPageModel };
