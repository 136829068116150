import { captureException, withScope } from '@sentry/react';

import { objectKeys } from '@kuna-pay/utils/typescript';

import { QuestionnaireFieldType } from '@kuna-pay/accept-payment/generated/graphql';
import type { QuestionnaireCompletedEvent as QuestionnaireCompletedAnalyticsEvent } from '@kuna-pay/accept-payment/shared/analytics';
import { $$analytics } from '@kuna-pay/accept-payment/shared/analytics';

class QuestionnaireCompletedEvent {
  public async logEvent(values: Record<string, string>): Promise<void> {
    try {
      await $$analytics.logEvent({
        event: 'Questionnaire Completed',
        properties: objectKeys(values).reduce(
          (properties, [key]) => {
            const formKey = key as QuestionnaireFieldType;

            switch (formKey) {
              case QuestionnaireFieldType.Email: {
                properties.hasEmailField = true;

                return properties;
              }

              case QuestionnaireFieldType.FirstName: {
                properties.hasFirstNameField = true;

                return properties;
              }

              case QuestionnaireFieldType.LastName: {
                properties.hasLastNameField = true;

                return properties;
              }

              case QuestionnaireFieldType.BillingAddress: {
                properties.hasAddressField = true;

                return properties;
              }

              case QuestionnaireFieldType.Text: {
                properties.hasCustomTextField = true;

                return properties;
              }

              default: {
                const _: never = formKey;

                return properties;
              }
            }
          },
          {
            hasAddressField: false,
            hasEmailField: false,
            hasFirstNameField: false,
            hasLastNameField: false,
            hasCustomTextField: false,
          } as QuestionnaireCompletedAnalyticsEvent['properties']
        ),
      });
    } catch (e) {
      withScope((scope) => {
        scope.setExtra('values', values);
        captureException(e);
      });
    }
  }
}

export { QuestionnaireCompletedEvent };
