import { combine, createEffect, createStore } from 'effector';

import { call, setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import type { Feature } from '@kuna-pay/core/generated/public/graphql';

import { nonAuthFeatures } from '../api';

const $$featureFlags = atom(() => {
  const $list = createStore<Feature[]>([], {
    name: '$$feature-flags.$staticFeatures.$list',
  });

  const loadFx = createEffect({
    name: '$$feature-flags.$staticFeatures.loadFx',
    handler: async () => {
      const features = await call(
        nonAuthFeatures({ name: true, value: true }),
        void 0
      );

      setState($list, features);

      return Object.fromEntries(
        features.map((feature) => [feature.name, feature.value])
      );
    },
  });

  const $kv = combine($list, (features) =>
    Object.fromEntries(features.map((feature) => [feature.name, feature.value]))
  );

  return {
    loadStaticFeaturesFx: loadFx,

    $features: $kv,
    $list: $list,
  };
});

export { $$featureFlags };
