import clsx from 'clsx';
import Decimal from 'decimal.js-light';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { positive } from '@kuna-pay/utils/decimal';
import type { DescriptionDetailsPropertiesRootProps } from '@kuna-pay/ui/components/description-details';
import { DescriptionDetails } from '@kuna-pay/ui/components/description-details';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { AssetParser, useAssetFormat } from '@kuna-pay/core/entities/asset';

import { CheckoutPageModel } from '@kuna-pay/accept-payment/pages/checkout/page.model';

import styles from './amounts.module.scss';

const InvoiceAmounts = {
  Root: ({ className, ...props }: DescriptionDetailsPropertiesRootProps) => (
    <DescriptionDetails.Properties.Root
      className={clsx(styles.root, className)}
      {...props}
      color='white'
    />
  ),

  AmountLeft: () => {
    const $$model = CheckoutPageModel.useModel();

    const { t } = useTranslation();
    const AssetFormat = useAssetFormat();

    const { rate, paymentAmount, paidAmount, PaymentAsset, InvoiceAsset } =
      useUnit($$model.$invoice)!;

    const amountLeft = new Decimal(
      AssetParser.toParsedWithPrecision(
        new Decimal(paymentAmount).sub(paidAmount),

        PaymentAsset!,

        { round: 'up' }
      )
    );

    return (
      <DescriptionDetails.Properties.Row
        className={clsx(styles.amountLeft, styles.row)}
      >
        <DescriptionDetails.Properties.Title
          className={styles.amountLeftTitle}
          variant='subtitle2'
          nowrap={false}
        >
          {t('entities.invoice.ui.amounts.amount-left.label')}:
        </DescriptionDetails.Properties.Title>

        <DescriptionDetails.Properties.Value className={styles.amountLeftValue}>
          <Typography variant='subtitle2'>
            {AssetFormat.formatAmount(
              positive(amountLeft),

              PaymentAsset!
            )}
          </Typography>

          <Typography variant='numbers2'>
            ≈
            {AssetFormat.formatAmount(
              positive(amountLeft.mul(rate)),
              InvoiceAsset,

              { round: 'up', includeCode: true }
            )}
          </Typography>
        </DescriptionDetails.Properties.Value>
      </DescriptionDetails.Properties.Row>
    );
  },

  ReceivedAmount: () => {
    const $$model = CheckoutPageModel.useModel();

    const { t } = useTranslation();
    const AssetFormat = useAssetFormat();

    const { rate, paidAmount, PaymentAsset, InvoiceAsset } = useUnit(
      $$model.$invoice
    )!;

    return (
      <DescriptionDetails.Properties.Row
        className={clsx(styles.receivedAmount, styles.row)}
      >
        <DescriptionDetails.Properties.Title variant='subtitle5' nowrap={false}>
          {t('entities.invoice.ui.amounts.received-amount.label')}:
        </DescriptionDetails.Properties.Title>

        <DescriptionDetails.Properties.Value
          className={styles.receivedAmountValue}
        >
          <Typography variant='subtitle2'>
            {AssetFormat.formatAmount(paidAmount, PaymentAsset!)}
          </Typography>

          <Typography variant='numbers2'>
            ≈
            {AssetFormat.formatAmount(
              positive(AssetParser.parseAmount(paidAmount).mul(rate)),
              InvoiceAsset,
              { round: 'up', includeCode: true }
            )}
          </Typography>
        </DescriptionDetails.Properties.Value>
      </DescriptionDetails.Properties.Row>
    );
  },

  PaymentAmount: () => {
    const $$model = CheckoutPageModel.useModel();

    const AssetFormat = useAssetFormat();
    const { t } = useTranslation();

    const { paymentAmount, invoiceAmount, PaymentAsset, InvoiceAsset } =
      useUnit($$model.$invoice)!;

    return (
      <DescriptionDetails.Properties.Row
        className={clsx(styles.paymentAmount, styles.row)}
      >
        <DescriptionDetails.Properties.Title variant='subtitle5' nowrap={false}>
          {t('entities.invoice.ui.amounts.payment-amount.label')}:
        </DescriptionDetails.Properties.Title>

        <DescriptionDetails.Properties.Value
          className={styles.paymentAmountValue}
        >
          <Typography variant='subtitle2'>
            {AssetFormat.formatAmount(paymentAmount, PaymentAsset!)}
          </Typography>

          <Typography variant='numbers2'>
            ≈{AssetFormat.formatAmount(invoiceAmount, InvoiceAsset)}
          </Typography>
        </DescriptionDetails.Properties.Value>
      </DescriptionDetails.Properties.Row>
    );
  },
};

export { InvoiceAmounts };
