import clsx from 'clsx';
import { QRCodeSVG } from 'qrcode.react';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoIcon, WarningOutlineIcon } from '@kuna-pay/ui/icons';
import { CopyButton } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';
import type {
  Maybe,
  PublicProductOutput,
} from '@kuna-pay/core/generated/public/graphql';

import { Warning } from '@kuna-pay/accept-payment/shared/ui/warning';

import type { PaymentMethodAddressAmountProps } from './payment-method-address-amount.component';
import { PaymentMethodAddressAmount } from './payment-method-address-amount.component';
import styles from './payment-method-address.module.scss';

type PaymentMethodAddressProps = PaymentMethodAddressAmountProps &
  PropsWithChildren & {
    address: string;
    companyAvatar: string | null | undefined;
    network: Maybe<string> | null | undefined;
    memo: Maybe<string> | null | undefined;
    Product: Pick<PublicProductOutput, 'minAmount'>;

    showScanQRCta?: boolean;
  };

const PaymentMethodAddress = memo(
  ({
    className,
    address,
    memo,
    network,
    companyAvatar,
    Product,
    paymentAmount,
    equivalentAmount,
    PaymentAsset,
    EquivalentAsset,
    children,

    showScanQRCta,
  }: PaymentMethodAddressProps) => {
    const { t } = useTranslation();

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.rect}>
          <PaymentMethodAddressAmount
            className={styles.invoiceAmount}
            paymentAmount={paymentAmount}
            equivalentAmount={equivalentAmount}
            PaymentAsset={PaymentAsset}
            EquivalentAsset={EquivalentAsset}
          />

          {!!showScanQRCta && (
            <Typography className={styles.qrCta} variant='subtitle5'>
              <Trans
                t={t}
                i18nKey='entities.payment-method.address.cta'
                components={{ body3: <Typography variant='body3' /> }}
              />
            </Typography>
          )}

          <PaymentMethodAddressQR
            address={address}
            companyAvatar={companyAvatar}
          />

          {children}

          <div className={styles.warnings}>
            <PaymentMethodAddressNetworkFeeWarning network={network} />

            <PaymentMethodAddressMinDepositAmountWarning
              PaymentAsset={PaymentAsset}
              Product={Product}
            />
          </div>

          <PaymentMethodAddressDetails address={address} memo={memo} />
        </div>

        <PaymentMethodAddressMemoWarning memo={memo} />
      </div>
    );
  }
);

// ===============================

type PaymentMethodAddressQRProps = Pick<
  PaymentMethodAddressProps,
  'address' | 'companyAvatar'
>;

const PaymentMethodAddressQR = memo(
  ({ address, companyAvatar }: PaymentMethodAddressQRProps) => (
    <div className={styles.qr}>
      <QRCodeSVG
        size={150}
        value={address}
        imageSettings={{
          src: companyAvatar ?? '/kuna-qr-default-logo.jpg',
          height: 45,
          width: 45,
          excavate: false,
        }}
      />
    </div>
  )
);

// ===============================

type PaymentMethodAddressNetworkFeeWarningProps = Pick<
  PaymentMethodAddressProps,
  'network'
>;

const PaymentMethodAddressNetworkFeeWarning = memo(
  ({ network }: PaymentMethodAddressNetworkFeeWarningProps) => {
    const { t } = useTranslation();

    return (
      <div className={styles.networkFeeWarning}>
        <WarningOutlineIcon />

        <Typography variant='numberAdmin2'>
          <Trans
            t={t}
            i18nKey='entities.payment-method.address.warnings.network-fee'
            values={{
              network,
            }}
            components={{
              s4: <Typography variant='subtitle4' />,
            }}
          />
        </Typography>
      </div>
    );
  }
);

// ===============================

type PaymentMethodAddressMinDepositAmountWarningProps = Pick<
  PaymentMethodAddressProps,
  'Product' | 'PaymentAsset'
>;

const PaymentMethodAddressMinDepositAmountWarning = memo(
  ({
    Product,
    PaymentAsset,
  }: PaymentMethodAddressMinDepositAmountWarningProps) => {
    const { t } = useTranslation();
    const AssetFormat = useAssetFormat();

    return (
      <>
        {isMinDepositAmountWarningCanBeApplied(Product) && (
          <div className={styles.minDepositWarning}>
            <InfoIcon />

            <Typography variant='numberAdmin2'>
              <Trans
                t={t}
                i18nKey='entities.payment-method.address.warnings.min-deposit'
                values={{
                  amount: AssetFormat.formatAmount(
                    Product.minAmount,
                    PaymentAsset
                  ),
                }}
                components={{
                  numberAdmin3: <Typography variant='numberAdmin3' />,
                }}
              />
            </Typography>
          </div>
        )}
      </>
    );
  }
);

function isMinDepositAmountWarningCanBeApplied(
  Product?: Maybe<Pick<PublicProductOutput, 'minAmount'>>
): Product is Pick<PublicProductOutput, 'minAmount'> & { minAmount: string } {
  return !!Product?.minAmount && Product.minAmount !== '0';
}

// ===============================

type PaymentMethodAddressDetailsProps = Pick<
  PaymentMethodAddressProps,
  'address' | 'memo'
>;

const PaymentMethodAddressDetails = memo(
  ({ address, memo }: PaymentMethodAddressDetailsProps) => {
    const { t } = useTranslation();

    return (
      <div className={styles.details}>
        <div className={styles.detailsRow}>
          <Typography className={styles.detailsRowLabel} variant='numbers1'>
            {t('entities.payment-method.address.wallet.label')}
          </Typography>

          <CopyButton
            classes={{
              root: clsx(styles.detailsRowValueContainer, styles.address),
            }}
            value={address}
            customMessage={t(
              'entities.payment-method.address.wallet.copy-message'
            )}
            size='lg'
          >
            <Typography variant='subtitle5'>{address}</Typography>
          </CopyButton>
        </div>

        {!!memo && (
          <div className={styles.detailsRow}>
            <Typography className={styles.detailsRowLabel} variant='numbers1'>
              {t('entities.payment-method.address.memo.label')}
            </Typography>

            <CopyButton
              classes={{
                root: styles.detailsRowValueContainer,
                button: styles.copyButton,
              }}
              value={memo}
              customMessage={t(
                'entities.payment-method.address.memo.copy-message'
              )}
              size='lg'
            >
              <Typography
                className={styles.detailsRowValue}
                variant='subtitle5'
                nowrap
              >
                {memo}
              </Typography>
            </CopyButton>
          </div>
        )}
      </div>
    );
  }
);

// ===============================

type PaymentMethodAddressMemoWarningProps = Pick<
  PaymentMethodAddressProps,
  'memo'
>;

const PaymentMethodAddressMemoWarning = memo(
  ({ memo }: PaymentMethodAddressMemoWarningProps) => {
    const { t } = useTranslation();

    return (
      <>
        {!!memo && (
          <Warning
            className={styles.memo}
            title={t('entities.payment-method.address.memo.warning.title')}
          >
            {t('entities.payment-method.address.memo.warning.description')}
          </Warning>
        )}
      </>
    );
  }
);

export type { PaymentMethodAddressProps };
export { PaymentMethodAddress };
