import { createEffect } from 'effector';

import { ASSET_SELECT } from '@kuna-pay/core/entities/asset';

import { PaymentMethodComparator } from '@kuna-pay/accept-payment/entities/payment-method';
import type {
  PublicAssetOutput,
  PublicPaymentMethodOutput,
} from '@kuna-pay/accept-payment/generated/graphql';
import { findPublicPaymentMethodsToPayInvoice } from '@kuna-pay/accept-payment/shared/api/generated/Invoice/request/findPublicPaymentMethodsToPayInvoice';

type PaymentMethodOption = Pick<
  PublicPaymentMethodOutput,
  'code' | 'network' | 'asset'
> & {
  Asset?: Pick<PublicAssetOutput, 'name' | 'payload'> | null;
  Product?: { maxAmount?: string | null; minAmount?: string | null } | null;
};

type PaymentMethod = Pick<PaymentMethodOption, 'network' | 'code' | 'asset'> & {
  name: NonNullable<PaymentMethodOption['Asset']>['name'];
  icon: string;
};

const findPaymentMethodsFx = createEffect({
  handler: async (params: { id: string }): Promise<PaymentMethodOption[]> => {
    const paymentMethods = await findPublicPaymentMethodsToPayInvoice({
      code: true,
      network: true,
      position: true,
      asset: true,
      Product: {
        maxAmount: true,
        minAmount: true,
      },
      Asset: ASSET_SELECT,
    })(params);

    paymentMethods.sort(PaymentMethodComparator.ASC);

    return paymentMethods;
  },
});

export type { PaymentMethod, PaymentMethodOption };
export { findPaymentMethodsFx };
