import type { Effect, EffectParams, EffectResult } from 'effector';
import { attach, createEffect, createEvent, createStore } from 'effector';

import { bridge } from '../misc';

type EffectDonePayload<FX extends Effect<any, any>> = {
  params: EffectParams<FX>;
  result: EffectResult<FX>;
};
type EffectFailPayload<FX extends Effect<any, any>> = {
  params: EffectParams<FX>;
  error: EffectResult<FX>;
};

type EffectPayload<FX extends Effect<any, any>> =
  | EffectDonePayload<FX>
  | EffectFailPayload<FX>;

const createEffectWithLastParams = <Params, Done, Fail = Error>(
  fn: (params: Params) => Promise<Done>
) => {
  const reset = createEvent();

  const executeFx = createEffect<Params, Done, Fail>(fn);

  const $lastParams = createStore<Params>(null as Params);

  bridge(() => {
    $lastParams.on(executeFx, (_, params) => params);
  });

  bridge(() => {
    $lastParams.reset(reset);
  });

  return {
    $lastParams,
    fx: executeFx,
    retryFx: attach({ source: $lastParams, effect: executeFx }),
    reset,
  };
};

export type { EffectDonePayload, EffectFailPayload, EffectPayload };
export { createEffectWithLastParams };
