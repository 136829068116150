import clsx from 'clsx';

import { Loader } from '@kuna-pay/accept-payment/shared/ui/loader';

import { ReactComponent as ReceiveIcon } from '../../../assets/receive.svg';
import { ReactComponent as StepperConnector } from '../../../assets/stepper-connector.svg';
import styles from './confirmation-awaiting.stepper.module.scss';

const ConfirmationAwaitingStepper = {
  StepCircle: () => (
    <div className={styles.container}>
      <div className={styles.circle} />
    </div>
  ),

  Connector: () => <StepperConnector className={styles.connector} />,

  StepRateCircle: ({ loading }: { loading?: boolean }) =>
    loading ? (
      <Loader className={clsx(styles.container, styles.rateLoading)} />
    ) : (
      <ReceiveIcon className={styles.container} />
    ),
};

export { ConfirmationAwaitingStepper };
