import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '@kuna-pay/ui/router';
import { Typography } from '@kuna-pay/ui/ui/typography';
import { urls } from '@kuna-pay/core/shared/config';
import { KUNALogo } from '@kuna-pay/core/shared/ui/logo';

import { ReactComponent as ChainAnalysisLogo } from './chain-analysis.logo.svg';
import styles from './footer.module.scss';

type FooterProps = HTMLAttributes<HTMLDivElement> & {};

const Footer = memo(({ className, children, ...props }: FooterProps) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'shared.ui.footer',
  });

  return (
    <footer className={clsx(styles.footer, className)} {...props}>
      <ExternalLink
        className={styles.poweredBy}
        href={urls.landings.kunaPay.withLocale(i18n.language)}
      >
        <Typography variant='numberAdmin1' nowrap>
          {t('powered-by')}
        </Typography>

        <KUNALogo mainIconProps={{ width: 90 }} height={28} />
      </ExternalLink>

      <ExternalLink
        className={styles.checkedBy}
        href={urls.services.chainAnalysis}
      >
        <Typography variant='numberAdmin1' nowrap>
          {t('checked-by')}
        </Typography>

        <ChainAnalysisLogo width={112} height={28} />
      </ExternalLink>

      <div className={styles.privacy}>
        <ExternalLink href={urls.privacyPolicy.withLocale(i18n.language)}>
          <Typography variant='numberAdmin1' nowrap>
            {t('privacy-policy')}
          </Typography>
        </ExternalLink>

        {children}
      </div>
    </footer>
  );
});

export { Footer };
