import { createEvent, sample } from 'effector';
import { delay } from 'patronum';

import { modelFactory } from '@kuna-pay/utils/effector';
import type { FieldModel, FormModel } from '@kuna-pay/form';

import type { OptionsLoader } from './loader';
import {
  createAsyncLoader,
  createOnceLoader,
  createSyncLoader,
} from './loader';
import { SelectOptionsFactory } from './options.model';
import { SelectRootFactory } from './root.model';
import { SelectSearchFactory } from './search.model';
import type { ExternalMultiValueSource } from './value';
import { createExternalMultiValue, createMultiValueModel } from './value';

type MultiSelectModelConfig = {
  $$loader: OptionsLoader;

  $$value?: ExternalMultiValueSource;

  options?: {
    closeOnSelect?: boolean;
  };
};

const MultiSelectModel = Object.assign(
  modelFactory(
    ({
      $$loader,
      options = { closeOnSelect: false },
      ...config
    }: MultiSelectModelConfig) => {
      const reset = createEvent();

      const $$root = SelectRootFactory.createModel();

      const $$search = SelectSearchFactory.createModel();

      const $$options = SelectOptionsFactory.createModel({
        $$loader,
        $searchQuery: $$search.$query,
      });

      const $$value = config.$$value
        ? createExternalMultiValue({ $$value: config.$$value })
        : createMultiValueModel();

      if (options?.closeOnSelect) {
        sample({
          clock: $$value.changed,
          target: $$root.close,
        });
      }

      sample({
        clock: $$root.closed,
        target: $$search.clear,
      });

      sample({
        clock: $$root.opened,
        target: $$value.focus,
      });

      sample({
        clock: $$root.closed,
        target: $$value.blur,
      });

      sample({
        clock: reset,
        target: [
          $$search.reset,
          $$options.reset,
          $$root.reset,
          $$loader.reset,
          $$value.reset,
        ],
      });

      return {
        $$load: createOnceLoader($$loader),
        $$value,

        $$list: {
          $value: $$value.$value,
          clear: $$value.clear,
          remove: $$value.$$ui.remove,
        },

        changed: delay({ source: $$value.changed, timeout: 250 }),
        reset,

        $$ui: {
          $$root: $$root,
          $$search: $$search,
          $$options: $$options,
          $$value: $$value.$$ui,

          $$state: {
            $options: $$options.$state,
          },
        },

        __: {
          $$root: $$root.__,
          $$search: $$search.__,
          $$options: $$options.__,
          $$value: $$value.__,
        },
      };
    }
  ),
  {
    Value: {
      fromField: function fromMultiField(
        field: FieldModel<string[]>,
        $$form: FormModel<any>
      ): ExternalMultiValueSource {
        return {
          $value: field.$value,

          change: field.change,

          focus: $$form.focused.prepend(() => [field.path]),
          blur: $$form.blured.prepend(() => [field.path]),
        };
      },
    },

    Loader: {
      createSyncLoader,
      createAsyncLoader,
    },
  }
);

export { MultiSelectModel };
