import type { NonAuthInvoiceDetailsOutput } from '@kuna-pay/accept-payment/generated/graphql';

import type { PaymentMethod, PaymentMethodOption } from './api';

class PaymentMethodAdapter {
  public static fromPaymentMethodOption({
    code,
    asset,
    Asset,
    network,
  }: PaymentMethodOption) {
    return {
      code,
      name: Asset!.name,
      icon: (Asset!.payload?.icons?.svg ?? '') as string,
      network,
      asset,
    };
  }

  public static fromPublicInvoiceOutput(
    dto: NonAuthInvoiceDetailsOutput
  ): PaymentMethod | null {
    if (!dto?.PaymentMethod || !dto.PaymentAsset) return null;

    const {
      PaymentAsset: { name, payload },
      PaymentMethod: { code, asset, network },
    } = dto; // PublicInvoiceOutput;

    return {
      code,
      name,
      icon: (payload?.icons?.svg ?? '') as string,
      network,
      asset,
    };
  }
}

export { PaymentMethodAdapter };
