import clsx from 'clsx';
import { memo } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';
import { Company24Icon } from '@kuna-pay/ui/icons';
import type { AvatarProps } from '@kuna-pay/ui/ui/avatar';
import { Avatar } from '@kuna-pay/ui/ui/avatar';

import styles from './avatar.module.scss';

type MerchantAvatarProps = PartialField<AvatarProps, 'size'>;

const MerchantAvatar = memo(
  ({ size, className, ...props }: MerchantAvatarProps) => (
    <Avatar
      className={clsx(styles.avatar, className)}
      size={size ?? 'xxxl'}
      variant={props.src ? 'img' : 'icon'}
      {...props}
    >
      <Company24Icon fontSize={24} />
    </Avatar>
  )
);

export { MerchantAvatar };
