function capitalize(str = '') {
  if (!str) return str;

  return str.substring(0, 1).toUpperCase() + str.substring(1);
}

function capitalizeSnakeCase(str = '') {
  if (!str) return str;

  return capitalize(str.split('_').join(' '));
}

export { capitalize, capitalizeSnakeCase };
