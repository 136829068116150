import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@kuna-pay/ui/ui/dropdown';
import { Skeleton } from '@kuna-pay/ui/ui/skeleton';
import { CrowdinInContextConfig } from '@kuna-pay/core/shared/i18n';

import { $$crowdinInContextFeatureFlag } from '@kuna-pay/accept-payment/shared/feature-flags/features';
import { $$i18n, I18nConfig } from '@kuna-pay/accept-payment/shared/i18n';

import { $$changeLanguage } from './change-language.model';
import styles from './change-language.module.scss';

const ChangeLanguageView = memo(() => {
  const { i18n } = useTranslation();
  const [languages, changingLanguageTo, changeLanguage] = useUnit([
    $$i18n.$supportedLanguages,
    $$changeLanguage.$$ui.$changingLanguageTo,
    $$changeLanguage.$$ui.changeLanguage,
  ]);
  const crowdinInContextFF = useUnit($$crowdinInContextFeatureFlag);

  // If we only have one language, we don't need to show the dropdown
  if (languages.length === 1) {
    return null;
  }

  if (!i18n.resolvedLanguage) {
    return <Skeleton height={24} width={70} />;
  }

  let visibleLanguage = changingLanguageTo ?? i18n.resolvedLanguage;

  if (crowdinInContextFF.enabled) {
    if (visibleLanguage === CrowdinInContextConfig.PSEUDO_LANGUAGE) {
      visibleLanguage = I18nConfig.FALLBACK_LANGUAGE;
    }
  }

  return (
    <Dropdown
      className={styles.dropdown}
      classes={{
        triggerText: styles.triggerText,
      }}
      content={
        <Dropdown.Content align='end' shadow>
          <div className={styles.list}>
            {languages.map((language) => (
              <Dropdown.Item
                className={styles.listButton}
                key={language}
                onClick={() => {
                  changeLanguage(language);
                }}
              >
                <Dropdown.ItemText variant='numbers1' className='text-black900'>
                  {language.toUpperCase()}
                </Dropdown.ItemText>
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Content>
      }
      variant='numbers1'
    >
      <>{visibleLanguage.toUpperCase()}</>
    </Dropdown>
  );
});

export { ChangeLanguageView };
