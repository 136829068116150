import { combine, createEvent, createStore, sample } from 'effector';
import { condition } from 'patronum';

import { atom } from '@kuna-pay/utils/misc';

import type { ValueState } from './value.declaration';

const createMultiValueModel = (config: { value?: string[] } = {}) => {
  const change = createEvent<string>();
  const clear = createEvent();

  const add = createEvent<string>();
  const remove = createEvent<string>();

  const reset = createEvent();

  const $value = createStore<string[]>(config.value ?? []);

  const { $focused, blur, focus } = atom(() => {
    const focus = createEvent();
    const blur = createEvent();

    const $focused = createStore(false)
      .on(focus, () => true)
      .on(blur, () => false);

    return {
      $focused,
      focus,
      blur,
    };
  });

  condition({
    source: sample({
      clock: change,
      source: $value,
      fn: (state, value) => ({ state, value }),
    }),

    if: ({ state, value }) => state.includes(value),

    then: remove.prepend(
      ({ value }: { state: string[]; value: string }) => value
    ),

    else: add.prepend(({ value }: { state: string[]; value: string }) => value),
  });

  $value
    .on(clear, () => [])
    .on(add, (state, value) => [...state, value])
    .on(remove, (state, value) => state.filter((item) => item !== value))
    .reset(reset);

  return {
    $value,
    clear,
    changed: $value.updates,
    focus,
    blur,
    reset,

    $$ui: {
      $value,
      $focused,
      change,
      add,
      remove,

      $state: combine(
        $value,
        (value): ValueState => (value.length > 0 ? 'filled' : 'empty')
      ),
    },

    __: {},
  };
};

export { createMultiValueModel };
