import { createContext, useMemo } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import type { ChildrenProps } from '@kuna-pay/ui/components/select/lib';

import type { FormControlSize, FormControlState } from './form-control.core';
import {
  FormControlFooter,
  FormControlHeader,
  FormControlHelderText,
  FormControlLabel,
  FormControlRoot,
  FormControlText,
} from './form-control.core';

const FormControlStateContext = createContext<FormControlState>('idle');

const FormControl = createCompoundComponent(
  ({ useControlState, Footer, Header, Root }) =>
    ({
      className,
      children,
      size,
      label,
      helperText,
      focused,
      hasValue,
      disabled,
      hasError,
      alwaysShowLabel,
      variant,
      hideLabel,
    }: ChildrenProps<FormControlState> & {
      className?: string;
      size: FormControlSize;
      variant?: 'filled' | 'outlined';

      focused?: boolean;
      hasValue?: boolean;
      hasError?: boolean;
      disabled?: boolean;

      label?: string;
      helperText?: string;
      alwaysShowLabel?: boolean;
      hideLabel?: boolean;
    }) => {
      const state = useControlState({ disabled, hasError, focused });

      const shouldShowLabel =
        !!focused || !!hasValue || !!hasError || !!alwaysShowLabel;

      return (
        <FormControlStateContext.Provider value={state}>
          <Root className={className} size={size}>
            {!hideLabel && (
              <Header size={size}>
                <Header.Label
                  size={size}
                  hidden={!shouldShowLabel}
                  focused={focused}
                  disabled={disabled}
                  variant={variant}
                  error={hasError}
                >
                  {label}
                </Header.Label>

                <Header.End size={size} />
              </Header>
            )}

            {typeof children === 'function' ? children(state) : children}

            {helperText && (
              <Footer size={size}>
                <Footer.HelperText size={size} hasError={hasError}>
                  {helperText}
                </Footer.HelperText>
              </Footer>
            )}
          </Root>
        </FormControlStateContext.Provider>
      );
    },
  {
    useControlState: ({
      focused,
      disabled,
      hasError,
    }: {
      focused?: boolean;
      hasError?: boolean;
      disabled?: boolean;
    }) =>
      useMemo((): FormControlState => {
        if (focused) return 'focus';

        if (disabled) return 'disabled';

        if (hasError) return 'error';

        return 'idle';
      }, [focused, disabled, hasError]),

    Root: FormControlRoot,

    Context: FormControlStateContext,

    Header: Object.assign(FormControlHeader, {
      Label: FormControlLabel,
      End: FormControlText,
    }),

    Footer: Object.assign(FormControlFooter, {
      HelperText: FormControlHelderText,
    }),
  }
);

export { FormControl, FormControlStateContext };
