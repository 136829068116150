import * as RadixPopover from '@radix-ui/react-popover';
import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';

import { modelView } from '@kuna-pay/utils/effector';

import { SelectRootFactory } from '../../model';

type DropdownRootProps = Omit<
  RadixPopover.PopoverProps,
  'open' | 'onOpenChange' | 'defaultOpen'
>;

const SelectRoot = Object.assign(
  (props: DropdownRootProps) => {
    const $$root = SelectRootFactory.useModel();
    const [open, onOpenChange] = useUnit([$$root.$open, $$root.change]);

    return (
      <RadixPopover.Root open={open} onOpenChange={onOpenChange} {...props} />
    );
  },
  {
    Provider: modelView(
      SelectRootFactory,
      ({ children }: PropsWithChildren) => <>{children}</>
    ),
  }
);

export { SelectRoot };
