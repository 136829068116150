import type { TagVariant } from '@kuna-pay/ui/ui/tag';
import { ConvertStatus } from '@kuna-pay/core/generated/public/graphql';

class AutoConversionStatusMapping {
  public static text: Record<ConvertStatus, string> = {
    [ConvertStatus.Created]: 'created',
    [ConvertStatus.Processing]: 'processing',
    [ConvertStatus.Processed]: 'processed',
    [ConvertStatus.Canceled]: 'cancelled',
  };

  public static tagVariant: Record<ConvertStatus, TagVariant> = {
    [ConvertStatus.Created]: 'warning',
    [ConvertStatus.Processing]: 'warning',
    [ConvertStatus.Processed]: 'success',
    [ConvertStatus.Canceled]: 'danger',
  };

  public static color: Record<ConvertStatus, string> = {
    [ConvertStatus.Created]: 'var(--color-yellow600)',
    [ConvertStatus.Processing]: 'var(--color-yellow600)',
    [ConvertStatus.Processed]: 'var(--color-green500)',
    [ConvertStatus.Canceled]: 'var(--color-red500)',
  };
}

export { AutoConversionStatusMapping };
