enum AsyncLoaderOperation {
  Load = 'load',
  LoadMore = 'load-more',
}

type AsyncLoaderParams = {
  params: { skip: number; take: number; search: string };
  operation: AsyncLoaderOperation;
};

type AsyncLoaderDone = { result: string[]; operation: AsyncLoaderOperation };
type AsyncLoaderFail = { operation: AsyncLoaderOperation };

export type { AsyncLoaderDone, AsyncLoaderFail, AsyncLoaderParams };
export { AsyncLoaderOperation };
