import type { CoreAsset } from '../types';

type AssetComparatorEntity = Pick<CoreAsset, 'code'> & { position: number };

class AssetComparator {
  public static compare<T extends AssetComparatorEntity>(a: T, b: T) {
    if (a.position !== b.position) {
      return a.position - b.position;
    }

    return a.code.localeCompare(b.code);
  }
}

export { AssetComparator };
