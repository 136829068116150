import type { Event } from 'effector';
import { createEvent, createStore } from 'effector';
import { and, not } from 'patronum';

import type { OptionsLoader } from './loader.declaration';

type OnceLoader = Pick<
  OptionsLoader['$$load'],
  'start' | 'done' | 'fail' | '$pending'
> & {
  reset: Event<void>;
};

/**
 * Need this to ensure that the initial load will not be shown as pending
 * after clearing the search query
 */
const createOnceLoader = (from: OptionsLoader): OnceLoader => {
  const reset = from.reset ?? createEvent();
  const { start, fail, done, $pending } = from.$$load;

  const $hasLoaded = createStore(false)
    .on(done, () => true)
    .reset(reset);

  return {
    start,
    fail,
    done,
    /**
     * Need this to ensure that the initial load will not be shown as pending
     * after clearing the search query
     */
    $pending: and($pending, not($hasLoaded)),
    reset,
  };
};

export { createOnceLoader };
