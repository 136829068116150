import { useUnit } from 'effector-react';
import type { PropsWithChildren } from 'react';
import { Suspense } from 'react';

import {
  AppErrorScreen,
  AppLoadingScreen,
} from '@kuna-pay/ui/ui/splash-screen';

import { $$boot } from './boot.model';

const AppBoot = ({ children }: PropsWithChildren) => {
  const [isLoading, isError, onReload] = useUnit([
    $$boot.$$ui.$loading,
    $$boot.$$ui.$error,
    $$boot.$$ui.reloadClicked,
  ]);

  if (isLoading) {
    return <AppLoadingScreen />;
  }

  if (isError) {
    return (
      <AppErrorScreen
        onReload={onReload}
        description='We couldn’t load the page, please try again'
      />
    );
  }

  return <Suspense fallback={<AppLoadingScreen />}>{children}</Suspense>;
};

export { AppBoot };
