import type { Effect } from 'effector';
import { attach, combine, createEvent, restore, sample } from 'effector';
import { not } from 'patronum';

import { bridge } from '@kuna-pay/utils/misc';

function createSyncSearch<Option>(config: {
  getOptionsFx: Effect<void, Option[]>;

  match: (option: Option, query: string) => boolean;
}) {
  const getOptionsFx = attach({ effect: config.getOptionsFx });

  //commands
  const load = createEvent();
  const reset = createEvent();
  const clear = createEvent();

  //events
  const changed = createEvent<string>();
  const cleared = createEvent();

  const $options = restore(getOptionsFx, []);
  const $query = restore(changed, '');

  bridge(() => {
    sample({
      clock: load,
      filter: not(getOptionsFx.pending),
      target: getOptionsFx,
    });
  });

  bridge(() => {
    sample({
      clock: clear,
      target: cleared,
    });

    $query.reset(cleared);
  });

  bridge(() => {
    $options.reset(reset);
    $query.reset(reset);
  });

  const $filteredOptions = combine($options, $query, (options, query) => {
    if (!query) return options;

    return options.filter((option) => config.match(option, query));
  });

  return {
    load,
    clear,
    reset,

    $query,

    $$ui: {
      changed,
      cleared,

      $query,
      $options: $filteredOptions,
      $allOptions: $options,

      $loading: getOptionsFx.pending,
    },

    __: {
      getOptionsFx,
    },
  };
}

export { createSyncSearch };
