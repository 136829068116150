import { Monitoring } from '@kuna-pay/core/shared/monitoring';

import { GraphqlClient } from '../api/graphql';
import { environment } from '../config';
import { $$featureFlags } from '../feature-flags';

const $$monitoring = Monitoring.createModel({
  dsn: environment.sentry.dsn,
  environment: environment.sentry.environment,
  release: environment.sentry.release,

  featureFlags: {
    $features: $$featureFlags.$features,
  },

  api: {
    gwUrl: environment.backend.gw.url,

    graphqlClient: () => GraphqlClient.get(),
  },
});

export { $$monitoring };
