import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { forwardRef } from 'react';

import type { AsChild } from '@kuna-pay/ui/components/select/lib';
import type {
  UnstyledInputProps,
  UnstyledTextareaProps,
} from '@kuna-pay/ui/components/unstyled/input';
import {
  UnstyledInput,
  UnstyledTextarea,
} from '@kuna-pay/ui/components/unstyled/input';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './input.module.scss';

type InputSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type InputProps = Omit<UnstyledInputProps, 'size'> &
  AsChild & { size: InputSize };

type TextareProps = UnstyledTextareaProps & AsChild & { size: InputSize };

const TextField = {
  Input: forwardRef<HTMLInputElement, InputProps>(
    ({ asChild, className, size, ...props }, ref) => {
      const Component = asChild ? Slot : UnstyledInput;

      return (
        <Component
          ref={ref}
          className={clsx(
            styles.input,
            {
              [Typography.classes.small2]: size === 'xs',
              [Typography.classes.numbers1]: size === 'sm' || size === 'md',
              [Typography.classes.subtitle5]: size === 'lg' || size === 'xl',
            },
            className
          )}
          {...props}
        />
      );
    }
  ),

  TextArea: forwardRef<HTMLTextAreaElement, TextareProps>(
    ({ asChild, className, size, ...props }, ref) => {
      const Component = asChild ? Slot : UnstyledTextarea;

      return (
        <Component
          ref={ref}
          className={clsx(
            styles.textarea,
            {
              [Typography.classes.small2]: size === 'xs',
              [Typography.classes.numbers1]: size === 'sm',
              [Typography.classes.numbers1]: size === 'md',
              [Typography.classes.subtitle5]: size === 'lg',
              [Typography.classes.subtitle5]: size === 'xl',
            },
            className
          )}
          {...props}
        />
      );
    }
  ),
};

export { TextField };
export type { InputProps, InputSize, TextareProps };
