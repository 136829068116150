import { createFeatureFlag } from '../model';

const $$crowdinInContextFeatureFlag = createFeatureFlag(
  'FE_CHECKOUT_CROWDIN_IN_CONTEXT'
);

const $$isESLangEnabledFeatureFlag = createFeatureFlag(
  'KUPAY_1936_FE_CHECKOUT_ES_LANGUAGE_ENABLED'
);

const $$recaptchaFeatureFlag = createFeatureFlag('RECAPTCHA_ENABLED');

const $$autoSelectIfOnlyOnePaymentMethodFeatureFlag = createFeatureFlag(
  'KUPAY_2703_FE_CHECKOUT_AUTO_SELECT_IF_ONLY_ONE_PAYMENT_METHOD'
);

const $$payForCocktailWithKunaFeatureFlag = createFeatureFlag(
  'KUPAY_2798_FE_CHECKOUT_PAY_FOR_COCKTAIL_WITH_KUNA'
);

const $$payForCocktailWithKrakenFeatureFlag = createFeatureFlag(
  'KUPAY_2798_FE_CHECKOUT_PAY_FOR_COCKTAIL_WITH_KRAKEN'
);

const $$payForCocktailWithTrustWalletFeatureFlag = createFeatureFlag(
  'KUPAY_2798_FE_CHECKOUT_PAY_FOR_COCKTAIL_WITH_TRUSTWALLET'
);

export {
  $$autoSelectIfOnlyOnePaymentMethodFeatureFlag,
  $$crowdinInContextFeatureFlag,
  $$isESLangEnabledFeatureFlag,
  $$payForCocktailWithKrakenFeatureFlag,
  $$payForCocktailWithKunaFeatureFlag,
  $$payForCocktailWithTrustWalletFeatureFlag,
  $$recaptchaFeatureFlag,
};
