import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@kuna-pay/ui/ui/typography';

import emptyListPng from '../../../assets/invoice-deposit/list/empty.png';
import styles from './empty.module.scss';

type EmptyInvoiceDepositListProps = {
  className?: string;
  classes?: {
    root?: string;
    image?: string;
    title?: string;
    subtitle?: string;
  };
};

const EmptyInvoiceDepositList = memo(
  ({ className, classes }: EmptyInvoiceDepositListProps) => {
    const { t } = useTranslation('common', {
      keyPrefix: 'entities.transaction.invoice-deposit.list.empty',
    });

    return (
      <div className={clsx(styles.root, className, classes?.root)}>
        <img
          className={clsx(styles.image, classes?.image)}
          src={emptyListPng}
          width={168}
          height={88}
          alt=''
        />

        <Typography
          as='p'
          className={clsx(styles.title, classes?.title)}
          variant='numberAdmin2'
          center
        >
          {t('title')}
        </Typography>

        <Typography
          as='p'
          className={clsx(styles.subtitle, classes?.subtitle)}
          variant='numberAdmin1'
          center
        >
          {t('subtitle')}
        </Typography>
      </div>
    );
  }
);

export type { EmptyInvoiceDepositListProps };
export { EmptyInvoiceDepositList };
