import { useUnit } from 'effector-react';
import type { FC, ReactNode } from 'react';

import { MultiSelectModel } from '../../model';

type MultiValueProps = {
  children: (value: string[]) => ReactNode;
};

const MultiValue: FC<MultiValueProps> = ({ children }) => {
  const { $$value } = MultiSelectModel.useModel();
  const [value] = useUnit([$$value.$value]);

  return <>{children(value)}</>;
};

export { MultiValue };
