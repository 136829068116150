import { DescriptionDetailsItem } from './item';
import { DescriptionDetailsProperties } from './properties';

const DescriptionDetails = {
  Item: DescriptionDetailsItem,
  Properties: DescriptionDetailsProperties,
};

export { DescriptionDetails };
export type { DescriptionDetailsPropertiesRootProps } from './properties';
