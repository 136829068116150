import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { CheckoutPageModel } from '../../../page.model';
import { AccordionDetails } from '../../shared';
import styles from './product-details.module.scss';

type ProductDetailsProps = { className?: string };

const ProductDetails = ({ className }: ProductDetailsProps) => {
  const $$model = CheckoutPageModel.useModel();

  const { t } = useTranslation(undefined, {
    keyPrefix: 'pages.checkout.choose-payment-method.product-details',
  });

  const { productCategory, productDescription } = useUnit($$model.$invoice)!;

  if (!productCategory && !productDescription) return null;

  return (
    <AccordionDetails className={className} title={t('title')}>
      {!!productCategory && (
        <AccordionDetails.Column>
          <AccordionDetails.Label>{t('category')}</AccordionDetails.Label>

          <AccordionDetails.Value>{productCategory}</AccordionDetails.Value>
        </AccordionDetails.Column>
      )}

      {!!productDescription && (
        <AccordionDetails.Column>
          <AccordionDetails.Label>{t('description')}</AccordionDetails.Label>

          <AccordionDetails.Value
            className={styles.preWrap}
            wordBreak
            nowrap={false}
          >
            {productDescription}
          </AccordionDetails.Value>
        </AccordionDetails.Column>
      )}
    </AccordionDetails>
  );
};

export { ProductDetails };
