import type { IAuthService } from '@kuna/graphql-client/dist/transport/AuthHttpTransport';
import { AuthHttpTransport } from '@kuna/graphql-client/dist/transport/AuthHttpTransport';

import { notify } from '@kuna-pay/ui/notification';
import { NoActiveShardsError } from '@kuna-pay/core/shared/api/errors';
import { KunaPayGraphqlClient } from '@kuna-pay/core/shared/api/http';
import { APIAmplitudeIntegration } from '@kuna-pay/core/shared/api/integrations';
import { AppStage } from '@kuna-pay/core/shared/config';
import { APIRecaptchaIntegration } from '@kuna-pay/core/shared/recaptcha';

import { environment } from '@kuna-pay/accept-payment/shared/config';

import { $$analytics } from '../analytics';
import { $$recaptcha } from '../recaptcha';

class AuthService implements IAuthService {
  private readonly amplitudeIntegration = new APIAmplitudeIntegration(
    $$analytics
  );

  private readonly recaptchaIntegration = new APIRecaptchaIntegration(
    $$recaptcha
  );

  public getStatusCode(item: any) {
    return item?.errors?.[0]?.extensions?.exception?.response?.statusCode;
  }

  //implements

  public async refresh(): Promise<void> {
    return Promise.resolve();
  }

  public async logout(): Promise<void> {
    return Promise.resolve();
  }

  public isUnauthorized(request: unknown): boolean {
    return (
      Array.isArray(request) &&
      request.some(
        (item) =>
          this.getStatusCode(item) === 401 ||
          (item.errors && item.errors[0]?.code === 'UNAUTHORIZED')
      )
    );
  }

  public isAuthRequest(request: unknown): boolean {
    return (
      Array.isArray(request) &&
      request.length >= 1 &&
      !request[0].variables?.__noAuth
    );
  }

  public addHeaders = (headers: Record<string, unknown>): void => {
    this.amplitudeIntegration.addHeaders(headers);
    this.recaptchaIntegration.addHeaders(headers);
  };
}

class GraphqlClient {
  private static instance: KunaPayGraphqlClient;

  public static get(): KunaPayGraphqlClient {
    if (!GraphqlClient.instance) {
      GraphqlClient.instance = new KunaPayGraphqlClient({
        transport: new AuthHttpTransport({
          url: environment.backend.gw.url,
          authService: new AuthService(),
        }),
      });

      if (environment.stage === AppStage.DEV) {
        GraphqlClient.instance.onError().subscribe((error) => {
          if (NoActiveShardsError.match(error)) {
            const noActiveShardsError = NoActiveShardsError.from(error);

            notify.error(noActiveShardsError.messageForNotification);
          }
        });
      }
    }

    return GraphqlClient.instance;
  }
}

const query = <T, D = any>(query: string, variables?: D) =>
  GraphqlClient.get().query<T, D>(query, variables);

const subscription = <T = any>(channel: string): any =>
  GraphqlClient.get().subscription<T>(channel);

export { GraphqlClient, query, subscription };
