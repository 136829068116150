import { createUIBindingsToAmplitudeAnalytics } from '@kuna-pay/core/shared/analytics/amplitude';

import { $$analytics } from './analytics.model';

const {
  useAmplitudeAnalytics: useAnalytics,
  LogAmplitudeEventOnMount: LogEventOnMount,
} = createUIBindingsToAmplitudeAnalytics($$analytics);

export { LogEventOnMount, useAnalytics };
