import { z } from 'zod';

import { AsyncLoaderOperation } from './loader.async.declaration';

const asyncLoaderSchema = z.object({
  result: z.array(z.string()),
  operation: z.nativeEnum(AsyncLoaderOperation),
});

export { asyncLoaderSchema };
