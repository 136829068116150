import { combine, createEvent, createStore, sample } from 'effector';

import { atom } from '@kuna-pay/utils/misc';

import type { ValueState } from './value.declaration';
import type { ExternalMultiValueSource } from './value.multi.declaration';

const createExternalMultiValue = ({
  $$value: { $value, change, ...$$value },
}: {
  $$value: ExternalMultiValueSource;
}) => {
  const clear = createEvent();
  const reset = $$value.reset ?? createEvent();

  const add = createEvent<string>();
  const remove = createEvent<string>();

  const { $focused, blur, focus } = atom(() => {
    const focus = $$value.focus ?? createEvent();
    const blur = $$value.blur ?? createEvent();

    const $focused = createStore(false)
      .on(focus, () => true)
      .on(blur, () => false)
      .reset(reset);

    return {
      $focused,
      focus,
      blur,
    };
  });

  sample({
    clock: add,
    source: $value,
    fn: (values, option) => values.concat(option),
    target: change,
  });

  sample({
    clock: remove,
    source: $value,
    fn: (values, option) => values.filter((value) => value !== option),
    target: change,
  });

  sample({
    clock: clear,
    target: change.prepend(() => []),
  });

  return {
    clear,
    $value,
    reset: reset,
    changed: $value.updates,

    focus: focus,
    blur: blur,

    $$ui: {
      $value,
      $focused,
      change,
      add,
      remove,

      $state: combine(
        $value,
        (value): ValueState => (value.length > 0 ? 'filled' : 'empty')
      ),
    },

    __: {},
  };
};

export { createExternalMultiValue };
