import clsx from 'clsx';
import type { Numeric } from 'decimal.js-light';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@kuna-pay/ui/ui/button';
import { Typography } from '@kuna-pay/ui/ui/typography';
import type { CoreAsset } from '@kuna-pay/core/entities/asset';
import { useAssetFormat } from '@kuna-pay/core/entities/asset';

import styles from './payment-method-address-amount.module.scss';

type PaymentMethodAddressAmountProps = {
  className?: string;

  paymentAmount: Numeric;
  equivalentAmount: Numeric;
  PaymentAsset: CoreAsset;
  EquivalentAsset: CoreAsset;
};

const PaymentMethodAddressAmount = memo(
  ({
    className,
    paymentAmount,
    equivalentAmount,
    PaymentAsset,
    EquivalentAsset,
  }: PaymentMethodAddressAmountProps) => {
    const { t } = useTranslation();
    const AssetFormat = useAssetFormat();

    return (
      <div className={clsx(styles.root, className)}>
        <Typography className='text-black600' variant='subtitle5'>
          {t('entities.payment-method.address.amount.title')}:
        </Typography>

        <CopyButton
          classes={{ button: styles.copyButton }}
          value={paymentAmount.toString()}
          customMessage={t(
            'entities.payment-method.address.amount.copy-message'
          )}
          size='lg'
        >
          <Typography variant='h6'>
            {AssetFormat.formatAmount(paymentAmount, PaymentAsset)}
          </Typography>
        </CopyButton>

        <Typography className={styles.estimatedAmount} variant='body3'>
          ≈{AssetFormat.formatAmount(equivalentAmount, EquivalentAsset)}
        </Typography>
      </div>
    );
  }
);

export type { PaymentMethodAddressAmountProps };
export { PaymentMethodAddressAmount };
