import { useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ExternalLink } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';

import { $$analytics } from '@kuna-pay/accept-payment/shared/analytics';

import { CheckoutPageModel } from '../../../page.model';
import styles from './redirect-url-button.module.scss';

const RedirectUrlButton = memo(() => {
  const $$model = CheckoutPageModel.useModel();
  const { t } = useTranslation('core');

  const invoice = useUnit($$model.$invoice);

  const Redirect = invoice?.Customization?.redirect;

  if (!Redirect?.url) {
    return null;
  }

  return (
    <Button.Root
      className={styles.root}
      variant='contained'
      color='primary'
      size='xl'
      fullWidth
      asChild
    >
      <ExternalLink
        href={Redirect.url}
        target='_self'
        onClick={() => {
          $$analytics.logEvent({
            event: 'Invoice Redirect Clicked',
            properties: {
              invoiceId: invoice!.id,
            },
          });
        }}
      >
        <Button.Text variant='subtitle3' size='lg'>
          {Redirect.buttonTitle ??
            t('entities.invoice.ui.redirect.button-title')}
        </Button.Text>
      </ExternalLink>
    </Button.Root>
  );
});

export { RedirectUrlButton };
