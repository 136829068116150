import type { Select } from '@kuna/graphql-util/dist/select';
import { buildSelectFragmentFromSelect } from '@kuna/graphql-util/dist/select';

import type { Feature } from '@kuna-pay/core/generated/public/graphql';

import { nonAuthGraphqlClient } from '@kuna-pay/accept-payment/shared/api';

const publicFeaturesRequest = (
  select: Select<Feature> | string
) => `query publicFeatures {
  data: publicFeatures {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'Feature')}`;

const nonAuthFeatures = (select: Select<Feature> | string) => () =>
  nonAuthGraphqlClient.query<Feature[]>(publicFeaturesRequest(select));

export { nonAuthFeatures };
