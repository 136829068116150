import type { PropsWithChildren } from 'react';

import { modelView } from '@kuna-pay/utils/effector';
import { SelectOptionsFactory } from '@kuna-pay/ui/components/select/model';

import { OptionsConfigContext } from './context';
import { SelectOptionsList } from './list';
import { SingleSelectCheckboxOption } from './option.single';
import { OptionState } from './state';

const SingleSelectOptions = {
  Provider: modelView(
    SelectOptionsFactory,
    ({ children }: PropsWithChildren) => <>{children}</>
  ),

  State: OptionState,

  List: SelectOptionsList,

  ConfigProvider: OptionsConfigContext.Provider,

  Option: {
    Checkbox: SingleSelectCheckboxOption,
  },
};

export { SingleSelectOptions };
