import { useUnit } from 'effector-react';
import type { FC, ReactNode } from 'react';

import { SingleSelectModel } from '../../model';

type SingleValueProps = {
  children: (value: string | null) => ReactNode;
};

const SingleValue: FC<SingleValueProps> = ({ children }) => {
  const { $$value } = SingleSelectModel.useModel();
  const [value] = useUnit([$$value.$value]);

  return <>{children(value)}</>;
};

export { SingleValue };
