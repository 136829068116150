/* eslint-disable effector/no-ambiguity-target */ // FIXME

import type { Effect, Event, Store } from 'effector';
import { combine } from 'effector';
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import i18next from 'i18next';

import { createDynamicTimer, getState, listen } from '@kuna-pay/utils/effector';
import { createSyncSearch } from '@kuna-pay/utils/effector/search';
import { invariant } from '@kuna-pay/utils/invariant';
import { bridge } from '@kuna-pay/utils/misc';
import { objectEntries } from '@kuna-pay/utils/typescript';
import { notify } from '@kuna-pay/ui/notification';
import { ErrorMatcher } from '@kuna-pay/core/shared/api';

import { InvoiceStatus } from '@kuna-pay/accept-payment/generated/graphql';
import { $$analytics } from '@kuna-pay/accept-payment/shared/analytics';
import { executePublicInvoice } from '@kuna-pay/accept-payment/shared/api/generated/Invoice/request/executePublicInvoice';
import { preRequestPublicInvoice } from '@kuna-pay/accept-payment/shared/api/generated/Invoice/request/preRequestPublicInvoice';
import { $$autoSelectIfOnlyOnePaymentMethodFeatureFlag } from '@kuna-pay/accept-payment/shared/feature-flags/features';

import type {
  PaymentMethod,
  PaymentMethodOption,
  PublicInvoiceDetailsOutput,
} from '../api';
import { findPaymentMethodsFx } from '../api';
import { PaymentMethodAdapter } from '../page.lib';

const ChoosePaymentMethod = {
  statuses: new Set([
    InvoiceStatus.Created,
    InvoiceStatus.ConfirmationAwaiting,
    InvoiceStatus.LimitsOutOfRange,
  ]),

  createModel: (config: {
    $invoiceId: Store<string>;
    $invoiceStatus: Store<InvoiceStatus | null>;
    getInvoiceInfoFx: Effect<void, PublicInvoiceDetailsOutput>;
  }) => {
    //Implicit deps on same getInvoiceInfoFx to optimize api calls
    const refetchInvoiceInfoFx = config.getInvoiceInfoFx;

    const onInvoiceLoaded = createEvent();

    const changePaymentMethodFx = attach({
      source: { id: config.$invoiceId },

      effect: (
        { id },
        {
          paymentAsset,
          paymentMethodCode,
        }: { paymentAsset: string; paymentMethodCode: string }
      ) =>
        preRequestPublicInvoice({
          id: true,
          amount: true,
          fee: true,
          processedAmount: true,
          rate: true,
          rateFreezeAt: true,
          issues: true,
          isCanBePaid: true,
        })({ id, paymentAsset, paymentMethodCode }),
    });

    const getPaymentMethodsFx = attach({
      source: config.$invoiceId,
      mapParams: (_: void, invoiceId) => ({ id: invoiceId }),
      effect: findPaymentMethodsFx,
    });

    const proceedCheckoutFx = attach({
      source: { id: config.$invoiceId },
      effect: createEffect(
        executePublicInvoice({
          id: true,
        })
      ),
    });

    //commands
    const load = createEvent();
    const reset = createEvent();

    //events
    const submitted = createEvent();

    const $$invoicePaymentMethodExpireTimer = createDynamicTimer();

    const $$selectPaymentMethod = createSyncSelectModel<
      PaymentMethodOption,
      PaymentMethod
    >({
      getOptionsFx: getPaymentMethodsFx,

      match: (option, query) =>
        [option.asset, option.network]
          .filter(Boolean)
          .some((str) => str!.toLowerCase().includes(query.toLowerCase())),

      valueAdapter: (_, option) =>
        PaymentMethodAdapter.fromPaymentMethodOption(option),

      updateFilter: (prevValue, nextValue) => {
        if (prevValue === null || nextValue === null) return true;

        //shallowEqual
        return objectEntries(prevValue).some(
          ([key, value]) => nextValue[key] !== value
        );
      },
    });

    const $isOutOfRangeStatus = combine(
      config.$invoiceStatus,
      (status) => status === InvoiceStatus.LimitsOutOfRange
    );

    const $isInvalid = combine(
      $$selectPaymentMethod.$value,
      changePaymentMethodFx.pending,

      refetchInvoiceInfoFx.pending,

      $$invoicePaymentMethodExpireTimer.$pending,
      $isOutOfRangeStatus,
      (
        isSelected,
        isChangingPaymentMethod,
        isRefetching,
        isNotExpired,
        isOutOfRangeStatus
      ) =>
        !isSelected ||
        isChangingPaymentMethod ||
        isRefetching ||
        !isNotExpired ||
        isOutOfRangeStatus
    );

    const $loadingInitialRates = combine(
      changePaymentMethodFx.pending,
      refetchInvoiceInfoFx.pending,
      config.$invoiceStatus,

      (isPendingChange, isPendingRefetch, status) => {
        const isPending = isPendingChange || isPendingRefetch;

        const statuses = [
          InvoiceStatus.Created,
          InvoiceStatus.LimitsOutOfRange,
        ];

        return isPending && statuses.includes(status!);
      }
    );

    const $changingPaymentMethod = combine(
      changePaymentMethodFx.pending,
      refetchInvoiceInfoFx.pending,
      config.$invoiceStatus,

      (isPendingChange, isPendingRefetch, status) => {
        const isPending = isPendingChange || isPendingRefetch;

        return isPending && status === InvoiceStatus.ConfirmationAwaiting;
      }
    );

    listen({
      clock: load,
      handler: () => {
        $$selectPaymentMethod.load();
      },
    });

    const $counter = createStore(0).on(
      [onInvoiceLoaded, getPaymentMethodsFx.doneData],
      (count) => {
        const newCount = count + 1;

        return Math.min(2, newCount);
      }
    );

    listen({
      clock: onlyInCreatedInvoiceStatus($counter.updates),
      source: $$selectPaymentMethod.$$ui.$$search.$allOptions,
      handler: async (_, options) => {
        const isAutoSelectEnabled = await getState(
          $$autoSelectIfOnlyOnePaymentMethodFeatureFlag.$enabled
        );

        if (!isAutoSelectEnabled) {
          return;
        }

        if (options.length === 1) {
          $$selectPaymentMethod.changed(options[0]);
        }
      },
    });

    listen({
      clock: onlyInChoosePaymentInvoiceStatus($$selectPaymentMethod.changed),
      source: $$selectPaymentMethod.$value,
      handler: async (_, paymentMethod) => {
        if (!paymentMethod) return;

        try {
          await changePaymentMethodFx({
            paymentAsset: paymentMethod.asset,
            paymentMethodCode: paymentMethod.code,
          });
        } catch (e) {
          notify.warning(
            i18next.t(
              'pages.checkout.choose-payment-method.model.change-payment-method.failed',
              { replace: { code: paymentMethod.asset } }
            )
          );
        } finally {
          await refetchInvoiceInfoFx();
        }
      },
    });

    listen({
      clock: onlyInChoosePaymentInvoiceStatus(refetchInvoiceInfoFx.doneData),
      handler: async (invoice) => {
        $$selectPaymentMethod.init(
          PaymentMethodAdapter.fromPublicInvoiceOutput(invoice)
        );

        if (invoice.expireAt) {
          $$invoicePaymentMethodExpireTimer.init({
            endsAt: invoice.expireAt,
          });
        }
      },
    });

    listen({
      name: '$$choose-payment-method.onSelectedPaymentMethodExpired',

      clock: onlyInChoosePaymentInvoiceStatus(
        $$invoicePaymentMethodExpireTimer.finally
      ),

      source: $$selectPaymentMethod.$value,

      handler: async (_, paymentMethod) => {
        invariant.error(paymentMethod, 'Payment method is not selected');

        try {
          await changePaymentMethodFx({
            paymentAsset: paymentMethod.asset,
            paymentMethodCode: paymentMethod.code,
          });

          /**
           * Shouldn't refetch invoice in finally block
           * because it will create infinite loop if the payment method is expired
           *
           * @see https://kunatech.atlassian.net/browse/KUPAY-1827
           */
          await refetchInvoiceInfoFx();
        } catch (e) {
          notify.warning(
            i18next.t(
              'pages.checkout.choose-payment-method.model.renew-payment-method.failed'
            )
          );
        }
      },
    });

    listen({
      clock: onlyInChoosePaymentInvoiceStatus(submitted),
      source: {
        invoiceId: config.$invoiceId,
        isInvalid: $isInvalid,
        selectedPaymentMethod: $$selectPaymentMethod.$value,
      },
      handler: async (
        _,
        { isInvalid, invoiceId, selectedPaymentMethod: method }
      ) => {
        if (isInvalid) return;

        try {
          await proceedCheckoutFx();

          void $$analytics.logEvent({
            event: 'Invoice Proceed to Checkout',
            properties: {
              id: invoiceId,
              asset: method?.asset,
              code: method?.code,
              name: method?.name,
              network: method?.network,
            },
          });
        } catch (error) {
          if (
            ErrorMatcher.createErrorMatcher('PRE_REQUEST_OF_INVOICE_EXPIRED')(
              error
            )
          ) {
            notify.warning(
              i18next.t(
                'pages.checkout.choose-payment-method.model.proceed-checkout.failed.expired'
              )
            );

            throw error;
          }

          if (
            /**
             * "message": "An invoice can only be executed once after pre-request!",
             * "code": "UNABLE_TO_EXECUTE_INVOICE",
             */
            ErrorMatcher.createErrorMatcher('UNABLE_TO_EXECUTE_INVOICE')(error)
          ) {
            void refetchInvoiceInfoFx();

            throw error;
          }

          notify.warning(
            i18next.t(
              'pages.checkout.choose-payment-method.model.proceed-checkout.failed.unknown'
            )
          );
          throw error;
        }

        void refetchInvoiceInfoFx();
      },
    });

    function onlyInChoosePaymentInvoiceStatus<EventPayload>(
      event: Event<EventPayload>
    ) {
      return sample({
        clock: event,
        filter: combine(config.$invoiceStatus, (status) =>
          ChoosePaymentMethod.statuses.has(status!)
        ),
      });
    }

    function onlyInCreatedInvoiceStatus<EventPayload>(
      event: Event<EventPayload>
    ) {
      return sample({
        clock: event,
        filter: combine(
          config.$invoiceStatus,
          (status) => status === InvoiceStatus.Created
        ),
      });
    }

    return {
      load,
      reset,
      onInvoiceLoaded,

      $$ui: {
        $$invoicePaymentMethodExpireTimer,
        $$selectPaymentMethod: $$selectPaymentMethod.$$ui,

        $isInvalid,

        submitted,

        $submitting: proceedCheckoutFx.pending,

        $isOutOfRangeStatus,

        $loadingInitialRates,
        $changingPaymentMethod,
      },
    };
  },
};

//TODO: extract to lib
function createSyncSelectModel<Option, Value>(config: {
  getOptionsFx: Effect<void, Option[]>;

  match: (option: Option, query: string) => boolean;

  valueAdapter: (prevValue: Value | null, option: Option) => Value | null;

  updateFilter: (prevValue: Value | null, nextValue: Value | null) => boolean;
}) {
  const getPaymentMethodsFx = attach({ effect: config.getOptionsFx });

  //commands
  const init = createEvent<Value | null>();

  //events
  const buttonClicked = createEvent();
  const searchItemClicked = createEvent<Option>();
  const goBackClicked = createEvent();

  //stores
  const $isOpen = createStore(false);
  const $value = createStore<Value | null>(null, {
    updateFilter: config.updateFilter,
  });

  //models
  const $$search = createSyncSearch({
    getOptionsFx: getPaymentMethodsFx,

    match: config.match,
  });

  bridge(() => {
    $value.on(init, (_, value) => value);
  });

  bridge(() => {
    $isOpen.on(buttonClicked, () => true);
  });

  bridge(() => {
    $isOpen.on(searchItemClicked, () => false);

    $value.on(searchItemClicked, config.valueAdapter);
  });

  bridge(() => {
    sample({
      clock: goBackClicked,
      target: $$search.clear,
    });

    $isOpen.on(goBackClicked, () => false);
  });

  return {
    load: $$search.load,
    init,

    $value,

    changed: searchItemClicked,

    $$ui: {
      $$search: $$search.$$ui,

      $isOpen,

      $value,

      buttonClicked,
      searchItemClicked,
      goBackClicked,
    },
  };
}

export { ChoosePaymentMethod };
